// Description: Component that will render an graphic with the user's campaigns data
// Updated: 24/03/2025

// React
import React, { useEffect, useRef, useState } from 'react';

// Material UI Components
import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';

// Components and functions for the project
import Chart from 'chart.js/auto';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import axios from 'axios';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import TableModal from './ModeGraphicModal';


const Labels = ({ selectedLabel, handleIconClick, labelsValues, loading }) => {

    const labels = [
        { key: 'total_campaigns', label: 'Geral campanhas', icon: FactCheckOutlinedIcon },
        { key: 'total_campaigns_read', label: 'Lidas', icon: TaskAltOutlinedIcon },
        { key: 'total_campaigns_send', label: 'Enviadas', icon: SendOutlinedIcon },
        { key: 'total_campaigns_failed', label: 'Falhadas', icon: ErrorOutlineOutlinedIcon },
        { key: 'total_messages_received', label: 'Recebidas', icon: AccessTimeOutlinedIcon },
    ];

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(x => x + x).join('');
        }
        const num = parseInt(hex, 16);
        return `${(num >> 16) & 255}, ${(num >> 8) & 255}, ${num & 255}`;
    };

    return (
        <>
            <Box sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px',
                borderRadius: '10px',
                marginTop: '20px',
                gap: 2,
            }}>
                {labels.map((label, index) => (
                    <Box
                        key={index}
                        onClick={() => handleIconClick(label.key)}
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: selectedLabel === label.key ? ThemeColors.purple100 : '#fff',
                            padding: '10px',
                            borderRadius: '10px',
                            flex: 1,
                            maxWidth: { xs: '140px', sm: 'calc(33.33% - 20px)' },
                            minWidth: { xs: '140px', sm: 'none' },
                            border: selectedLabel === label.key ? 'none' : '1px solid #D9D9DC',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                width: { xl: '50px', lg: '50px', sm: '40px' },
                                height: { xl: '50px', lg: '50px', sm: '40px' },
                                borderRadius: { xl: '10px', sm: '6px' },
                                background: selectedLabel === label.key ? ThemeColors.purple200 : ThemeColors.gray20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <label.icon sx={{ color: selectedLabel === label.key ? ThemeColors.purple600 : ThemeColors.gray40 }} />
                            </Box>
                            <Box sx={{ marginLeft: '10px' }}>
                                {loading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'left', minHeight: '20px' }}>
                                        <LinearProgress
                                            sx={{
                                                height: '10px',
                                                borderRadius: '6px',
                                                minWidth: '50px',
                                                width: '100%',
                                                backgroundColor: `rgba(${hexToRgb(ThemeColors.gray20)}, 0.8)`,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: ThemeColors.gray20
                                                },
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <Typography sx={{
                                        fontSize: { xl: '18px', lg: '18px', sm: '12px' },
                                        fontFamily: 'Satoshi-Medium',
                                        color: selectedLabel === label.key ? ThemeColors.purple600 : ThemeColors.gray40
                                    }}>
                                        {labelsValues[label.key]}
                                    </Typography>
                                )}
                                <Typography sx={{
                                    color: 'rgb(0 0 0 / 50%)',
                                    fontSize: { xl: '14px', lg: '14px', sm: '11px' }
                                }}>
                                    {label.label}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );
};

const customTooltip = (context) => {

    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div></div>';
        document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;

    console.log(tooltipModel.body, 'tooltipModel.body');

    if (tooltipModel.opacity === 0) {
        tooltipEl.classList.remove('show');
        return;
    }

    tooltipEl.classList.add('show');
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    if (tooltipModel.body) {

        let label = tooltipModel.dataPoints[0].label;

        label = label.toString();

        const [year, month] = label.split('-');

        const date = `${month}/${year}`;

        const value = tooltipModel.dataPoints[0].raw;

        let labelName = tooltipModel.dataPoints[0].dataset.label;

        labelName = labelName.charAt(0).toUpperCase() + labelName.slice(1).toLowerCase();

        const tooltipContent = tooltipEl.querySelector('div');

        if (tooltipContent) {

            tooltipContent.innerHTML = `<strong>${value}</strong><br><span style="font-size: 0.8em;">${labelName} em ${date}</span>`;
        }
    }

    const position = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight + 'px';
    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
    tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';

    const activeDataset = context.chart.data.datasets[tooltipModel.dataPoints[0].datasetIndex];
    tooltipEl.style.setProperty('background-color', activeDataset.borderColor);
    tooltipEl.style.setProperty('--tooltip-bg', activeDataset.borderColor);
};

const ModeGraphic = () => {

    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [selectedLabel, setSelectedLabel] = useState('total_campaigns');
    const [loadingGraphic, setLoadingGraphic] = useState(false);
    const [dataGraphic, setDataGraphic] = useState([]);
    const [loadingLabels, setLoadingLabels] = useState(false);
    const [labelsValues, setLabelsValues] = useState({});
    const [startDate, setStartDate] = useState(
        dayjs().subtract(6, 'month').startOf('month')
    );
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const [modalData, setModalData] = useState({ label: "", date: "" });
    const [openModal, setOpenModal] = useState(false);
    const isSmallScreen = useMediaQuery("(max-width: 600px)");

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(x => x + x).join('');
        }
        const num = parseInt(hex, 16);
        return `${(num >> 16) & 255}, ${(num >> 8) & 255}, ${num & 255}`;
    };

    useEffect(() => {

        setLoadingGraphic(true);

        const authorization = localStorage.getItem('jwt_token');
        const client_id = localStorage.getItem('idToken');

        const url = process.env.REACT_APP_BACKEND_URL;

        axios.post(`${url}/painel/data/messages/general/month`, {}, {
            headers: { Authorization: authorization, clientid: client_id }
        })
            .then((response) => {
                setDataGraphic(response.data.data);
            })
            .catch((error) => {
                console.error('Erro ao buscar os indicadores:', error);
            })
            .finally(() => {
                setLoadingGraphic(false);
            });
    }, []);

    useEffect(() => {

        setLoadingLabels(true);
        const authorization = localStorage.getItem('jwt_token');

        const body = {
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
        };

        const url = process.env.REACT_APP_BACKEND_URL;

        axios.post(`${url}/painel/data/messages/general/total`, body, {
            headers: { 'Authorization': authorization, 'clientid': localStorage.getItem('idToken') }
        }).then((response) => {
            setLabelsValues(response.data || {});
        }).catch((error) => {
            console.error("Erro ao buscar os indicadores:", error);
        }).finally(() => {
            setLoadingLabels(false);
        }
        );
    }, []);

    const handleIconClick = (label) => {
        setSelectedLabel(label);
    };

    const processData = (data, selectedLabel) => {
        if (!data || !Array.isArray(data)) {
            return { labels: [], datasets: [] };
        }

        let datasets = [];
        let labels = [...new Set(data.map((item) => item.month_year))];

        labels.sort();

        const numMonths = isSmallScreen ? 3 : 8;
        labels = labels.slice(-numMonths);

        const translations = {
            total_send: 'Enviadas',
            total_read: 'Lidas',
            total_failed: 'Falhadas',
            total_received: 'Recebidas',
            total_unknown: 'Sem paradeiro'
        };

        if (selectedLabel === 'total_campaigns') {
            const filteredData = data.filter((item) => item.final_status !== 'total_unknown');

            const uniqueStatuses = [...new Set(filteredData.map((item) => item.final_status))];

            datasets = uniqueStatuses.map((status) => {
                const color = {
                    total_send: ThemeColors.purple500,
                    total_read: ThemeColors.purple600,
                    total_failed: ThemeColors.purple950,
                    total_received: ThemeColors.purple700,
                    total_unknown: ThemeColors.purple800
                };

                const totalsByMonth = {};
                filteredData
                    .filter((item) => item.final_status === status)
                    .forEach((item) => {
                        const monthYear = item.month_year;
                        const count = parseInt(item.status_count, 10) || 0;
                        totalsByMonth[monthYear] = (totalsByMonth[monthYear] || 0) + count;
                    });

                return {
                    label: String(translations[status]).toUpperCase(),
                    data: labels.map((label) => totalsByMonth[label] || 0),
                    backgroundColor: `rgba(${hexToRgb(color[status])}, 0.1)`,
                    borderColor: color[status],
                    pointBackgroundColor: color[status],
                    pointRadius: 6.5,
                    borderDash: [5, 5],
                    fill: true,
                    tension: 0.4,
                };
            });
        } else {
            const mapLabels = {
                total_campaigns_read: 'total_read',
                total_campaigns_send: 'total_send',
                total_campaigns_failed: 'total_failed',
                total_messages_received: 'total_received',
            };

            const selectedLabelMapped = mapLabels[selectedLabel] || selectedLabel;

            const filteredData = data.filter((item) => item.final_status === selectedLabelMapped);

            const totalsByMonth = {};
            filteredData.forEach((item) => {
                const monthYear = item.month_year;
                const count = parseInt(item.status_count, 10) || 0;
                totalsByMonth[monthYear] = (totalsByMonth[monthYear] || 0) + count;
            });

            datasets = [{
                label: String(translations[selectedLabelMapped]).toUpperCase(),
                data: labels.map((label) => totalsByMonth[label] || 0),
                backgroundColor: 'rgba(242, 240, 255, 0.5)',
                borderColor: ThemeColors.purple500,
                pointBackgroundColor: ThemeColors.purple500,
                pointRadius: 6.5,
                fill: true,
                tension: 0.4,
            }];
        }

        return { labels, datasets };
    };

    const [showLegend, setShowLegend] = useState(false);

    const toggleLegend = () => {
        setShowLegend((prev) => !prev);
    };

    useEffect(() => {
        if (chartRef.current) {

            const ctx = chartRef.current.getContext('2d');

            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            Chart.register(ChartDataLabels);

            const { labels, datasets } = processData(dataGraphic, selectedLabel);

            const plugin = {
                beforeInit(chart) {
                    // Referência à função original 'fit' da legenda
                    const origFit = chart.legend.fit;
                    chart.legend.fit = function fit() {
                        origFit.bind(chart.legend)();
                        // Alterando a altura da legenda (exemplo: 50px a mais)
                        this.height += 50; // Aqui você pode ajustar o valor conforme necessário
                    };
                }
            };

            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: { labels, datasets },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                drawOnChartArea: true,
                                borderDash: [5, 5],
                                display: false
                            },
                            ticks: {
                                font: {
                                    family: 'Satoshi',
                                    size: 14
                                },
                            }
                            , display: true,
                            position: 'bottom',

                        },
                        y: {
                            stacked: false,
                            beginAtZero: true,
                            suggestedMin: 0,
                            suggestedMax: 10,
                            grid: {
                                borderDash: [5, 5],
                                display: true
                            },
                            ticks: {
                                display: true,
                            },
                            display: true,
                            position: 'transparent',
                        }
                    },
                    plugins: {
                        legend: {
                            display: selectedLabel === 'total_campaigns' ? showLegend : false,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                                generateLabels: (chart) => {
                                    return chart.data.datasets.map((dataset, i) => ({
                                        text: `Mostrar ${dataset.label.charAt(0).toUpperCase() + dataset.label.slice(1).toLowerCase()}`,
                                        fillStyle: dataset.borderColor,
                                        strokeStyle: dataset.borderColor,
                                        pointStyle: 'circle', //
                                        hidden: !chart.isDatasetVisible(i),
                                        datasetIndex: i
                                    }));
                                }
                            }
                        },
                        tooltip: {
                            enabled: false,
                            external: customTooltip
                        },
                        datalabels: {
                            display: false,
                            align: 'right',
                            anchor: 'top',
                            formatter: (value, context) => context.dataset.label,
                            font: {
                                weight: 'normal',
                                size: 11
                            },
                            color: '#000',
                        }
                    },
                    elements: {
                        line: {
                            borderWidth: 1,
                            borderColor: ThemeColors.purple300,
                        },
                        point: {
                            hoverRadius: 8,
                            radius: 8
                        },
                    },
                    onClick: (event) => {
                        const activePoints = chartInstanceRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
                        if (activePoints.length > 0) {
                            const datasetIndex = activePoints[0].datasetIndex;
                            const dataIndex = activePoints[0].index;

                            const date = chartInstanceRef.current.data.labels[dataIndex];
                            const label = chartInstanceRef.current.data.datasets[datasetIndex].label;
                            const value = chartInstanceRef.current.data.datasets[datasetIndex].data[dataIndex];

                            const mapLabels = {
                                LIDAS: 'total_read',
                                ENVIADAS: 'total_send',
                                FALHADAS: 'total_failed',
                                RECEBIDAS: 'total_received'
                            };

                            setModalData({ label: mapLabels[label], date: date });

                            setOpenModal(true);
                        }
                    }
                },
                plugins: [plugin]
            });
        }
    }, [selectedLabel, dataGraphic, showLegend, isSmallScreen]);

    return (
        <>
            <Labels selectedLabel={selectedLabel} handleIconClick={handleIconClick} labelsValues={labelsValues} loading={loadingLabels} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px', padding: '0px 32px' }}>
                {dataGraphic.length > 0 && selectedLabel === 'total_campaigns' && (
                    <Typography
                        sx={{
                            fontFamily: 'Satoshi-Medium',
                            fontSize: '18px',
                            color: ThemeColors.gray40,
                            cursor: 'pointer',
                            padding: '0px 30px',
                            textDecoration: 'underline'
                        }}
                        onClick={toggleLegend}
                    >
                        {showLegend ? 'Desativar legenda' : 'Habilitar legenda'}
                    </Typography>
                )}
            </Box>
            <Box sx={{ height: '400px', position: 'relative', marginTop: '40px', margin: '20px' }}>
                <canvas style={{ overflow: 'inherit' }} ref={chartRef}></canvas>
            </Box>
            <TableModal open={openModal} onClose={() => (setOpenModal(false), setModalData(null))} data={modalData} />
        </>
    );
};

export default ModeGraphic;