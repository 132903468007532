import { AES, enc } from 'crypto-js';
import CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const getToken = () => {
  return localStorage.getItem('idToken'); 
};


const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Verifica se o cookie começa com o nome fornecido
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

let cookieIVGEN = '';

function isAuthenticated() {

  if(getToken()) {
    const token = getToken();
    if (!token) {
      return { authenticated: false, decryptedData: null };
    }
  
    try {
      const decodedToken = jwtDecode(token);

      const userId = decodedToken.user_id; 

      const cookieUUID = process.env.REACT_APP_COOKIE_UUID;

      Cookies.set(cookieUUID, userId, { expires: 1 });
      
      const currentTime = Date.now() / 1000; 
      if (decodedToken.exp < currentTime) {
        return { authenticated: false, decryptedData: null };  
      }
      
      decodedToken['UUID'] = decodedToken['custom:uuid_client'];
      decodedToken['EMAIL'] = decodedToken['email'];
      decodedToken['NAME'] = decodedToken['cognito:username'];

      return { authenticated: true, decryptedData: decodedToken };
    } catch (error) {
      console.error('Invalid JWT', error);
      return { authenticated: false, decryptedData: null };
    }
  }

  let authenticated = false;

  const cookieUUID = process.env.REACT_APP_COOKIE_UUID;
  const cookieNameClient = process.env.REACT_APP_COOKIE_NAME_CLIENT;
  const cookieUserType = process.env.REACT_APP_COOKIE_USER_TYPE;
  const cookieIv = process.env.REACT_APP_COOKIE_IV;
  const cookieToken = process.env.REACT_APP_COOKIE_TOKEN;
  const cookieEmail = process.env.REACT_APP_COOKIE_EMAIL;

  const getCookieUUID = getCookie(cookieUUID);
  const getCookieNameClient = getCookie(cookieNameClient);
  const getCookieUserType = getCookie(cookieUserType);
  const getCookieIv = getCookie(cookieIv);  
  const getCookieToken = getCookie(cookieToken);
  const getCookieEmail = getCookie(cookieEmail);

  if (getCookieUUID && getCookieNameClient && getCookieUserType && getCookieIv && getCookieToken && getCookieEmail) {
    authenticated = true;
    const ivBase64 = getCookieIv;
    cookieIVGEN = ivBase64;
  } else {
    authenticated = false;
  }

  const decryptedData = {};

  if (authenticated) {
    decryptedData['UUID'] = decrypted(getCookieUUID);
    decryptedData['NAME'] = decrypted(getCookieNameClient);
    decryptedData['USERTYPE'] = decrypted(getCookieUserType);
    decryptedData['IV'] = getCookieIv;
    decryptedData['TOKEN'] = decrypted(getCookieToken);
    decryptedData['EMAIL'] = decrypted(getCookieEmail);
    
  }

  return { authenticated, decryptedData };
}


function decrypted(encryptedData) {
  if (!encryptedData) {
    return '';
  }

  const ENCRYPTION_KEY_HEX = process.env.REACT_APP_ENCRYPTION_KEY;
  const ENCRYPTION_KEY = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY_HEX);
  
  const iv = CryptoJS.enc.Base64.parse(cookieIVGEN);

  const decryptedValue = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decryptedData = decryptedValue.toString(CryptoJS.enc.Utf8);

  if (!decryptedData) {
    console.error('Decryption failed');
    return '';
  }

  return decryptedData.replace(/\u0000/g, '').trim();
}



export { isAuthenticated, decrypted};
