const tutorialData = [
    {
        cardTitle: "Como adicionar Canais?",
        text: "Adicionar um canal é o primeiro passo para utilizar a plataforma 123zap. Os canais de comunicação são as formas de envio de suas mensagens. Oferecemos diferentes meios de saída: E-mail, SMS, WhatsApp Oficial e WhatsApp Web. Abaixo, você poderá conferir como cadastrar cada um deles em nossa plataforma.",
        title: "",
        tutorial: [
            {
                title: "WhatsApp Web",
                data: [
                    { desc: "Acesse o link https://painel.123zap.com.br/canais/adicionar para acessar o formulário de login." },
                    { desc: "Encontre o canal que deseja adicionar e clique sobre ele (WhatsApp Web, WhatsApp API, E-mail ou SMS)." },
                    { desc: "Para o WhatsApp Web, será aberta uma nova página onde você deverá escolher um nome para identificação e clicar no botão Gerar QR Code." },
                    { desc: "A plataforma irá gerar um QR Code para a conexão. Em seguida, acesse o aplicativo do WhatsApp no seu celular, vá em Gerenciar Dispositivos e clique em Adicionar Conexão para realizar a leitura do QR Code gerado." },
                    { desc: "Após a leitura bem-sucedida do QR Code, a conexão aparecerá como Conectada no seu telefone." },
                    { desc: "A plataforma 123zap tentará automaticamente verificar o estabelecimento da conexão. Não se preocupe se o processo demorar um pouco; normalmente, pode levar até 2 minutos para que a conexão seja reconhecida." },
                    { desc: "Você poderá acompanhar o progresso no temporizador exibido abaixo do QR Code." },
                    { desc: 'Assim que a conexão for reconhecida pela plataforma (123zap), você será automaticamente redirecionado para a listagem de canais. Nessa página, seu canal aparecerá como "Conectado".' },
                ]
            },
            {
                title: "WhatsApp API",
                data: [
                    { desc: 'Para API Oficial do WhatsApp, você precisará realizar o processo de criação de aplicativo e geração de token fixo diretamente na Meta. Para isso, siga as instruções disponíveis no link: https://developers.facebook.com/docs/whatsapp/cloud-api/get-started.' },
                    { desc: 'Após obter o token fixo da Meta, clique na opção WhatsApp API na plataforma 123zap. Uma janela será exibida solicitando: O token fixo gerado na Meta e  A URL base fornecida pela Meta.' },
                    { desc: 'Preencha os dois campos e clique em “Confirmar solicitação”.' },
                    { desc: 'Se tudo correr bem, uma janela será exibida confirmando que seu canal foi adicionado com sucesso à plataforma.' },
                    { desc: 'Clique no botão "Verificar meus canais" para retornar à listagem de canais. Inicialmente, o seu canal de WhatsApp API aparecerá com o status "Aguardando".' },
                    { desc: 'Se todos os dados estiverem corretos, a plataforma conseguirá estabelecer a comunicação com o WhatsApp em até 10 minutos e o status mudará para conectado.' },
                    { desc: 'Caso a comunicação não seja estabelecida dentro do prazo, entre em contato com o suporte para verificar possíveis problemas.' },
                ]
            },
            {
                title: "SMS",
                data: [
                    { desc: 'Para SMS será aberta uma janela de confirmação onde você deverá digitar a palavra "solicitar" no campo indicado para confirmar sua solicitação.' },
                    { desc: "Se tudo correr bem, uma janela será exibida confirmando que seu canal foi adicionado com sucesso." },
                    { desc: 'Clique no botão "Verificar meus canais" para retornar à listagem de canais, onde o seu canal de SMS aparecerá.' },
                ]
            },
            {
                title: "Email",
                data: [
                    { desc: 'Para e-mail será aberta uma janela de confirmação onde você deverá digitar o e-mail que deseja utilizar para enviar mensagens pela 123zap e escolher um nome para identificação na plataforma.' },
                    { desc: 'Se tudo correr bem, uma janela será exibida confirmando que seu canal foi adicionado com sucesso à plataforma e que uma solicitação de confirmação foi enviada para o seu e-mail.' },
                    { desc: 'Clique no botão "Verificar meus canais" para retornar à listagem de canais. Inicialmente, o seu canal de e-mail aparecerá com o status "Aguardando".' },
                    { desc: 'Acesse o e-mail informado e localize a solicitação para autorizar a integração enviado pela Amazon Web Services. Clique no link recebido para autorizar a integração.' },
                    { desc: 'Após a autorização, a plataforma identificará automaticamente a conexão estabelecida, e o status do seu canal será alterado para Conectado. Esse processo pode levar até 10 minutos após a confirmação.' },
                ]
            }
        ]
    },
    {
        cardTitle: "Como importar Listas?",
        text: "Agora que você já adicionou os canais à plataforma, pode seguir para o próximo passo: carregar sua listagem e base de dados. A listagem representa os clientes ou contatos para os quais você deseja enviar mensagens. Ela pode incluir uma lista de cobranças de um mês, toda a sua base de clientes ou qualquer outra necessidade. Oferecemos dois modos de importação: Planilha Google e API. A seguir, explicaremos como realizar esse processo",
        title: "",
        tutorial: [
            {
                title: 'Importe sua lista com os dados de Envio',
                data: [
                    { desc: 'Acesse o link https://painel.123zap.com.br/listagens/adicionar para acessar o formulário de importação.' },
                    { desc: 'Na primeira parte do formulário, comece informando os dados básicos da planilha: nome para identificação, descrição e formato (Google Planilhas ou API).' },
                    { desc: 'Se optar por Google Planilhas, será necessário compartilhar sua planilha com o e-mail planilhas-n8n@projeto-brio-n8n.iam.gserviceaccount.com para que a plataforma tenha acesso. Após preencher os campos, clique em Carregar Dados para que a plataforma carregue as informações.' },
                ]
            },
            {
                title: 'Usar listagem via API',
                data: [
                    { desc: 'Para integrar sua listagem à plataforma via API, acesse o endereço https://painel.123zap.com.br/apis/adicionar. Nesse local, você poderá configurar uma API REST para importar seus dados.' },
                    { desc: 'Comece escolhendo um nome para identificar a API e forneça uma descrição clara sobre ela. Em seguida, informe a URL da API e insira o token de autorização necessário. Após preencher essas informações, finalize clicando em Adicionar API.' },
                    { desc: 'É essencial que a API retorne os dados no formato JSON com a seguinte estrutura: O objeto principal deve conter a chave "faturas", cujo valor seja um array de objetos JSON. Cada item desse array deve representar um cliente, incluindo as informações necessárias para o envio de mensagens, como datas, nomes e outras informações relevantes.' },
                    { desc: 'Após configurar sua API na plataforma, você poderá adicioná-la como uma listagem. Se tudo estiver correto, sua API estará disponível para importação na página Listagens/Adicionar. Para isso, acesse https://painel.123zap.com.br/listagens/adicionar, escolha a opção API, selecione a API configurada e clique em Carregar Dados. Isso permitirá que o formulário avance para a próxima etapa, que é a Padronização de Dados.' },
                ]
            },
            {
                title: "Padronização",
                data: [
                    { desc: 'Se tudo correu bem até aqui, o formulário já estará na segunda aba, chamada Padronização.' },
                    { desc: 'A plataforma utiliza um sistema de padronização para conseguir trabalhar com dados de diferentes clientes de forma organizada, usando identificadores. Embora pareça complicado, é muito simples! A plataforma irá carregar os cabeçalhos da sua planilha ou listagem na coluna do lado esquerdo. No lado direito, haverá um menu de seleção onde você deverá indicar qual identificador da plataforma 123Zap corresponde a cada coluna carregada.' },
                    { desc: 'Por exemplo, se sua planilha possui uma coluna chamada "Nome de clientes", ela aparecerá na coluna do lado esquerdo. No lado direito dessa coluna, haverá um menu com diversas opções de identificadores, como Datas, Valores, Nome ou Nome_Completo. Nesse caso, você deverá selecionar o identificador que melhor corresponde à sua coluna, que seria Nome ou Nome_Completo.' },
                    { desc: 'Esse processo permite que a plataforma entenda e padronize os dados corretamente, facilitando o envio de mensagens e outras ações. Dessa forma, ao criar modelos de mensagens, você poderá utilizar esses identificadores para enviar mensagens personalizadas aos seus clientes, garantindo uma comunicação mais eficiente e direcionada.' },
                    { desc: 'Agora que você já padronizou as colunas importantes para os seus disparos, clique no botão Enviar. Se nenhum erro grave for identificado, você será redirecionado para a página /listagens, onde poderá visualizar a listagem que acabou de adicionar, já disponível na plataforma.' },
                ]
            },
        ],
    },
    {
        cardTitle: "Como criar Modelos?",
        text: "Agora chegamos à melhor parte, onde você poderá criar a mensagem personalizada para o seu cliente. Mas antes, é importante entender o que é um modelo. O modelo é a estrutura de mensagem que você irá criar para cada canal de envio, como Email, SMS ou WhatsApp. Cada canal possui seu próprio modelo, e caso você queira criar uma campanha com múltiplos canais, será necessário criar um modelo específico para cada um deles. A criação dos modelos é bem intuitiva e não muda muito de um canal para outro. Para facilitar, vamos usar um canal como exemplo, e você poderá replicá-lo nos outros.",
        title: "",
        tutorial: [
            {
                title: 'Crie modelos personalizados para cada canal de envio',
                data: [
                    { desc: 'Acesse a Ferramenta de Criação de Modelos Primeiro, acesse o link https://painel.123zap.com.br/modelos/canal e selecione o canal de modelo que você deseja criar. Por exemplo, para o modelo de SMS, você pode acessar https://painel.123zap.com.br/modelos/canal/sms. Para o modelo de WhatsApp, acesse https://painel.123zap.com.br/modelos/canal/whatsapp.' },
                    { desc: 'Após selecionar o canal, você será redirecionado para a ferramenta de criação de modelos. Nessa tela, você verá duas partes principais' },
                    { desc: 'Lado Esquerdo: Aqui você pode montar o seu modelo de mensagem.' },
                    { desc: 'Lado Direito: Aqui será exibida uma prévia em tempo real de como o modelo está ficando' },
                ]
            },
            {
                title: 'Escolha o Nome e Organize o Modelo',
                data: [
                    { desc: 'No lado esquerdo, comece escolhendo um nome para o seu modelo. Em seguida, você pode escolher uma etiqueta para organizar melhor seus modelos. As etiquetas funcionam como categorias de organização, como "Avisos", "Atrasos", "Faturas", etc. Fique à vontade para escolher conforme desejar.' },
                ]
            },
            {
                title: "Início da Montagem da Mensagem",
                data: [
                    { desc: 'Agora você pode começar a montagem da mensagem no campo Mensagem. Este campo é onde você escreverá o texto que será enviado ao cliente.' },
                ]
            },
            {
                title: "Utilizando Variáveis para Personalização",
                data: [
                    { desc: 'Ao lado do título Mensagem, você verá um botão chamado Variáveis. As variáveis são identificadores padronizados da plataforma que você pode usar para personalizar a mensagem. Por exemplo, se você adicionar uma variável chamada (Nome_Completo), e esta variável foi padronizada na importação da sua planilha, cada cliente receberá a mensagem personalizada com o seu nome. Isso permite que você adicione inteligência às suas campanhas, incluindo dados como data de vencimento, valor total da fatura e muito mais.' },
                    { desc: 'As variáveis estão disponíveis para todos os tipos de modelos, então você pode usá-las em Email, SMS e WhatsApp para enviar mensagens personalizadas.' },
                ]
            },
            {
                title: "Adicionando Múltiplas Mensagens no WhatsApp",
                data: [
                    { desc: 'Para o modelo de WhatsApp, você pode adicionar mais de uma mensagem ao mesmo modelo. Isso significa que você pode enviar uma mensagem de texto para o cliente e, em seguida, uma imagem, ou uma cobrança de texto seguida de uma chave Pix.' },
                    { desc: 'Importante: Embora você possa adicionar imagens e mensagens de texto no WhatsApp, ainda não é possível anexar documentos em modelos de mensagem, seja para Email ou WhatsApp.' },
                ]
            },
            {
                title: "Salvando Modelo",
                data: [
                    { desc: 'Após criar e personalizar sua mensagem, clique em Salvar para garantir que o modelo seja registrado.' },
                ]
            },
        ],
    },
    {
        cardTitle: "Como criar Campanhas?",
        text: "Campanhas são ações para enviar mensagens de forma automatizada, como discutido anteriormente. Após adicionar o canal, a lista de contatos e o modelo de mensagem, você poderá conectar todos esses elementos em uma ação para disparar mensagens de maneira automática e, principalmente, inteligente. Nossa plataforma oferece diferentes formas de criação de campanhas, proporcionando praticidade para atender às suas necessidades.",
        title: "",
        tutorial: [
            {
                title: 'Agora vamos criar Sua campanha',
                data: [
                    { desc: 'Acesse o Painel de Campanhas: Vá até o link https://painel.123zap.com.br/campanhas/adicionar para começar a criação de sua campanha.' },
                    { desc: 'Escolha o Tipo de Criação: Selecione o Modo Personalizado para campanhas que exigem configurações detalhadas, como filtros baseados em datas, vencimento, dias úteis, entre outros. Esse modo é ideal para automatizar processos como cobranças e campanhas complexas.' },
                ]
            },
            {
                title: 'Informações Gerais',
                data: [
                    { desc: 'Nesta aba, você irá configurar as informações principais da sua campanha:' },
                    { desc: 'Título da Campanha: Escolha um nome para sua campanha para fácil identificação no sistema.' },
                    { desc: 'Descrição da Campanha: Crie uma descrição que explique o objetivo da campanha, para você e sua equipe entenderem o contexto.' },
                    { desc: 'Data de Início: Defina a data em que a campanha será iniciada (formato DD/MM/YYYY).' },
                    { desc: 'Data de Término: Defina a data em que a campanha será encerrada (formato DD/MM/YYYY).' },
                    { desc: 'Horário de Início: Escolha a hora em que a campanha começará a ser enviada (formato hh:mm).' },
                    { desc: 'Escolha os Canais: Selecione os canais (WhatsApp, SMS, Email) que deseja usar para o envio das mensagens. Para cada canal, você irá escolher o modelo de mensagem correspondente.' },
                ]
            },
            {
                title: "Regras de envio",
                data: [
                    { desc: 'Primeiro, selecione a listagem de clientes que você usará para disparar a campanha. A listagem deve ser previamente carregada dentro da plataforma.' },
                    { desc: 'Se a sua planilha inclui várias datas importantes (como datas de vencimento ou outras datas específicas), você pode ativar a opção "Datas Dinâmicas".' },
                    { desc: 'Após ativar, será necessário selecionar a coluna específica da sua planilha que contém as datas dinâmicas (como datas de vencimento, datas de pagamento ou outras datas relevantes). Com isso, o sistema calculará automaticamente o melhor dia para cada cliente, conforme as configurações que você escolher.' },
                    { desc: 'Se desejar que a campanha envie mensagens somente em dias úteis, ative a opção "Enviar somente em dias úteis".' },
                    { desc: 'Escolha a referência de envio que determina a relação entre a data de envio e a data dinâmica da planilha' },
                    { desc: 'Após definir a referência de envio, o próximo passo é escolher o intervalo de envio em dias antes ou depois da data dinâmica.' },
                    { desc: 'Caso deseje adicionar regras mais específicas para o envio, ative a opção "Adicionar Regra de Envio".' },
                    { desc: 'Após configurar todas as opções e regras de envio, clique em "Salvar" para finalizar a criação da campanha.' },
                ]
            }
        ],
    },
    
    {
        cardTitle: "Ficou alguma dúvida?",
        title: "Se ainda ficou alguma dúvida entre em contato com o suporte",
        text: "Para dúvidas, entre em contato pelo email suporte@123zap.com.br",
        tutorial: []
    },
    
];

export default tutorialData;