// This component is used to add a new request to the support page.
// The user can select the sector, add a title, a message and attach files.
// The user can also preview the files before submitting the request.   
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/107872264/Adicionar+Solicita+o
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=1854-254&t=RqPxWd9hgguzht2p-4

import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddRequest from "../../assets/ilustrations/AddRequest.png";

import Dropzone from 'react-dropzone';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Titles from '../../components/Titles/Titles';
import FileCard from '../../components/FileCard/FileCard';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import DynamicButton from '../../components/DynamicButton/DynamicButton.js';

import Loading from '../../components/Loading/Loading';
import { Typography } from '@mui/material';
import { isAuthenticated } from '../../authMiddleware';
import { RequestCreate } from '../../api/backend/Request/RequestCreate';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../utils/utils.js';
import axios from 'axios';
import { useCallback } from 'react';

function PageAddRequest() {

    const itensSecondary = getComputedStyle(document.documentElement).getPropertyValue('--itensSecondary').trim();
    const defaultMinSendTime = new Date();
    defaultMinSendTime.setHours(8, 0, 0);

    const defaultMaxSendTime = new Date();
    defaultMaxSendTime.setHours(20, 0, 0);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const [sector, setSector] = useState('Financeiro');
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [errorImage, setErrorImage] = useState(false);

    const navigate = useNavigate();

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    const [showLoading, setShowLoading] = useState(true);

    const handleFileChange = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) return;
    
        const file = acceptedFiles[0];
    
        if (!file || !file.type.startsWith("image/")) {
            console.error("Por favor, selecione apenas imagens.");
            notify("Error", "Por favor, selecione apenas imagens.");
            return;
        }
    
        setLoading(true);
    
        const reader = new FileReader();
        reader.onload = async () => {
            const base64String = reader.result;
            const newFile = { file, base64String };
    
            setSelectedFiles((prevFiles) => [...prevFiles, newFile]);
    
            try {
                const uploadedImageUrl = await uploadImage(base64String);
                if (uploadedImageUrl) {
                    setSelectedFiles((prevFiles) =>
                        prevFiles.map((item) =>
                            item.file.name === file.name ? { ...item, base64String: uploadedImageUrl } : item
                        )
                    );
                    notify("Success", "Arquivo adicionado com sucesso", true);
                    setIsPreviewVisible(true);
                }
            } catch (error) {
                console.error("Erro ao enviar imagem:", error);
                notify("Error", "Falha ao enviar imagem", true);
            } finally {
                setLoading(false);
            }
        };
    
        reader.readAsDataURL(file);
    }, []);
    
    const uploadImage = async (base64String) => {
        try {
            const token = localStorage.getItem("jwt_token");
            const clientId = localStorage.getItem("idToken");
    
            if (!token || !clientId) {
                throw new Error("Autenticação inválida");
            }
    
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_AWS_URL}/templates/upload-image`,
                { imagebase64: base64String },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        Client_ID: clientId,
                    },
                }
            );
    
            if (response.data.message === "success") {
                return response.data.image_url;
            } else {
                throw new Error("Erro ao processar upload");
            }
        } catch (error) {
            throw error;
        }
    };

    const deleteFile = (fileToBeDeleted) => {
        const filteredFiles = selectedFiles.filter((file) => file !== fileToBeDeleted);

        setSelectedFiles(filteredFiles);
    }

    const handleSubmit = async () => {

        if (!title || !sector || !message) {
            setError(true);
            alert('Preencha todos os campos obrigatórios');
            console.log(title, sector, message)
        } else {

            let data = {

                title: title,
                description: message,
                status: 'Pendente',
                creationdate: new Date().toISOString(),
                updatedate: new Date().toISOString(),
                user_client: userInfo['UUID'],
                sector: sector,
                body: {
                    message: message,
                    sector: sector,
                    files: selectedFiles.map((file) => (file.base64String))
                },
                priority: 'Alta',

            }

            const addRequestUser = await RequestCreate(data);

            if (addRequestUser.message === "success") {
                return navigate('/suporte');
            } else {
                console.log('Erro ao adicionar solicitação:', addRequestUser);
            }
        }
    }


    useEffect(() => {
        setTimeout(() => setShowLoading(false), 1000);
    }, []);


    const buttons = ['Financeiro', 'Técnico', 'Comercial', 'Reclamação', 'Elogio', 'Outros'];

    const [selected, setSelected] = useState(buttons[0]);

    const handleClick = (button) => {
        setSelected(button);
        setSector(button);
    }

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>
            <HeaderPage />
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    bgcolor: 'transparent',
                    overflow: 'auto',
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                <Grid container gap={"40px"} width={"100%"} justifyContent={'space-between'} paddingLeft={"20px"} paddingRight={"20px"}>
                    <Grid item xs={12} lg={5} minWidth={"48%"}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="outlined-basic"
                                        label="Título da Solicitação"
                                        variant="standard"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        InputLabelProps={{
                                            style: { fontSize: '20px' }  // Ajuste o tamanho da fonte conforme necessário
                                        }}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
                                    {buttons.map((button, index) => (
                                        <Button
                                            key={button}
                                            onClick={() => handleClick(button)}
                                            sx={{
                                                flexGrow: 1,
                                                mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                                                bgcolor: selected === button ? '#8142FC' : ThemeColors.gray30,
                                                color: '#fff',
                                                borderRadius: '27px',
                                                '&:hover': { bgcolor: selected === button ? '#8142FC' : 'gray' },
                                                fontSize: '12px',
                                                padding: '4px 16px'
                                            }}
                                        >
                                            {button}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Typography style={{ color: ThemeColors.gray40, fontSize: '20px' }}>Mensagem</Typography>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={12}
                                        variant="outlined"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        autoComplete="off"
                                        style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Titles children={'Este campo é obrigatório'} styles={{ marginTop: '10px', display: error ? 'block' : 'none', paddingLeft: '10px' }} type={'P1'} color={ThemeColors.red02} />
                        </Grid>
                        <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                            <Grid item xs={12}>
                                {
                                    selectedFiles.length > 0 && isPreviewVisible && loading === false && (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'center'}
                                            height={'100%'}
                                            width={'100%'}
                                            sx={{
                                                textAlign: 'center',
                                                marginTop: '10px',
                                                height: '170px',
                                                background: '#E5E5E5',
                                                borderRadius: '4px',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <Box
                                                display={'flex'}
                                                height={'80%'}
                                                maxHeight={'80%'}
                                                flexDirection={'column'}
                                                gap={'10px'}
                                                overflow={'auto'}
                                                padding={'15px'}
                                            >
                                                {
                                                    selectedFiles.map((file, index) => (
                                                        <FileCard key={index} file={file} handleClick={deleteFile} />
                                                    ))
                                                }
                                            </Box>
                                            <Dropzone
                                                onDrop={acceptedFiles => handleFileChange(acceptedFiles)}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        {...getRootProps()}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <Box display={'flex'} sx={{ gap: '10px', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
                                                            <CloudUploadIcon style={{ fontSize: '16px', color: '#848484' }} />
                                                            <Titles children={'Clique para adicionar arquivo'} color={'#848484'} type={'P1'} />
                                                        </Box>
                                                    </div>
                                                )}
                                            </Dropzone>

                                        </Box>
                                    ) || errorImage && (
                                        <Dropzone
                                            onDrop={acceptedFiles => handleFileChange(acceptedFiles)}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps()}
                                                    style={{
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        marginTop: '10px',
                                                        height: '170px',
                                                        background: ThemeColors.red01,
                                                        borderRadius: '4px',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <input {...getInputProps()} />
                                                    <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center', background: ThemeColors.red01, width: '100%', height: '100%', borderRadius: '4px' }}>
                                                        <CloudUploadIcon style={{ fontSize: '64px', color: ThemeColors.red03 }} />
                                                        <Titles children={'Adicione apenas imagens'} color={ThemeColors.red03} type={'P1'} />
                                                    </Box>
                                                </div>
                                            )}
                                        </Dropzone>
                                    ) || loading && (
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: '10px',
                                                height: '170px',
                                                background: '#E5E5E5',
                                                borderRadius: '4px',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center', background: '#E5E5E5', width: '100%', height: '100%', borderRadius: '4px' }}>
                                                <CloudUploadIcon style={{ fontSize: '64px', color: '#848484' }} />
                                                <Titles children={'...Carregando'} color={'#848484'} type={'P1'} />
                                            </Box>
                                        </div>
                                    ) || (
                                        <Dropzone
                                            onDrop={acceptedFiles => handleFileChange(acceptedFiles)}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps()}
                                                    style={{
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        marginTop: '10px',
                                                        height: '170px',
                                                        background: '#E5E5E5',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <input {...getInputProps()} />
                                                    <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center', background: '#E5E5E5', width: '100%', height: '100%', borderRadius: '4px' }}>
                                                        <CloudUploadIcon style={{ fontSize: '64px', color: '#848484' }} />
                                                        <Titles children={'Clique para adicionar arquivo'} color={'#848484'} type={'P1'} />
                                                    </Box>
                                                </div>
                                            )}
                                        </Dropzone>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginBottom: '10px' }}>
                            <Grid item xs={12}>
                                <DynamicButton text={'Abrir Solicitação'} handleFunction={handleSubmit} variant={'contained'} style={{ backgroundColor: itensSecondary, color: '#fff', width: '100%', padding: '10px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5} minWidth={"48%"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
                        <img
                            src={AddRequest}
                            style={{
                                padding: '10px',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                marginRight: '10px',
                                display: 'block',
                            }}
                        />
                    </Grid>
                </Grid>
            </Box >
        </>
    );
}

export default PageAddRequest;