import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HeaderPage from "../../components/HeaderPage/HeaderPage";
import Loading from "../../components/Loading/Loading";

import StepIndicator from "../../components/DynamicForm/StepIndicator";

import { Button, Box, TextField, Typography, Divider, Tooltip, CircularProgress } from "@mui/material";

import StatusIcon from "../../components/StatusLabel/StatusIcon";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { notify } from "../../utils/utils";
import { ChannelAddSMS } from '../../api/backend/Channels/ChannelAddSMS';

function ChannelPageAddSMS() {

    // --------------------------------------------------
    // Loading inicial

    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    // --------------------------------------------------

    let Navigate = useNavigate();

    const [firstTime, setFirstTime] = useState(30);
    const [currentStep, setCurrentStep] = useState(1);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleAdicionar = async () => {

        try {

            setFirstTime(30);

            const addSMS = await ChannelAddSMS({ 'confirm': 'solicitar' });

            if (addSMS.message === "success") {
                setSuccess(true);
            }

        } catch (error) {
            console.error("Error connecting to instance:", error);
        }
    };

    const HandleWhatsApp = () => {
        window.open('https://wa.me/553121300297?text=Ol%C3%A1%2C%20estou%20precisando%20de%20suporte!%20', '_blank');
    }

    const steps = [
        {
            stepNumber: 1,
            title: "Vamos solicitar seu canal SMS?",
            description: (
                <span>
                    Antes de começar, precisamos compartilhar uma informação importante. <br />
                    Em nossa plataforma, os envios de SMS são realizados por meio de um
                    <strong style={{ color: "#8142FC" }}> número curto compartilhado </strong>. <br />

                    <hr style={{ margin: "10px 0", border: "0.1px solid #F9F9F9" }} />

                    Um número curto é um código numérico menor que um número de telefone convencional,
                    utilizado para o envio de mensagens em larga escala, garantindo maior agilidade e entregabilidade. <br />

                    <hr style={{ margin: "10px 0", border: "0.1px solid #F9F9F9" }} />

                    O termo compartilhado significa que esse mesmo número pode ser
                    utilizado por diferentes empresas para o envio de SMS, o que possibilita reduzir custos e simplificar a operação.
                </span>

            )
        },
        {
            stepNumber: 2,
            title: "O que é um canal compartilhado?",
            description: (
                <span>

                    Chegou a hora de conectar o seu WhatsApp. Para isso,  abra o aplicativo WhatsApp no seu celular <strong style={{ color: "#8142FC" }}> {" > "}</strong> Dispositivos Conectados <strong style={{ color: "#8142FC" }}> {" > "}</strong> Conectar um dispositivo <strong style={{ color: "#8142FC" }}> {" > "}</strong> Escanei o QR Code abaixo.
                </span>
            ),
        },
        {
            stepNumber: 3,
            title: success ? "SMS adicionado com sucesso!" : "Ops! Algo deu errado",
            description: success ? (
                <span>
                    Agora você pode começar a enviar SMS para seus clientes. Clique no botão abaixo para verificar seus canais.
                </span>
            ) : (
                <span>
                    Parece que houve um problema ao adicionar o seu SMS. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter ajuda.
                </span>
            ),
        }
    ];

    const canMoveForward = () => {

        return true;

    };

    useEffect(() => {

        if (firstTime > 0 && currentStep === 2) {

            if (firstTime === 30) {
                handleAdicionar();
            }

            const timer = setInterval(() => {
                setFirstTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
        if (firstTime === 0 && currentStep === 2) {
            setCurrentStep(3);
        }

    }, [firstTime, currentStep]);


    const FormContent = ({ currentStep, steps }) => {

        if (currentStep === 1) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                        width: "100%",
                    }}
                >
                    <StepIndicator currentStep={currentStep} steps={steps} />
                    <Divider sx={{ width: "90%", borderColor: "gray" }} />
                    <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                        {steps[currentStep - 1].title}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: "16px", sm: "16px" }, color: "#848484", marginBottom: "19px", textAlign: "center", maxWidth: { xs: "100%", sm: "80%" } }}>
                        {steps[currentStep - 1].description}
                    </Typography>
                </Box>
            );
        }
        else if (currentStep === 2) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                        width: "100%",
                    }}
                >
                    <StepIndicator currentStep={currentStep} steps={steps} />
                    <Divider sx={{ width: "90%", borderColor: "gray" }} />
                    <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                        Aguarde um instante! 😉
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "16px" },
                            color: "#848484",
                            marginBottom: "19px",
                            textAlign: "center",
                            maxWidth: { xs: "100%", sm: "80%" },
                        }}
                    >
                        Estamos solicitando e configurando o seu canal SMS. Este processo pode levar alguns instantes. Por favor, aguarde.
                    </Typography>

                    <Box
                        sx={{
                            border: "2px solid #F5F3FF",
                            borderRadius: "50%", // Garante que seja um círculo
                            height: "200px", // Define o tamanho fixo para o círculo
                            width: "200px", // Define a mesma largura para o círculo
                            display: "flex",
                            flexDirection: "column", // Organiza os itens em coluna
                            justifyContent: "center", // Centraliza verticalmente
                            alignItems: "center", // Centraliza horizontalmente
                            textAlign: "center", // Alinhamento do texto centralizado
                        }}
                    >
                        <CircularProgress
                            sx={{
                                position: "absolute", // Faz com que o progresso circular fique em cima do Box
                                color: "#F5F3FF", // Cor do progresso circular
                            }}
                            size={200} // Tamanho do círculo
                            thickness={1} // Espessura do progresso
                        />
                        <p
                            style={{
                                fontWeight: 400,
                                fontSize: "30px",
                                fontFamily: '"Open Sans", sans-serif',
                                color: "#4F189A",
                                position: "relative", // Coloca o texto acima do CircularProgress
                                zIndex: 1, // Garante que o texto fique sobre o progresso circular
                            }}
                        >
                            {firstTime}
                        </p>
                    </Box>


                </Box>
            );
        }
        else if (currentStep === 3) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: { xs: "20px", sm: "30px" },
                        width: "100%",
                    }}
                >
                    <StepIndicator currentStep={currentStep} steps={steps} />
                    <Divider sx={{ width: "90%", borderColor: "gray" }} />
                    <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: "15px" }}>
                        {steps[currentStep - 1].title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                        <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                        <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                        <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "16px" },
                            color: "#848484",
                            marginBottom: "19px",
                            textAlign: "center",
                            maxWidth: { xs: "100%", sm: "80%" },
                        }}
                    >
                        {steps[currentStep - 1].description}
                    </Typography>

                    {success ? (
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "16px 20px", sm: "10px 25px" },
                                width: "100%", // Faz o botão ocupar toda a largura
                                maxWidth: "400px", // Define uma largura máxima opcional
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                fontSize: "15px",
                            }}
                            onClick={() => Navigate("/canais")}
                        >
                            Verificar meus canais
                        </Button>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: { xs: "10px", sm: "10px" },
                                marginTop: { xs: "30px", sm: "20px" },
                                flexDirection: {
                                    xs: "column", // Empilha os botões em telas menores
                                    sm: "row", // Alinha os botões na horizontal em telas maiores
                                },
                                width: "100%", // Faz os botões ocuparem toda a largura
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    width: "100%", // Faz o botão ocupar toda a largura
                                    maxWidth: "400px", // Define uma largura máxima opcional
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: "15px",
                                }}
                                onClick={HandleWhatsApp}
                                startIcon={<WhatsAppIcon sx={{ color: "white" }} />}
                            >
                                Falar com o suporte
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    width: "100%", // Faz o botão ocupar toda a largura
                                    maxWidth: "400px", // Define uma largura máxima opcional
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: "15px",
                                    backgroundColor: "#300D68",
                                }}
                                onClick={() => {
                                    Navigate(0);
                                }}
                            >
                                Tentar novamente
                            </Button>
                        </Box>
                    )}
                </Box>
            );
        }

        return null;

    };

    const errorNotification = (message) => {

        setError(true);

        setTimeout(() => {
            setError(false);
        }, 5000);

        notify("error", message, true)
    };

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: { xs: "100vh", sm: "100vh" } }}>
            <HeaderPage />
            <Box
                sx={{
                    minWidth: "100%",
                    minHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                    background: 'rgb(128,51,244)',
                    background: 'radial-gradient(circle, rgba(124,41,249,1) 54%, rgba(128,51,244,1) 100%)',
                    opacity: 0.8,
                    flex: 1,
                    gap: "15px",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                    }}
                >
                    <Box
                        id="form"
                        sx={{
                            background: "#FFF",
                            minHeight: {
                                xs: "100vh", // Ocupa a altura inteira em telas pequenas
                                sm: "100%", // Altura personalizada em telas maiores
                                md: "585px",
                                lg: "585px",
                                xl: "585px",
                            },
                            minWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            maxWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            padding: "30px",
                            borderRadius: {
                                xs: "0", // Sem borda arredondada em telas pequenas
                                sm: "8px", // Bordas arredondadas em telas médias ou maiores
                            },
                            boxShadow: {
                                xs: "none", // Sem sombra em telas pequenas
                                sm: "0 4px 10px rgba(0, 0, 0, 0.2)", // Sombra em telas maiores
                            },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: { xs: "flex-start", sm: "flex-start", md: "space-between", lg: "space-between" },
                            height: "100%", // Faz com que o form ocupe toda a altura disponível
                        }}
                    >
                        <FormContent
                            currentStep={currentStep}
                            steps={steps}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: { xs: "0px", sm: "10px" },
                                marginTop: { xs: "30px", sm: "20px" },
                                flexDirection: {
                                    xs: "column", // Empilha os botões em telas menores
                                    sm: "row", // Alinha os botões na horizontal em telas maiores
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    minWidth: {
                                        xs: '100%',
                                        sm: "154px",
                                    },
                                    display: currentStep === 1 ? "none" : currentStep === 3 || currentStep === 4 ? "none" : "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: { xs: "16px", sm: "16px" },
                                    marginRight: "auto",
                                }}
                                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                            >
                                Voltar
                            </Button>
                            <Tooltip
                                title={currentStep === 2 ? <Typography variant="body2">Não avance antes de escanear o QR Code em seu WhatsApp</Typography> : null}
                                arrow
                                placement="top"
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        padding: { xs: "16px 20px", sm: "10px 25px" },
                                        minWidth: {
                                            xs: '100%',
                                            sm: "154px", // Largura mínima em telas maiores
                                        },
                                        display: currentStep === steps.length ? "none" : (firstTime > 0 && currentStep === 2) ? "none" : "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "6px",
                                        fontSize: { xs: "16px", sm: "16px" },
                                        marginLeft: "auto",
                                        marginTop: { xs: "20px", sm: "0" },
                                    }}
                                    onClick={() => {
                                        if (canMoveForward()) {
                                            setCurrentStep((prevStep) => prevStep + 1);
                                        } else {
                                            errorNotification("Preencha o nome do WhatsApp");
                                        }
                                    }}
                                >
                                    Solicitar
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

}

export default ChannelPageAddSMS;