// This endpoint is used to add a new WhatsApp channel to the user.
// Author: Vitor de Jesus

// Documentation: 

import axios from 'axios';

export async function ChannelAddWppAPI(data) {
    try {

        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/channels/whatsapp/api/add`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                },
            }
        );

        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
