// Desc: Page to add new APIs to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Button, FormHelperText, Select, MenuItem } from "@mui/material";
import ReactJson from 'react-json-view';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

import { Tab, Tabs } from "@mui/material";

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Loading from "../../components/Loading/Loading";

import { ApiInsert } from "../../api/n8n/Listagens/ApiInsert";
import { VerifyAPI } from "../../api/n8n/Listagens/VerifyAPI";

import { isAuthenticated } from "../../authMiddleware";
import { fi } from "date-fns/locale";
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { notify } from "../../utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Render Form to add new APIs
function ApiForm({ copyRespostas, setCopyRespostas, setErrors, errors, uuid_client, handleVerifyAPI, loadingVerify, setReturnApi, setTabIndex }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setCopyRespostas((prev) => ({ ...prev, [name]: value }));

    if (name === 'body') {
      validateJson(value);
    }

  };

  const validateJson = (value) => {
    try {
      JSON.parse(value);
      setErrors((prev) => ({ ...prev, body: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, body: "JSON inválido" }));
    }
  };

  return (
    <Grid item xs={12} md={12}
      style={{ background: '#FFF', borderRadius: '12px', padding: '20px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          autoFocus
          variant="outlined"
          name="title"
          label="Digite um nome para identificar a API"
          value={copyRespostas.title || ""}
          onChange={(e) => handleInputChange("title", e.target.value)}
          sx={{ background: errors.title ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={!!errors.title}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.title && <FormHelperText style={{ color: "red" }}>{errors.title}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="desc"
          label="Digite uma descrição para a API"
          sx={{ background: errors.desc ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.desc || ""}
          onChange={(e) => handleInputChange("desc", e.target.value)}
          error={!!errors.desc}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.desc && <FormHelperText style={{ color: "red" }}>{errors.desc}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel style={{ color: ThemeColors.gray50 }}>Qual o método HTTP da API?</InputLabel>
        <Select
          name="method"
          sx={{ background: errors.method ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.method || ""}
          onChange={(e) => handleInputChange("method", e.target.value)}
          error={!!errors.method}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
        </Select>
        {errors.method && <FormHelperText style={{ color: "red" }}>{errors.method}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="url"
          label="Digite a URL da API:"
          sx={{ background: errors.url ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.url || ""}
          onChange={(e) => handleInputChange("url", e.target.value)}
          error={!!errors.url}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.url && <FormHelperText style={{ color: "red" }}>{errors.url}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="authorization"
          label="Authorization Token"
          sx={{ background: errors.authorization ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.authorization || ""}
          onChange={(e) => handleInputChange("authorization", e.target.value)}
          error={!!errors.authorization}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.authorization && <FormHelperText style={{ color: "red" }}>{errors.authorization}</FormHelperText>}
      </FormControl>
      {copyRespostas.method === "POST" && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            name="body"
            multiline
            rows={4}
            placeholder="Body da requisição"
            sx={{
              background: errors.body ? '#F8D6D3' : '#F9F9F9',
              marginTop: '5px',
              borderRadius: '12px',
            }}
            value={copyRespostas.body || ''}
            onChange={(e) => handleInputChange('body', e.target.value)}
            error={!!errors.body}
            fullWidth
            InputLabelProps={{ style: { color: '#7B7B7B' } }} // Cor do rótulo
          />
          {errors.body && <FormHelperText style={{ color: 'red' }}>{errors.body}</FormHelperText>}
        </FormControl>
      )}
      < Box display="flex" justifyContent="center" alignItems="center" >

        <Button
          variant="contained"
          onClick={() => {
            handleVerifyAPI();
          }}
          sx={{ padding: '15px', marginRight: '10px' }}
          fullWidth
          disabled={loadingVerify}
        >
          {loadingVerify ? "Consultando API" : "Carregar dados"}
        </Button>
      </Box>
    </Grid>
  );
}

function DataDisplayTable({ data, setData, variablesOfTemplate, page, setPage, copyRespostas, handleSubmit, loadingAdd, setLoadingAdd }) {
  // State to control the form fields
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = useState({});

  // UseEffect para evitar a re-renderização das páginas
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const newInputValues = {};

    currentItems.forEach((item, i) => {
      const absoluteIndex = startIndex + i;
      newInputValues[absoluteIndex] = item.variable || '';
    })
    setValues(newInputValues);
  }, [data, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeSelect = (value, index) => {

    setValues({
      ...values,
      [index]: value === "Selecione" ? null : value
    });

    setData(prevData =>
      prevData.map((dataItem, dataIndex) =>
        dataIndex === index ? { ...dataItem, variable: value === "Selecione" ? null : value } : dataItem
      )
    );
  };

  const verifyIfVariableIsSelected = (variable) => {

    if (variable === 'WhatsApp' || variable === 'Email') {
      return false;
    }
    return data.some(item => item.variable === variable);
  }


  const renderSelects = () => {

    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);

    return currentItems.map((item, index) => {
      const absoluteIndex = startIndex + index;
      return (
        <TableRow key={absoluteIndex}>
          <TableCell>{item.field}</TableCell>
          <TableCell>
            <Select
              labelId={"type_send_id" + index}
              id={"type_send" + index}
              value={values[absoluteIndex] ? values[absoluteIndex] : "Selecione"}
              onChange={(e) => {
                handleChangeSelect(e.target.value, absoluteIndex)
              }} // Passa o index para a função
              fullWidth
              style={{ boxShadow: 'none' }} // Remove o sombreamento do Select
              MenuProps={{
                PaperProps: {
                  style: { boxShadow: 'none', border: '1px solid #d3d3d3' }
                },
              }}
              size="small"
              sx={{ background: values[absoluteIndex] === "Selecione" || !values[absoluteIndex] ? '#f9f9f9' : '#f5f3ff', marginTop: '5px', borderRadius: '12px' }}
            >
              <MenuItem value="Selecione">Selecione</MenuItem>
              {Object.values(variablesOfTemplate).flat().map((value) => (
                <MenuItem key={value} value={value} disabled={verifyIfVariableIsSelected(value)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
      <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CAMPO</TableCell>
              <TableCell>REPRESENTA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSelects()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" fullWidth sx={{ padding: '15px', marginTop: '10px', fontSize: '16px' }} onClick={handleSubmit} disabled={loadingAdd}>
        Enviar
      </Button>
    </Grid>
  );
}

function PageAddApis() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const [copyRespostas, setCopyRespostas] = useState({});
  const [errors, setErrors] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [verifyEmptyVariables, setVerifyEmptyVariables] = useState(false);
  const [fieldChannelSelected, setFieldChannelSelected] = useState(null);
  const [countEmptyVariables, setCountEmptyVariables] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [returnApi, setReturnApi] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const [loadingAdd, setLoadingAdd] = useState(false);
  const [dataR, setDataR] = useState([]);
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [padronizationPage, setPadronizationPage] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const navigate = useNavigate();

  const steps = [
    { desc: "Escolha um nome para identificar a API" },
    { desc: "Crie uma descrição para a API" },
    { desc: "Indique a URL da API" },
    { desc: "Insira o token de autorização" },
    { desc: 'Clique em "Adicionar API" para finalizar' }
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  const handleVerifyAPI = async () => {

    if (!copyRespostas.title || !copyRespostas.desc || !copyRespostas.url || !copyRespostas.authorization || !copyRespostas.body || !copyRespostas.method) {
      const newErrors = {
        title: !copyRespostas.title ? "Campo obrigatório" : "",
        desc: !copyRespostas.desc ? "Campo obrigatório" : "",
        url: !copyRespostas.url ? "Campo obrigatório" : "",
        authorization: !copyRespostas.authorization ? "Campo obrigatório" : "",
        body: copyRespostas.method === "POST" && !copyRespostas.body ? "Campo obrigatório" : copyRespostas.method === "POST" && errors.body ? "JSON inválido" : "",
        method: !copyRespostas.method ? "Campo obrigatório" : "",
        uuid_client: userInfo.UUID
      };

      setErrors(newErrors);

      if (newErrors.title || newErrors.desc || newErrors.url || newErrors.authorization || newErrors.body || newErrors.method) {
        return;
      }
    }

    setLoadingVerify(true);
    setReturnApi({});

    const data = { ...copyRespostas, uuid_client: userInfo.UUID, id: userInfo.EMAIL, token: userInfo.TOKEN };
    try {

      const response = await VerifyAPI(data);

      if (response && response.message === "success") {

        const items = Object.entries(response["item_example"]).map(([field, value]) => ({
          field,
          value
        })).filter((item) => item.field !== "row_number");

        setReturnApi(response.item_example);
        setDataR(items);
        setTabIndex(1);
      }

    } catch (error) {
      console.error("Erro ao verificar API:", error);
    } finally {
      setLoadingVerify(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {

    try {

      setLoadingAdd(true);

      let variables = dataR.map((item) => {
        return {
          field: item.field,
          variable: item.variable
        }
      });

      let data2 = { ...copyRespostas, variables };
      const response = await ApiInsert(data2);

      if (response.message === "success") {
        notify("Success", "API adicionada com sucesso!", true);
        navigate('/listagens');

      }

    } catch (error) {
      console.error('Erro ao adicionar API:', error);
    } finally {
      setLoadingAdd(false);
    }

  };

  const preSubmit = () => {

    const newErrors = {};
    if (!copyRespostas.title) newErrors.title = "Campo obrigatório";
    if (!copyRespostas.desc) newErrors.desc = "Campo obrigatório";
    if (!copyRespostas.url && copyRespostas.formatSheets === "Google planilhas") newErrors.url = "Campo obrigatório";
    if (!copyRespostas.formatSheets) newErrors.formatSheets = "Campo obrigatório";
    if (!copyRespostas.deleteDuplicatedVariables) newErrors.deleteDuplicatedVariables = "Campo obrigatório";

    setErrors(newErrors);

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    const hasEmptyVariable = variables.some((item) => !item.variable);
    const countEmptyVariable = variables.filter((item) => !item.variable).length;

    setVerifyEmptyVariables(hasEmptyVariable);
    setCountEmptyVariables(countEmptyVariable);

    const variablesWhatsApp = variables.filter((item) => item.variable === 'WhatsApp');
    const variablesEmail = variables.filter((item) => item.variable === 'Email');

    if (variablesWhatsApp.length > 0 || variablesEmail.length > 0) {
      setFieldChannelSelected(true);
    } else {
      setFieldChannelSelected(false);
      setOpenModalConfirm(true);
      return false;
    }

    setOpenModalConfirm(true);

  }

  const fetchData = async () => {
    try {

      let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
      info = JSON.stringify(info);

      const translations = {
        "Personal_information": "Informações Pessoais",
        "Contact": "Contato",
        "Address": "Endereço",
        "Financial_References": "Referências Financeiras"
      };

      const resultJson = await GetVariables();

      const result = resultJson.variables.reduce((acc, item) => {
        const category = translations[item.category] || item.category;
        if (!acc[category]) {
          acc[category] = [];
        }

        acc[category].push(item.variableName);

        return acc;

      }, {});

      setVariablesOfTemplate(result);

    } catch (error) {
      console.error('Error fetching data from API:', error);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(variablesOfTemplate).length !== 0 && tabIndex === 1) {
      setDataR((prev) =>
        prev.map((item) => {
          const normalize = (str) => str?.trim().toLowerCase();
          const findVariable = Object.values(variablesOfTemplate).flat().find((variable) => normalize(variable) === normalize(item.field));

          if (findVariable) {
            return {
              ...item,
              variable: findVariable
            }
          } else {
            return {
              ...item
            }
          }
        })
      );

    }
  }, [variablesOfTemplate, tabIndex])

  const handleModalConfirm = () => {
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }


  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage title="Adicionar APIs" />


      <Grid container spacing={4} sx={{ padding: { xs: 2.5, sm: 5 } }}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
          <HelpAdd Steps={steps} Title="Adicione sua API" />
          {
            (copyRespostas.title === "" || copyRespostas.desc === "" || copyRespostas.url === "" || copyRespostas.authorization === "") && (
              <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
            )
          }
          {
            !fieldChannelSelected && dataR.length > 0 && (
              <AlertSnackbar message="WhatsApp ou Email não selecionado" severity="error" open={true} />
            )
          }
          {
            verifyEmptyVariables && (
              <AlertSnackbar message={`Existem ${countEmptyVariables} campos sem seleção`} severity="warning" open={true} />
            )
          }
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
            <Tab label="Dados da API" />
            <Tab label="Padronização" disabled={!returnApi || !returnApi.message || returnApi.message === "success"} />
          </Tabs>
          <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
            <ApiForm
              copyRespostas={copyRespostas}
              setCopyRespostas={setCopyRespostas}
              setErrors={setErrors}
              errors={errors}
              uuid_client={userInfo.UUID}
              handleVerifyAPI={handleVerifyAPI}
              loadingVerify={loadingVerify}
              setReturnApi={setReturnApi}
              setTabIndex={setTabIndex}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} style={{ background: "#FFF", borderRadius: '12px', padding: '20px', minHeight: '60vh' }}>
            <DataDisplayTable loadingAdd={loadingAdd} setLoadingAdd={setLoadingAdd} data={dataR} handleSubmit={preSubmit} setData={setDataR} variablesOfTemplate={variablesOfTemplate} page={padronizationPage} setPage={setPadronizationPage} copyRespostas={copyRespostas} />
          </TabPanel>
        </Grid>
      </Grid>
      <ModalR
        cancelText={!fieldChannelSelected ? "Voltar" : "Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={!fieldChannelSelected ? "" : "Sim, tenho certeza"}
        subTitle={!fieldChannelSelected ? "Selecione a coluna onde esteja os WhatsApps ou Emails em sua listagem"
          : "Você deseja finalizar a importação da listagem?"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
        title={!fieldChannelSelected ? "Alguns campos são obrigatórios"
          : verifyEmptyVariables ? `Existem ${countEmptyVariables} campos sem seleção` : "Finalizar importação"}
        emoji={verifyEmptyVariables ? "emoji04" : "emoji01"}
        iconColor={ThemeColors.purple400}
      />
    </>
  );
}

export default PageAddApis;
