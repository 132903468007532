// This component is responsible for adding a new template to the system. The user can choose the type of message that he wants to create, and then he can customize the message as he wants.
// The user can also preview the message that he is creating, and then save it to the system.
// Author: Vitor de Jesus
// Updated: 24/02/2025

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, FormControl, Grid, TextField, useMediaQuery } from '@mui/material';

import { GetVariables } from '../../api/backend/Templates/GetVariables';
import { isAuthenticated } from '../../authMiddleware';
import ViewWhatsAppScreen from '../../components/WhatsAppScreen/ViewWhatsAppScreen';
import { CreateTemplate } from '../../api/backend/Templates/CreateTemplate';

import FormWhatsApp from './TemplateFormWhatsApp';
import FormSMS from './TemplateFormSMS';
import FormEmail from './TemplateFormEmail';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Footer from '../../components/Footer/Footer';
import EmailFormShowCase from '../../components/EmailFormShowCase/EmailFormShowCase';
import { personalizedBackgrounds } from '../../utils/templates';
import Loading from '../../components/Loading/Loading';
import CampaignModal from '../../components/Modals/CampaignModal';
import { notify } from '../../utils/utils';

function TemplateAdd(props) {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    const Navigate = useNavigate();

    const creatingCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("creating_campaign")) || null;
    const [title, setTitle] = useState('');
    const [typeMessage, setTypeMessage] = useState('Atrasos');
    const [selected, setSelected] = useState('Atrasos');
    const buttons = ['Atrasos', 'Avisos', 'Faturas', 'Inadimplências', 'Marketing', 'Outros'];
    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    const [showPreview, setShowPreview] = useState(false);
    const [openModalCampaign, setOpenModalCampaign] = useState(false);

    const [emailTitle, setEmailTitle] = useState('');
    const [initialGreeting, setInitialGreeting] = useState('');
    const [messageTemporaryEmail, setMessageTemporaryEmail] = useState('');
    const [isEmailTemplatePersonalized, setIsEmailTemplatePersonalized] = useState(JSON.parse(localStorage.getItem('isEmailTemplatePersonalized')) || false);
    const [background, setBackground] = useState(JSON.parse(localStorage.getItem('isEmailTemplatePersonalized')) ? '' : personalizedBackgrounds[5].base64);

    const [messageTemporaryWhatsApp, setMessageTemporaryWhatsApp] = useState('');
    const [integration_system, setIntegrationSystem] = useState('codeChat');

    const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
    const [palletOfColors, setPalletOfColors] = useState('N/A');

    const [messageTemporary, setMessageTemporary] = useState('');
    const [typeChannel, setTypeChannel] = useState(props.typeChannel)

    const [showLoading, setShowLoading] = useState(true);

    const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
        messages: {
            messages: []
        }
    });

    useEffect(() => {
        if (typeChannel === 'WhatsApp') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'whatsapp',
                messages: {
                    messages: [],
                },
            }));
        } else if (typeChannel === 'SMS') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'sms',
                messages: {
                    messages: [],
                },
            }));
        } else if (typeChannel === 'E-mail') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'email',
                messages: {
                    messages: [],
                },
            }));
        }

        setMessageTemporary('');

    }, [typeChannel]);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (typeChannel === 'SMS') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                messages: {
                    messages: [{ id: prevProps.messages.messages.length + 1, type: 'sms', message: messageTemporary, fromMe: true }],
                },
            }));
        } else if (typeChannel === 'E-mail') {

            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                messages: {
                    messages: [{ id: prevProps.messages.messages.length + 1, type: 'email', message: messageTemporary, fromMe: true, titleEmail: emailTitle, initialGreeting: initialGreeting }
                    ],
                },
            }));

        }

    }, [messageTemporary, emailTitle, initialGreeting]);

    const fetchData = async () => {
        try {
            const translations = {
                "Personal_information": "Informações Pessoais",
                "Contact": "Contato",
                "Address": "Endereço",
                "Financial_References": "Referências Financeiras"
            };

            const resultJson = await GetVariables();

            console.log('resultJson', resultJson);

            const result = resultJson.variables.reduce((acc, item) => {
                const category = translations[item.category] || item.category;
                if (!acc[category]) {
                    acc[category] = [];
                }

                acc[category].push(item.variableName);

                return acc;
            }, {});

            setVariablesOfTemplate(result);

        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    const saveTemplate = async () => {

        if (typeChannel === 'E-mail' && (emailTitle === '' || initialGreeting === '' || viewWhatsAppScreenProps.messages.messages.length === 0)) {
            console.log('typeChannel', typeChannel, 'emailTitle', emailTitle, 'initialGreeting', initialGreeting, 'mensagens', viewWhatsAppScreenProps.messages.messages.length);
            return;
        }

        const get_messages = viewWhatsAppScreenProps.messages.messages.map((message) => {
            return message.message;
        });

        let info = {}

        if (typeChannel === 'E-mail') {

            info = {
                'title': title,
                'messages': get_messages,
                'msg_type': typeMessage,
                'channel_type': typeChannel,
                'type': 'Custom123',
                'background': background,
                'type': isEmailTemplatePersonalized ? 'Custom' : 'Custom123'

            }

        } else if (typeChannel === 'WhatsApp') {

            info = {
                'title': title,
                'messages': get_messages,
                'msg_type': typeMessage,
                'channel_type': typeChannel,
                'type': 'Custom123'

            }
        } else if (typeChannel === 'SMS') {

            info = {
                'title': title,
                'messages': get_messages,
                'msg_type': typeMessage,
                'channel_type': typeChannel,
                'type': 'Custom123'
            }
        }

        info = JSON.stringify(info);

        const resultJson = await CreateTemplate(info);

        if (resultJson['message'] === 'success') {
            notify("Success", "Modelo criado com sucesso!", true);
            creatingCampaignWithHelpMode === true ? setOpenModalCampaign(true) : Navigate("/modelos");
        }
    }

    const handleClick = (value) => {
        setSelected(value);
        setTypeMessage(value);
    };

    useEffect(() => {
        setTimeout(() => setShowLoading(false), 1000);
    }, []);

    const verifyFieldsBeforeSave = async () => {
        if (title === "") {
            notify("Error", "Preencha o título do modelo", true);
        } else if ((typeChannel === "SMS" && messageTemporary.length === 0) || (typeChannel === "WhatsApp" && viewWhatsAppScreenProps.messages.messages.length === 0)) {
            notify("Error", "Escreva uma mensagem antes de salvar o modelo", true);
        } else if (title === "" && messageTemporary.length === 0) {
            notify("Error", "Preencha todos os campos", true);
        } else {
            await saveTemplate();
        }
    }

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>
            <HeaderPage />
            <Box sx={{ position: 'relative', minHeight: { xs: 'calc(100vh - 75px)', sm: 'calc(100vh - 100px)' }, background: "#F9F9F9" }}>
                <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                    <Grid item xs={12} lg={6} xl={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ flexGrow: 1, justifyContent: 'center', backgroundColor: '#F9F9F9', width: { xs: 'calc(100% - 20px)', sm: "561.200px", lg: "90%" }, margin: { xs: '0 auto 20px', lg: '0 auto' }, padding: { xs: 0, lg: "20px 0px 20px 30px", xl: "20px" } }}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Nome do seu Modelo"
                                    variant="standard"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    InputLabelProps={{
                                        style: { fontSize: '20px' }  // Ajuste o tamanho da fonte conforme necessário
                                    }}

                                />
                            </FormControl>
                            <Box item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                {buttons.map((button, index) => (
                                    <Button
                                        key={button}
                                        onClick={() => handleClick(button)}
                                        sx={{
                                            flexGrow: 1,
                                            mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                                            bgcolor: selected === button ? '#8142FC' : ThemeColors.gray30,
                                            color: '#fff',
                                            borderRadius: '27px',
                                            '&:hover': { bgcolor: selected === button ? '#8142FC' : 'gray' },
                                            fontSize: '12px',
                                            padding: '4px 16px'
                                        }}
                                    >
                                        {button}
                                    </Button>
                                ))}
                            </Box>

                            <Box sx={{ paddingTop: { xs: '20px', sm: '0px' }, marginBottom: (isSmallScreen && showPreview) ? '20px' : 'none', position: 'relative' }}>
                                <Box sx={{
                                    color: 'rgb(129, 66, 252)',
                                    fontSize: '18px',
                                    position: 'absolute',
                                    top: { xs: (typeChannel === 'WhatsApp' || typeChannel === 'SMS') ? '16px' : (typeChannel === 'E-mail' && showPreview) ? '16px' : '0px', sm: (typeChannel === 'WhatsApp' || typeChannel === 'SMS') ? '16px' : (typeChannel === 'E-mail' && showPreview) ? '16px' : '0px' },
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    display: isSmallScreen ? 'block' : 'none',
                                    fontFamily: 'Satoshi',
                                    cursor: 'pointer',
                                    zIndex: '10'
                                }} onClick={() => setShowPreview(prev => !prev)}>
                                    {
                                        !showPreview ? "Exibir Prévia" : "Esconder Prévia"
                                    }
                                </Box>
                                <Box sx={{
                                    display: (isSmallScreen && showPreview) ? 'none' : 'block'
                                }}>
                                    {
                                        (typeChannel === 'WhatsApp') ? (
                                            <FormWhatsApp messageTemporaryWhatsApp={messageTemporaryWhatsApp} setMessageTemporary={setMessageTemporaryWhatsApp} palletOfColors={palletOfColors} setPalletOfColors={setPalletOfColors} variablesOfTemplate={variablesOfTemplate} typeMessage={typeMessage} setTypeMessage={setTypeMessage} setViewWhatsAppScreenProps={setViewWhatsAppScreenProps} viewWhatsAppScreenProps={viewWhatsAppScreenProps} integration_system={integration_system} setIntegrationSystem={setIntegrationSystem} saveTemplate={verifyFieldsBeforeSave} />
                                        ) : (typeChannel === 'SMS') ? (
                                            <FormSMS messageTemporary={messageTemporary} setMessageTemporary={setMessageTemporary} palletOfColors={palletOfColors} setPalletOfColors={setPalletOfColors} variablesOfTemplate={variablesOfTemplate} typeMessage={typeMessage} setTypeMessage={setTypeMessage} setViewWhatsAppScreenProps={setViewWhatsAppScreenProps} viewWhatsAppScreenProps={viewWhatsAppScreenProps} saveTemplate={verifyFieldsBeforeSave} />
                                        ) : null
                                    }
                                </Box>
                            </Box>

                            <Box sx={{ display: { xs: 'none', lg: 'flex' }, justifyContent: 'start', alignItems: 'end', minHeight: '80px' }}>
                                <Footer />
                            </Box>
                        </Box>
                    </Grid>
                    {
                        (isSmallScreen && showPreview === true) ? (
                            <Grid item xs={12} lg={6} xl={6} padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}>
                                {
                                    typeChannel === 'WhatsApp' || typeChannel === 'SMS' ? (
                                        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFF', padding: '20px', borderRadius: '15px', minHeight: { xs: "696px", lg: "100%", xl: "100%" }, width: { xs: "100%", sm: "561.200px", lg: "100%", xl: "100%" }, margin: { xs: "0 auto", lg: "0", xl: "0" } }}>
                                            <ViewWhatsAppScreen {...viewWhatsAppScreenProps} emailTitle={emailTitle} setEmailTitle={setEmailTitle} initialGreeting={initialGreeting} setInitialGreeting={setInitialGreeting} message={messageTemporaryEmail} setMessageTemporary={setMessageTemporaryEmail} open={true} palletOfColors={palletOfColors} setPalletOfColors={setPalletOfColors} setViewWhatsAppScreenProps={setViewWhatsAppScreenProps} viewWhatsAppScreenProps={viewWhatsAppScreenProps} />
                                        </Box>
                                    ) : (
                                        <EmailFormShowCase isEmailTemplatePersonalized={isEmailTemplatePersonalized} greeting={initialGreeting} titleModel={title} title={emailTitle} message={messageTemporary} background={background} setBackground={setBackground} />
                                    )
                                }
                            </Grid>
                        ) : (!isSmallScreen) ? (
                            <Grid item xs={12} lg={6} xl={6} padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}>
                                {
                                    typeChannel === 'WhatsApp' || typeChannel === 'SMS' ? (
                                        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFF', padding: '20px', borderRadius: '15px', minHeight: { xs: "696px", lg: "100%", xl: "100%" }, width: { xs: "561.200px", lg: "100%", xl: "100%" }, margin: { xs: "0 auto", lg: "0", xl: "0" } }}>
                                            <ViewWhatsAppScreen {...viewWhatsAppScreenProps} emailTitle={emailTitle} setEmailTitle={setEmailTitle} initialGreeting={initialGreeting} setInitialGreeting={setInitialGreeting} message={messageTemporaryEmail} setMessageTemporary={setMessageTemporaryEmail} open={true} palletOfColors={palletOfColors} setPalletOfColors={setPalletOfColors} setViewWhatsAppScreenProps={setViewWhatsAppScreenProps} viewWhatsAppScreenProps={viewWhatsAppScreenProps} />
                                        </Box>
                                    ) : (
                                        <EmailFormShowCase isEmailTemplatePersonalized={isEmailTemplatePersonalized} greeting={initialGreeting} titleModel={title} title={emailTitle} message={messageTemporary} background={background} setBackground={setBackground} />
                                    )
                                }
                            </Grid>
                        ) : null
                    }
                    <Box sx={{ padding: { xs: "20px 0 0 25px", sm: "20px 0 0 0" }, display: { xs: "block", lg: "none" }, width: { xs: "100%", sm: "561.200px", lg: "100%", xl: "100%" }, margin: { sm: "0 auto", lg: "0", xl: "0" } }}>
                        <Footer />
                    </Box>
                </Grid>

            </Box>
            <CampaignModal
                cancelText="Criar mais um modelo"
                confirmText="Retornar ao formulário"
                subTitle={"Percebemos que você está configurando uma campanha. Gostaria de retornar ao formulário?"}
                isOpen={openModalCampaign}
                onClose={() => setOpenModalCampaign(false)}
                onCancel={() => Navigate("/modelos/canal")}
                onConfirm={() => (Navigate("/campanhas/adicionar/modo-ajuda"), sessionStorage.setItem("ReturToCampaign", true))}
                title={"Modelo criado com sucesso"}
                iconColor={ThemeColors.purple400}
            />
        </>
    );
}

export default TemplateAdd;