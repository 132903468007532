// Description: This component render a Dynamic Form that will need a HTML file
// Update:  18/11/2024
// Author: Simon Franklin

// React Components and DOM Elements
import React, { useState } from "react";

// Material UI Components
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";
import Footer from "../Footer/Footer";
import Titles from "../Titles/Titles";
import StatusIcon from "../StatusLabel/StatusIcon";
import { createObjectFromArray } from "../../utils/utils";
import TemplateInput from "../Input/DynamicTemplateInput";
import { notify } from "../../utils/utils";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import DynamicButton from "../DynamicButton/DynamicButton";

// render Your Component
const DynamicForm = ({
  title,
  setTitle,
  updatedHtml,
  variablesOfTemplate,
  emailTitle,
  setEmailTitle,
  setTypeMessage,
  variables,
  handleSubmit,
  formState,
  setFormState,
  selected,
  setSelected,
  edit = false,
  mapHTMLColors,
  handleColorChange,
  selectedColor,
  isHTMLTemplate,
  dropzone = false,
  templateBackgroundColor = false,
}) => {
  // Form States default
  const [isVisible, setIsVisible] = useState(
    createObjectFromArray(variables, false)
  );
  const [expanded, setExpanded] = useState(
    createObjectFromArray(variables, false)
  );

  // Function to toggle the visibility of the Accordion Menu
  const toggleAccordionVisibility = (name) => {
    setIsVisible((prevState) => ({
      ...prevState,
      [name]: !isVisible[name],
    }));
  };

  // Function to handle the change of each accordion
  const handleChangeAccordion = (variable, category) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [variable]: isExpanded ? category : false,
    }));
  };

  const buttons = [
    "Atrasos",
    "Avisos",
    "Faturas",
    "Inadimplências",
    "Marketing",
    "Outros",
  ];

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const [showPreview, setShowPreview] = useState(isSmallScreen ? false : true);
  const [isPreviewVisible, setIsPreviewVisible] = useState(
    edit ? (formState.Logo ? true : false) : false
  );
  const [selectedFile, setSelectedFile] = useState(
    edit ? (formState.Logo ? formState.Logo : null) : null
  );

  // Function to handle the file change
  const handleFileChange = (acceptedFiles) => {
    if (!acceptedFiles.length) return;

    const file = acceptedFiles[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = async () => {
          if (img.width > 300 || img.height > 300) {
            notify("Error", "A imagem precisa ser menor que 300x300px", true);
            return;
          }

          const base64String = reader.result;
          setSelectedFile({ file, base64String });
          setIsPreviewVisible(true);

          try {
            const token = localStorage.getItem("jwt_token");
            const clientId = localStorage.getItem("idToken");

            if (!token) throw new Error("Token de autenticação não encontrado");
            if (!clientId) throw new Error("ID do cliente não encontrado");

            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_AWS_URL}/templates/upload-image`,
              { imagebase64: base64String },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${token}`,
                  Client_ID: `${clientId}`,
                },
              }
            );

            setFormState((prev) => ({
              ...prev,
              Logo: response.data.image_url,
            }));
            notify("Success", "Arquivo adicionado com sucesso", true);
          } catch (error) {
            console.error("Erro ao enviar imagem:", error);
            notify("Error", "Falha ao enviar imagem", true);
          }
        };
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Por favor, selecione apenas imagens.");
      notify("Error", "Por favor, selecione apenas imagens.");
      setSelectedFile(null);
      setIsPreviewVisible(false);
    }
  };

  // Function to change the TypeMessage
  const handleClick = (value) => {
    setSelected(value);
    setTypeMessage(value);
  };

  // Function to handle the input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  return (
    <Grid container spacing={2} sx={{ paddingTop: { sm: "20px" } }}>
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#F9F9F9",
            width: { sm: "561.200px", lg: "90%" },
            margin: { sm: "0 auto 20px", lg: "0 auto" },
            padding: {
              xs: "20px",
              sm: 0,
              lg: "20px 0px 20px 30px",
              xl: "20px",
            },
          }}
        >
          <Box>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Nome do seu Modelo"
                variant="standard"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  style: { fontSize: "20px" },
                }}
              />
            </FormControl>
            <Box
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                overflowX: { xs: "auto", sm: "none" },
              }}
            >
              {buttons.map((button, index) => (
                <Button
                  key={button}
                  onClick={() => handleClick(button)}
                  sx={{
                    flexGrow: 1,
                    mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                    bgcolor:
                      selected === button ? "#8142FC" : themeColors.gray30,
                    color: "#fff",
                    borderRadius: "27px",
                    "&:hover": {
                      bgcolor: selected === button ? "#8142FC" : "gray",
                    },
                    fontSize: "12px",
                    padding: { xs: "4px 10px", sm: "4px 16px" },
                  }}
                >
                  {button}
                </Button>
              ))}
            </Box>
            <Box
              position={"relative"}
              marginBottom={isSmallScreen && showPreview ? "20px" : "none"}
            >
              <Box
                sx={{
                  color: "rgb(129, 66, 252)",
                  fontSize: "18px",
                  position: "absolute",
                  top: {
                    xs: showPreview ? "16px" : "-5px",
                    sm: showPreview ? "16px" : "0px",
                  },
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: isSmallScreen ? "block" : "none",
                  fontFamily: "Satoshi",
                  cursor: "pointer",
                  zIndex: "10",
                }}
                onClick={() => setShowPreview((prev) => !prev)}
              >
                {!showPreview ? "Exibir Prévia" : "Esconder Prévia"}
              </Box>
              <Box
                sx={{
                  display: isSmallScreen && showPreview ? "none" : "block",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap="15px"
                  marginTop={"20px"}
                  marginBottom={"0px"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"5px"}
                    minWidth={"100%"}
                  >
                    <Titles
                      children={"Título do Email"}
                      color={"#52525B"}
                      type={"P1"}
                    />
                    <TextField
                      placeholder="Digite o título aqui :)"
                      fullWidth
                      rows={1}
                      variant="outlined"
                      value={emailTitle}
                      onChange={(e) => setEmailTitle(e.target.value)}
                      autoComplete="off"
                      style={{
                        background: "white",
                        color: "black",
                        borderRadius: "12px",
                        margin: 0,
                        border: `1px solid ${themeColors.gray20}`,
                        color: themeColors.gray40,
                      }}
                      sx={{
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "14px",
                        },
                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "6px",
                        },
                      }}
                    />
                  </Box>
                  {variables.map((variable) =>
                    variable !== "Logo" ? (
                      <TemplateInput
                        setFormState={setFormState}
                        field={variable}
                        handleChangeAccordion={handleChangeAccordion}
                        handleInputChange={handleInputChange}
                        toggleAccordionVisibility={toggleAccordionVisibility}
                        variablesOfTemplate={variablesOfTemplate}
                        expanded={expanded}
                        formState={formState}
                        isVisible={isVisible}
                        setExpanded={setExpanded}
                        key={variable}
                      />
                    ) : null
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      minWidth: "100%",
                      justifyContent: { xs: "start", sm: "space-between" },
                      flexWrap: { xs: "wrap", sm: "nowrap" },
                      gap: { xs: "16px", sm: "none" },
                    }}
                  >
                    {isHTMLTemplate && formState.Text !== null && (
                      <Box
                        display={"flex"}
                        gap={"7px"}
                        alignItems={"center"}
                        height={"48px"}
                      >
                        {mapHTMLColors.map((color) => (
                          <Box
                            key={color}
                            sx={{
                              width: 50,
                              height: 50,
                              backgroundColor: color,
                              cursor: "pointer",
                              borderRadius: "6px",
                              opacity: selectedColor === color ? 1 : 0.3,
                            }}
                            onClick={() => handleColorChange(color)}
                          />
                        ))}
                      </Box>
                    )}
                    <DynamicButton
                      handleFunction={handleSubmit}
                      style={{
                        width: "238px",
                        padding: "13px",
                        height: "48px",
                      }}
                      variant={"contained"}
                      text={edit ? "Editar Modelo" : "Salvar Modelo"}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              paddingTop: { xs: "20px", sm: "30px" },
              display: isSmallScreen && showPreview ? "none" : "block",
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Grid>
      {isSmallScreen && showPreview === true ? (
        <Grid
          item
          xs={12}
          lg={6}
          xl={6}
          padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
        >
          <Box
            borderRadius={"12px"}
            overflow={"hidden"}
            border={"1px solid #A986FF"}
            sx={{
              backgroundColor: "#fff",
              maxWidth: { sm: "561.200px", lg: "628px" },
              minHeight: { sm: "668px", lg: "90vh" },
              margin: { xs: "0 20px", sm: "0 auto", lg: "0 60px 0 0" },
            }}
          >
            <Box
              zIndex={"2"}
              padding={"12px"}
              display={"flex"}
              gap="12px"
              alignItems={"center"}
              minWidth={"100%"}
              sx={{ backgroundColor: "#8142FC", color: "#fff" }}
            >
              <StatusIcon
                status={"E-mail"}
                height={"26px"}
                width={"26px"}
                color="#fff"
              />
              <Titles children={title} color={"#fff"} type={"H4"} />
            </Box>
            <Box padding={"20px"} position={dropzone ? "relative" : "unset"}>
              {dropzone ? (
                <Dropzone
                  onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        marginTop: "10px",
                        height: selectedFile ? "85px" : "90px",
                        width: selectedFile ? "160px" : "500px",
                        background: selectedFile
                          ? "none"
                          : selectedColor
                            ? selectedColor
                            : "#6558de",
                        borderRadius: selectedFile ? "0" : "10px",
                        overflow: "hidden",
                        position: "absolute",
                        top: "30px",
                        left: selectedFile ? "38px" : "40px",
                        zIndex: "10",
                      }}
                    >
                      <input {...getInputProps()} />
                      {selectedFile && isPreviewVisible && !edit ? (
                        <div>
                          {selectedFile.file.type.startsWith("image/") && (
                            <div>
                              <img
                                style={{
                                  width: "170px",
                                  height: "95px",
                                  padding: "20px 48px 0 48px",
                                  objectFit: "contain",
                                  background: selectedColor
                                    ? selectedColor
                                    : "#6558de",
                                  borderRadius: "10px",
                                }}
                                src={selectedFile.base64String}
                                alt="Logo"
                              />
                            </div>
                          )}
                        </div>
                      ) : edit && isPreviewVisible && formState.Logo ? (
                        <div>
                          <img
                            style={{
                              width: "170px",
                              height: "95px",
                              padding: "20px 48px 0 48px",
                              objectFit: "contain",
                              background: selectedColor
                                ? selectedColor
                                : "#6558de",
                              borderRadius: "10px",
                            }}
                            src={formState.Logo}
                            alt="Logo"
                          />
                        </div>
                      ) : (
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "start",
                            background: selectedColor
                              ? selectedColor
                              : "#6558de",
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                            paddingLeft: "48px",
                          }}
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: "20px",
                              color:
                                selectedColor === "#FFFFFF"
                                  ? themeColors.purple500
                                  : themeColors.purple50,
                              width: "30px",
                              height: "30px",
                            }}
                          />
                          <Titles
                            children={
                              "Clique para importar sua Logo Personalizada"
                            }
                            color={
                              selectedColor === "#FFFFFF"
                                ? themeColors.purple500
                                : themeColors.purple50
                            }
                            styles={{ fontSize: "14px", fontWeight: "500" }}
                          />
                        </Box>
                      )}
                    </div>
                  )}
                </Dropzone>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              padding: { xs: "20px", sm: "30px" },
              display: { xs: "block", sm: "none" },
            }}
          >
            <Footer />
          </Box>
        </Grid>
      ) : !isSmallScreen ? (
        <Grid
          item
          xs={12}
          lg={6}
          xl={6}
          padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
        >
          <Box
            borderRadius={"12px"}
            overflow={"hidden"}
            border={"1px solid #A986FF"}
            sx={{
              backgroundColor: "#fff",
              maxWidth: { xs: "561.200px", lg: "628px" },
              minHeight: { xs: "668px", lg: "90vh" },
              margin: { xs: "0 auto", lg: "0 60px 0 0" },
            }}
          >
            <Box
              zIndex={"2"}
              padding={"12px"}
              display={"flex"}
              gap="12px"
              alignItems={"center"}
              minWidth={"100%"}
              sx={{ backgroundColor: "#8142FC", color: "#fff" }}
            >
              <StatusIcon
                status={"E-mail"}
                height={"26px"}
                width={"26px"}
                color="#fff"
              />
              <Titles children={title} color={"#fff"} type={"H4"} />
            </Box>
            <Box padding={"20px"} position={dropzone ? "relative" : "unset"}>
              {dropzone ? (
                <Dropzone
                  onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        marginTop: "10px",
                        height: selectedFile ? "85px" : "90px",
                        width: selectedFile ? "160px" : "500px",
                        background: selectedFile
                          ? "none"
                          : selectedColor
                            ? selectedColor
                            : "#6558de",
                        borderRadius: selectedFile ? "0" : "10px",
                        overflow: "hidden",
                        position: "absolute",
                        top: "30px",
                        left: selectedFile ? "38px" : "40px",
                        zIndex: "10",
                      }}
                    >
                      <input {...getInputProps()} />
                      {selectedFile && isPreviewVisible && !edit ? (
                        <div>
                          {selectedFile.file.type.startsWith("image/") && (
                            <div>
                              <img
                                style={{
                                  width: "170px",
                                  height: "95px",
                                  padding: "20px 48px 0 48px",
                                  objectFit: "contain",
                                  background: selectedColor
                                    ? selectedColor
                                    : "#6558de",
                                  borderRadius: "10px",
                                }}
                                src={selectedFile.base64String}
                                alt="Logo"
                              />
                            </div>
                          )}
                        </div>
                      ) : edit && isPreviewVisible && formState.Logo ? (
                        <div>
                          <img
                            style={{
                              width: "170px",
                              height: "95px",
                              padding: "20px 48px 0 48px",
                              objectFit: "contain",
                              background: selectedColor
                                ? selectedColor
                                : "#6558de",
                              borderRadius: "10px",
                            }}
                            src={formState.Logo}
                            alt="Logo"
                          />
                        </div>
                      ) : (
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "start",
                            background: selectedColor
                              ? selectedColor
                              : "#6558de",
                            width: "100%",
                            height: "100%",
                            borderRadius: "4px",
                            paddingLeft: "48px",
                          }}
                        >
                          <CloudUploadIcon
                            style={{
                              fontSize: "20px",
                              color:
                                selectedColor === "#FFFFFF"
                                  ? themeColors.purple500
                                  : themeColors.purple50,
                              width: "30px",
                              height: "30px",
                            }}
                          />
                          <Titles
                            children={
                              "Clique para importar sua Logo Personalizada"
                            }
                            color={
                              selectedColor === "#FFFFFF"
                                ? themeColors.purple500
                                : themeColors.purple50
                            }
                            styles={{ fontSize: "14px", fontWeight: "500" }}
                          />
                        </Box>
                      )}
                    </div>
                  )}
                </Dropzone>
              ) : null}
              <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DynamicForm;
