// Description: This component render a Input that will receive and change a value
// Update: 10/02/2025
// Author: Simon Franklin

// React Components and DOM Elements
import React, { useEffect, useRef, useState } from "react";

// Material UI Components
import {
  Grid,
  FormControl,
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
} from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";
import Titles from "../Titles/Titles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updatedField } from "../../utils/utils.js";

// Import Styles
import "./TemplateInput.css";

// Render The component
const TemplateInput = ({
  field,
  handleInputChange,
  variablesOfTemplate,
  toggleAccordionVisibility,
  handleChangeAccordion,
  formState,
  isVisible,
  expanded,
  setExpanded,
  setFormState,
}) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const handleFocus = () => {
    const input = inputRef.current;
    if (input) {
      setCursorPosition(input.selectionStart);
    }
  };

  const closeAccordion = () => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [field]: null,
    }));
  };

  const addVariableInField = (name, value) => {
    const text = formState[name];
    const position = cursorPosition ?? text.length;
    const newText =
      text.slice(0, position) + ` {{${value}}}` + text.slice(position);

    setFormState((prevState) => ({
      ...prevState,
      [name]: newText,
    }));

    setTimeout(() => {
      const input = inputRef.current;
      if (input) {
        const newCursorPosition = position + ` {{${value}}}`.length;
        input.focus();
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  };

  function translateInputHeight(input) {
    if (input.includes("Title")) {
      return "51px";
    } else if (input.includes("Text")) {
      return "240px";
    } else {
      return "51px";
    }
  };

  function translateString(input) {
    if (input.includes("Title")) {
      return "Seu Título";
    } else if (input.includes("Text")) {
      return "Seu Texto";
    } else {
      return input;
    }
  };

  const editorStyle = {
    backgroundColor: "white",
    color: formState[field] ? "black" : themeColors.gray40,
    borderRadius: "12px",
    margin: 0,
    border: `1px solid ${themeColors.gray20}`,
    minHeight: "40px",
    whiteSpace: "pre-wrap",
    padding: "0px",
    maxHeight: translateInputHeight(field),
    minHeight: translateInputHeight(field),
    outline: "none",
    overflowY: field.includes("Text") ? "auto" : "default",
    '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      padding: '0px'
    },
    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '14px'
    },
    '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '14px'
    },
    '.css-1ntdzxq-MuiInputBase-root-MuiOutlinedInput-root': {
      padding: '0px'
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="5px" minWidth="100%">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Titles children={field.includes("Title") ? "Título" : "Texto"} color="#52525B" type="P1" />
        </Grid>
        {variablesOfTemplate && (
          <Grid item xs={6} style={{ position: "relative" }}>
            <FormControl fullWidth>
              <Typography
                onClick={() => {
                  handleFocus();
                  toggleAccordionVisibility(field);
                }}
                style={{
                  color: themeColors.purple500,
                  marginRight: "5px",
                  marginTop: "2px",
                  fontFamily: "Satoshi-Medium",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                {isVisible[field]
                  ? "Esconder Variáveis"
                  : "+ Adicionar Variáveis"}
              </Typography>
              {isVisible[field] && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "100%",
                    width: "auto",
                    backgroundColor: themeColors.purple200,
                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    overflow: "hidden",
                    zIndex: 1,
                    borderRadius: "12px",
                  }}
                >
                  {Object.entries(variablesOfTemplate).map(
                    ([category, values]) => (
                      <Accordion
                        key={category}
                        expanded={expanded[field] === category}
                        onChange={handleChangeAccordion(field, category)}
                        style={{
                          background: themeColors.purple200,
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{category}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          {values.length > 0 ? (
                            values.map((value) => (
                              <MenuItem
                                key={value}
                                onClick={() => {
                                  addVariableInField(field, value);
                                  toggleAccordionVisibility(field);
                                  closeAccordion();
                                }}
                                style={{ color: themeColors.purple950 }}
                              >
                                {value}
                              </MenuItem>
                            ))
                          ) : (
                            <Typography>Adicionar variáveis</Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </div>
              )}
            </FormControl>
          </Grid>
        )}
      </Grid>
      <TextField
        multiline={field.includes("Title") ? false : true}
        minRows={field.includes("Title") ? 1 : 9}
        inputRef={(el) => {
          inputRef.current = el;
        }}
        inputProps={{
          sx: {

          }
        }}
        value={formState[field]}
        placeholder={translateString(field)}
        sx={editorStyle}
        onFocus={handleFocus}
        onClick={handleFocus}
        onKeyUp={handleFocus}
        onChange={(e) => {
          handleInputChange({ target: { name: field, value: e.target.value } })
          handleFocus()
        }}
      />
    </Box>
  );
};

export default TemplateInput;