// Description: Modal that's activated when the user click one of the channel cards
// Update: 17/02/2025

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../authMiddleware';
import themeColors from '../ThemeColors/ThemeColors';

import { Box, InputLabel, MenuItem, Select, styled, Button, Dialog, FormControl, TextField } from '@mui/material';

import Titles from '../Titles/Titles';
import StatusIcon from '../StatusLabel/StatusIcon';
import DynamicButton from '../DynamicButton/DynamicButton';

const CardModal = ({ type, onClose, isOpen, input, setInput, onConfirm, input2, setInput2, input3, setInput3 }) => {

    const { authenticated } = isAuthenticated();
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const getTitle = () => {
        if (type === 'WhatsApp') {
            return 'Solicite o Whatsapp'
        } else if (type === 'WhatsApp Business') {
            return 'Solicite o Whatsapp Business'
        } else if (type === 'Email') {
            return 'Integre seu email'
        } else if (type === 'SMS') {
            return 'Solicite o envio de SMS.'
        }
    }

    const getText = () => {
        if (type === 'WhatsApp') {
            return 'Solicite o Whatsapp'
        } else if (type === 'WhatsApp Business') {
            return ''
        } else if (type === 'Email') {
            return 'Informe uma identificação e o email que deseja integrar.'
        } else if (type === 'SMS') {
            return <span>Para solicitar o envio de SMS, escreva <strong>solicitar</strong> no campo abaixo.</span>
        }
    }

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        } else {
            navigate('/login');
        }
    }, []);

    return (
        <div style={{ display: isOpen ? 'block' : 'none' }}>
            <Box onClick={onClose} sx={{
                height: '100vh',
                width: '100vw',
                position: 'fixed',
                top: '0',
                left: '0',
                display: 'block',
                background: '#000',
                opacity: '0.5'
            }}
            />
            <Box sx={{
                borderRadius: "12px",
                width: { xs: '95%', sm: "100%" },
                minHeight: { sm: "427px" },
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "461px !important",
                boxShadow: "4px 4px 12px rgba(0, 0, 0, 0.3)",
                overflow: "hidden"
            }}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ textAlign: 'center', backgroundColor: '#fff', height: '100%', padding: { xs: '30px', sm: '50px' } }}>
                    <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px', marginBottom: '30px' }}>
                        <Titles font={'medium'} children={getTitle()} color={'#8033F4'} styles={{ fontSize: { xs: "20px", sm: "24px" } }} />
                        <Box
                            sx={{
                                fontFamily: 'Satoshi',
                                fontSize: { xs: '16px', sm: '16px' },
                            }}
                        >
                            {type === 'SMS' && (
                                <span>Os envios de SMS na plataforma são realizados através de um <strong style={{ fontWeight: 'bold' }}>canal compartilhado</strong> de acordo com a disponibilidade da plataforma.
                                </span>

                            ) || type === 'WhatsApp' && (
                                <span>Após a configuração do seu WhatsApp <strong style={{ fontWeight: 'bold' }}>meta/facebook</strong> adicione as informações abaixo para solicitar o envio de mensagens.
                                </span>
                            ) || type === 'WhatsApp Business' && (
                                <span>Após a configuração do seu WhatsApp <strong style={{ fontWeight: 'bold' }}>meta/facebook</strong> adicione as informações abaixo para solicitar o envio de mensagens.
                                </span>
                            ) || type === 'Email' && (
                                <span>Informe um nome para <strong style={{ fontWeight: 'bold' }}>identificar</strong> o email dentro da plataforma, e o endereço de <strong style={{ fontWeight: 'bold' }}>email</strong> que deseja integrar. Você receberá um email de confirmação para validar a integração.
                                </span>
                            )}

                        </Box>
                    </Box>
                    <Box textAlign={'start'} display='flex' flexDirection='column'>
                        <Titles type={'P2'} children={getText()} color={'#52525B'} />
                        <Box marginTop={'10px'}>
                            {
                                type === 'SMS' && (
                                    <TextField
                                        id='outlined-multiline-static'
                                        placeholder='solicitar'
                                        variant='outlined'
                                        value={input}
                                        onChange={e => setInput(e.target.value)}
                                        fullWidth
                                        autoComplete="new-password"
                                        sx={{
                                            background: error ? themeColors.red01 : '#f6f6f6',
                                            color: '#848484',
                                            borderRadius: '6px',
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                error ?
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                            <rect width="20" height="20" fill="#D9D9D9" />
                                                        </mask>
                                                        <g mask="url(#mask0_34_1183)">
                                                            <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                        </g>
                                                    </svg>
                                                    : null
                                            )
                                        }}
                                    />
                                ) || type === 'Email' && (
                                    <Box display={'flex'} gap='10px' flexDirection='column'>
                                        <TextField
                                            placeholder='Escolha um nome para identificação'
                                            autoComplete="new-password"
                                            style={{
                                                background: error && input2 === '' ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                outline: 'none',
                                                width: '100%',
                                                borderRadius: '6px',
                                                border: 'none'
                                            }}
                                            onChange={e => setInput2(e.target.value)}
                                            value={input2}
                                        />
                                        <TextField
                                            placeholder='Seu email'
                                            style={{
                                                background: error ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                outline: 'none',
                                                width: '100%',
                                                borderRadius: '6px',
                                                border: 'none'
                                            }}
                                            onChange={e => setInput(e.target.value)}
                                            value={input}
                                            autoComplete="new-password"
                                            InputProps={{
                                                endAdornment: (
                                                    error ?
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                                <rect width="20" height="20" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_34_1183)">
                                                                <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                            </g>
                                                        </svg>
                                                        : null
                                                )
                                            }}
                                        />
                                    </Box>
                                ) || type === 'WhatsApp Business' && (
                                    <>
                                        <TextField
                                            placeholder='Escolha um nome para identificação'
                                            label='Escolha um nome para identificação'
                                            autoComplete="new-password"
                                            style={{
                                                background: error && input3 === '' ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                outline: 'none',
                                                width: '100%',
                                                borderRadius: '6px',
                                                border: 'none',
                                                marginBottom: '10px'
                                            }}
                                            onChange={e => setInput3(e.target.value)}
                                            value={input3}
                                        />
                                        <TextField
                                            id='outlined-multiline-static'
                                            placeholder='Digite A URL ofericida pela Meta API'
                                            label='Digite A URL ofericida pela Meta API'
                                            variant='outlined'
                                            autoComplete="new-password"
                                            value={input}
                                            onChange={e => setInput(e.target.value)}
                                            fullWidth
                                            sx={{
                                                background: error ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                borderRadius: '6px',
                                                border: 'none',
                                                outline: 'none',
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    error ?
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                                <rect width="20" height="20" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_34_1183)">
                                                                <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                            </g>
                                                        </svg>
                                                        : null
                                                )
                                            }}
                                        />
                                        <TextField
                                            id='outlined-multiline-static'
                                            placeholder='Digite o token fixo da Meta API'
                                            label='Digite o token fixo da Meta API'
                                            variant='outlined'
                                            value={input2}
                                            onChange={e => setInput2(e.target.value)}
                                            fullWidth
                                            autoComplete="new-password"
                                            type='password'
                                            sx={{
                                                background: error ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                borderRadius: '6px',
                                                border: 'none',
                                                outline: 'none',
                                                marginTop: '10px',
                                            }}
                                        />
                                    </>
                                )
                            }
                        </Box>
                    </Box>
                    <Box display={'flex'} sx={{ flexDirection: 'column', gap: '7px', marginTop: '30px' }}>
                        <Button variant='outlined' style={{ padding: '10px' }} onClick={onClose}
                        >
                            Cancelar
                        </Button>
                        <DynamicButton variant={'contained'} text={'Confirmar Solicitação'} handleFunction={async() => await onConfirm()} style={{ padding: '10px' }} />
                    </Box>
                </Box>
                <div
                    style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '25px' }}
                    onClick={() => {
                        setError(false);
                        onClose();
                    }}
                >
                    <StatusIcon color={'#B7B7B7'} status={'Close'} width={'20px'} height={'20px'} />
                </div>
            </Box>
        </div>
    )
}

export default CardModal