// Desc: Page to add new APIs to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Button, FormHelperText, Select, MenuItem } from "@mui/material";
import ReactJson from 'react-json-view';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

import { Tab, Tabs } from "@mui/material";
import Dropzone from 'react-dropzone';
// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Loading from "../../components/Loading/Loading";

import { ExcelInsert } from "../../api/n8n/Listagens/ExcelInsert";

import { isAuthenticated } from "../../authMiddleware";
import { fi } from "date-fns/locale";
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { notify } from "../../utils/utils";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import * as XLSX from "xlsx";
import Titles from '../../components/Titles/Titles';

import FileCard from '../../components/FileCard/FileCard';
import { use } from "react";
import { set } from "date-fns";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ApiForm({ copyRespostas, setCopyRespostas, setErrors, errors, uuid_client, handleVerifyAPI, loadingVerify, setReturnApi, setTabIndex }) {

  const [selectedFiles, setSelectedFiles] = useState(copyRespostas.file || []);
  const [isPreviewVisible, setIsPreviewVisible] = useState( copyRespostas.file && copyRespostas.file.length > 0 ? true : false);
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#F9F9F9');
  const [errorFile, setErrorFile] = useState(false);
  const [itemJsonExample, setItemJsonExample] = useState({});

  useEffect(() => {

    console.log('errors', errors);

  }, [errors]);

  const handleInputChange = (name, value) => {
    setCopyRespostas((prev) => ({ ...prev, [name]: value }));

    if (name === 'body') {
      validateJson(value);
    }

  };

  const validateJson = (value) => {
    try {
      JSON.parse(value);
      setErrors((prev) => ({ ...prev, body: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, body: "JSON inválido" }));
    }
  };

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const fileType = file.type;
    if (fileType !== 'application/vnd.ms-excel' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setErrorFile(true);
      setSelectedFiles([]);
      setBackgroundColor(ThemeColors.red01);
      return;
    }

    setErrorFile(false);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rawJson = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (rawJson.length > 1) {
        const headers = rawJson[0];  
        const values = rawJson.slice(1); 

        const formattedJson = values.map((row) => {
          return row.reduce((acc, value, index) => {
            acc[headers[index]] = value; 
            return acc;
          }, {});
        });

        setItemJsonExample(formattedJson[0]);
        setCopyRespostas((prev) => ({ ...prev, item_example: formattedJson[0] }));

        
        setSelectedFiles(acceptedFiles);
        setBackgroundColor('#F9F9F9');
        setIsPreviewVisible(true);

        setCopyRespostas((prev) => ({ ...prev, file: acceptedFiles }));
        
      } else {
        setErrorFile(true);
        setSelectedFiles([]);
        setBackgroundColor(ThemeColors.red01);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const deleteFile = (file) => {
    setSelectedFiles([]);
    setCopyRespostas((prev) => ({ ...prev, file: null }));
    setCopyRespostas((prev) => ({ ...prev, item_example: null }));
  }

  return (
    <Grid item xs={12} md={12}
      style={{ background: '#FFF', borderRadius: '12px', padding: '20px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          autoFocus
          variant="outlined"
          name="title"
          label="Digite um nome para identificar a Listagem"
          value={copyRespostas.title || ""}
          onChange={(e) => handleInputChange("title", e.target.value)}
          sx={{ background: errors.title ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={!!errors.title}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.title && <FormHelperText style={{ color: "red" }}>{errors.title}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="desc"
          label="Digite uma descrição para a Listagem"
          sx={{ background: errors.desc ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.desc || ""}
          onChange={(e) => handleInputChange("desc", e.target.value)}
          error={!!errors.desc}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.desc && <FormHelperText style={{ color: "red" }}>{errors.desc}</FormHelperText>}
      </FormControl>
      <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
        <Grid item xs={12}>
          {errorFile && (
            <Dropzone onDrop={handleFileChange}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginTop: '10px',
                    height: '170px',
                    background: ThemeColors.red01,
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                >
                  <input {...getInputProps()} />
                  <Box
                    display={'flex'}
                    sx={{
                      flexDirection: 'column',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: ThemeColors.red01,
                      width: '100%',
                      height: '100%',
                      borderRadius: '12px',
                    }}
                  >
                    <CloudUploadIcon style={{ fontSize: '64px', color: ThemeColors.red03 }} />
                    <Titles children={'Adicione apenas arquivos Excel (.xls, .xlsx)'} color={ThemeColors.red03} type={'P1'} />
                  </Box>
                </div>
              )}
            </Dropzone>
          )}

          {selectedFiles.length > 0 && !errorFile && isPreviewVisible && !loading && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              height={'100%'}
              width={'100%'}
              sx={{
                textAlign: 'center',
                marginTop: '10px',
                height: '170px',
                background: backgroundColor,
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
                overflow={'auto'}
                padding={'15px'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'10px'}
                  overflow={'auto'}
                  padding={'15px'}
                >

                  {selectedFiles.map((file, index) => (
                    <FileCard key={index} file={file} handleClick={deleteFile} />
                  ))}

                </Box>

              </Box>

            </Box>
          )}

          {!selectedFiles.length && !errorFile && (
            <Dropzone onDrop={handleFileChange}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    marginTop: '10px',
                    height: '170px',
                    background: '#F9F9F9',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                >
                  <input {...getInputProps()} />
                  <Box
                    display={'flex'}
                    sx={{
                      flexDirection: 'column',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: errors.file ? '#F8D6D3' : '#F9F9F9',
                      width: '100%',
                      height: '100%',
                      borderRadius: '12px',
                    }}
                  >
                    <CloudUploadIcon style={{ fontSize: '64px', color: errors.file ? '#4E1B1D' : '#848484' }} />
                    <p style={{ color: errors.file ? '#4E1B1D' : '#848484' }}>Clique para adicionar arquivo</p>
                  </Box>
                </div>
              )}
            </Dropzone>
          )}
        </Grid>
      </Grid>

      < Box display="flex" justifyContent="center" alignItems="center" >

        <Button
          variant="contained"
          onClick={() => {
            handleVerifyAPI();
          }}
          sx={{ padding: '15px', marginRight: '10px' }}
          fullWidth
          disabled={loadingVerify}
        >
          {loadingVerify ? "Consultando API" : "Carregar dados"}
        </Button>
      </Box>
    </Grid>
  );
}

function DataDisplayTable({ data, setData, variablesOfTemplate, page, setPage, copyRespostas, handleSubmit, loadingAdd, setLoadingAdd }) {

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = useState({});

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const newInputValues = {};

    currentItems.forEach((item, i) => {
      const absoluteIndex = startIndex + i;
      newInputValues[absoluteIndex] = item.variable || '';
    })
    setValues(newInputValues);
  }, [data, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeSelect = (value, index) => {

    setValues({
      ...values,
      [index]: value === "Selecione" ? null : value
    });

    setData(prevData =>
      prevData.map((dataItem, dataIndex) =>
        dataIndex === index ? { ...dataItem, variable: value === "Selecione" ? null : value } : dataItem
      )
    );
  };

  const verifyIfVariableIsSelected = (variable) => {

    if (variable === 'WhatsApp' || variable === 'Email') {
      return false;
    }
    return data.some(item => item.variable === variable);
  }


  const renderSelects = () => {

    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);

    return currentItems.map((item, index) => {
      const absoluteIndex = startIndex + index;
      return (
        <TableRow key={absoluteIndex}>
          <TableCell>{item.field}</TableCell>
          <TableCell>
            <Select
              labelId={"type_send_id" + index}
              id={"type_send" + index}
              value={values[absoluteIndex] ? values[absoluteIndex] : "Selecione"}
              onChange={(e) => {
                handleChangeSelect(e.target.value, absoluteIndex)
              }} // Passa o index para a função
              fullWidth
              style={{ boxShadow: 'none' }} // Remove o sombreamento do Select
              MenuProps={{
                PaperProps: {
                  style: { boxShadow: 'none', border: '1px solid #d3d3d3' }
                },
              }}
              size="small"
              sx={{ background: values[absoluteIndex] === "Selecione" || !values[absoluteIndex] ? '#f9f9f9' : '#f5f3ff', marginTop: '5px', borderRadius: '12px' }}
            >
              <MenuItem value="Selecione">Selecione</MenuItem>
              {Object.values(variablesOfTemplate).flat().map((value) => (
                <MenuItem key={value} value={value} disabled={verifyIfVariableIsSelected(value)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
      <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CAMPO</TableCell>
              <TableCell>REPRESENTA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSelects()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" fullWidth sx={{ padding: '15px', marginTop: '10px', fontSize: '16px' }} onClick={handleSubmit} disabled={loadingAdd}>
        Enviar
      </Button>
    </Grid>
  );
}

function PageAddExcel() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const [copyRespostas, setCopyRespostas] = useState({});
  const [errors, setErrors] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [verifyEmptyVariables, setVerifyEmptyVariables] = useState(false);
  const [fieldChannelSelected, setFieldChannelSelected] = useState(null);
  const [countEmptyVariables, setCountEmptyVariables] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [returnApi, setReturnApi] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const [loadingAdd, setLoadingAdd] = useState(false);
  const [dataR, setDataR] = useState([]);
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [padronizationPage, setPadronizationPage] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const navigate = useNavigate();

  useEffect(() => {

    console.log('copyRespostas', copyRespostas);

  }, [copyRespostas]);

  const steps = [
    { desc: "Escolha um nome para identificar a Listagem" },
    { desc: "Crie uma descrição para a Listagem" },
    { desc: "Carregue o arquivo Excel" },
    { desc: "Clique em 'Carregar dados'" },
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  const handleVerifyAPI = async () => {

    if (!copyRespostas.title || !copyRespostas.desc || !copyRespostas.file ) {

      const newErrors = {
        title: !copyRespostas.title ? "Campo obrigatório" : "",
        desc: !copyRespostas.desc ? "Campo obrigatório" : "",
        file: !copyRespostas.file ? "Campo obrigatório" : ""
      };

      setErrors(newErrors);

      if (newErrors.title || newErrors.desc || newErrors.file) {
        return;
      }
    }

    setLoadingVerify(true);
    setReturnApi({});
    
    try {

        const items = Object.entries(copyRespostas.item_example).map(([field, value]) => ({
          field,
          value
        })).filter((item) => item.field !== "row_number");

        setReturnApi(copyRespostas.item_example);
        setDataR(items);
        setTabIndex(1);
      
    } catch (error) {
      console.error("Erro ao verificar API:", error);
    } finally {
      setLoadingVerify(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoadingAdd(true);
  
      // Criando um novo FormData para envio do arquivo
      const formData = new FormData();
  
      console.log("Iniciando envio...");  // Log básico
      
      // Verificando se o arquivo existe em copyRespostas
      if (copyRespostas.file && copyRespostas.file.length > 0) {
        const fileToUpload = copyRespostas.file[0];  // O arquivo deve estar em copyRespostas.file[0]
        console.log('Nome do arquivo:', fileToUpload.name);
        console.log('Tipo do arquivo:', fileToUpload.type);
        console.log('Tamanho do arquivo:', fileToUpload.size);
  
        // Adicionando o arquivo ao FormData
        formData.append('file', fileToUpload);

        let variables = dataR.map((item) => {
          return {
            field: item.field,
            variable: item.variable
          }
        });

        formData.append('variables', JSON.stringify(variables));

        for (let key in copyRespostas) {
          if (key !== 'file') {
            formData.append(key, copyRespostas[key]);
          }
        }
  
        for (let pair of formData.entries()) {
          console.log(`${pair[0]}:`, pair[1]);
        }
      } else {
        console.error("Nenhum arquivo encontrado em copyRespostas.file.");
        throw new Error('Arquivo não selecionado ou array vazio');
      }
  
      const response = await ExcelInsert(formData);
  
      if (response.message === "success") {
        notify("Success", "Arquivo enviado com sucesso!", true);
        navigate('/listagens');
      } else {
        notify("Error", "Falha ao enviar o arquivo", false);
      }
  
    } catch (error) {
      console.error('Erro ao adicionar API:', error);
      notify("Error", error.message, false);
    } finally {
      setLoadingAdd(false);
    }
  };

  const preSubmit = () => {

    const newErrors = {};
    if (!copyRespostas.title) newErrors.title = "Campo obrigatório";
    if (!copyRespostas.desc) newErrors.desc = "Campo obrigatório";
    if (!copyRespostas.url && copyRespostas.formatSheets === "Google planilhas") newErrors.url = "Campo obrigatório";
    if (!copyRespostas.formatSheets) newErrors.formatSheets = "Campo obrigatório";
    if (!copyRespostas.deleteDuplicatedVariables) newErrors.deleteDuplicatedVariables = "Campo obrigatório";

    setErrors(newErrors);

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    const hasEmptyVariable = variables.some((item) => !item.variable);
    const countEmptyVariable = variables.filter((item) => !item.variable).length;

    setVerifyEmptyVariables(hasEmptyVariable);
    setCountEmptyVariables(countEmptyVariable);

    const variablesWhatsApp = variables.filter((item) => item.variable === 'WhatsApp');
    const variablesEmail = variables.filter((item) => item.variable === 'Email');

    if (variablesWhatsApp.length > 0 || variablesEmail.length > 0) {
      setFieldChannelSelected(true);
    } else {
      setFieldChannelSelected(false);
      setOpenModalConfirm(true);
      return false;
    }

    setOpenModalConfirm(true);

  }

  const fetchData = async () => {
    try {

      let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
      info = JSON.stringify(info);

      const translations = {
        "Personal_information": "Informações Pessoais",
        "Contact": "Contato",
        "Address": "Endereço",
        "Financial_References": "Referências Financeiras"
      };

      const resultJson = await GetVariables();

      const result = resultJson.variables.reduce((acc, item) => {
        const category = translations[item.category] || item.category;
        if (!acc[category]) {
          acc[category] = [];
        }

        acc[category].push(item.variableName);

        return acc;

      }, {});

      setVariablesOfTemplate(result);

    } catch (error) {
      console.error('Error fetching data from API:', error);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(variablesOfTemplate).length !== 0 && tabIndex === 1) {
      setDataR((prev) =>
        prev.map((item) => {
          const normalize = (str) => str?.trim().toLowerCase();
          const findVariable = Object.values(variablesOfTemplate).flat().find((variable) => normalize(variable) === normalize(item.field));

          if (findVariable) {
            return {
              ...item,
              variable: findVariable
            }
          } else {
            return {
              ...item
            }
          }
        })
      );

    }
  }, [variablesOfTemplate, tabIndex])

  const handleModalConfirm = () => {
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }


  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage title="Adicionar APIs" />


      <Grid container spacing={4} sx={{ padding: { xs: 2.5, sm: 5 } }}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
          <HelpAdd Steps={steps} Title="Adicione sua Listagem" />
          {
            (copyRespostas.title === "" || copyRespostas.desc === "" || copyRespostas.url === "" || copyRespostas.authorization === "") && (
              <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
            )
          }
          {
            !fieldChannelSelected && dataR.length > 0 && (
              <AlertSnackbar message="WhatsApp ou Email não selecionado" severity="error" open={true} />
            )
          }
          {
            verifyEmptyVariables && (
              <AlertSnackbar message={`Existem ${countEmptyVariables} campos sem seleção`} severity="warning" open={true} />
            )
          }
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
            <Tab label="Dados da API" />
            <Tab label="Padronização" disabled={!returnApi || !returnApi.message || returnApi.message === "success"} />
          </Tabs>
          <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
            <ApiForm
              copyRespostas={copyRespostas}
              setCopyRespostas={setCopyRespostas}
              setErrors={setErrors}
              errors={errors}
              uuid_client={userInfo.UUID}
              handleVerifyAPI={handleVerifyAPI}
              loadingVerify={loadingVerify}
              setReturnApi={setReturnApi}
              setTabIndex={setTabIndex}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1} style={{ background: "#FFF", borderRadius: '12px', padding: '20px', minHeight: '60vh' }}>
            <DataDisplayTable loadingAdd={loadingAdd} setLoadingAdd={setLoadingAdd} data={dataR} handleSubmit={preSubmit} setData={setDataR} variablesOfTemplate={variablesOfTemplate} page={padronizationPage} setPage={setPadronizationPage} copyRespostas={copyRespostas} />
          </TabPanel>
        </Grid>
      </Grid>
      <ModalR
        cancelText={!fieldChannelSelected ? "Voltar" : "Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={!fieldChannelSelected ? "" : "Sim, tenho certeza"}
        subTitle={!fieldChannelSelected ? "Selecione a coluna onde esteja os WhatsApps ou Emails em sua listagem"
          : "Você deseja finalizar a importação da listagem?"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
        title={!fieldChannelSelected ? "Alguns campos são obrigatórios"
          : verifyEmptyVariables ? `Existem ${countEmptyVariables} campos sem seleção` : "Finalizar importação"}
        emoji={verifyEmptyVariables ? "emoji04" : "emoji01"}
        iconColor={ThemeColors.purple400}
      />
    </>
  );
}

export default PageAddExcel;
