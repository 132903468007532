// Description: A Context to handle the state management of the project
// Updated: 06/03/2025
// Author: Simon Franklin

// React
import React, { createContext, useContext, useState, useEffect } from 'react';

// Functions and assets for the project
import { checkAuth } from '../utils/utils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // State Variables
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [userAvatar, setUserAvatar] = useState(null);

    useEffect(() => {
        setUserAuthenticated(checkAuth());
    }, []);

    const value = {
        userAuthenticated,
        setUserAuthenticated,
        loading,
        setLoading,
        userInfo,
        setUserInfo,
        userAvatar,
        setUserAvatar
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
