import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ThemeColors from '../ThemeColors/ThemeColors';

const formatSegment = (segment) => {
    return decodeURIComponent(segment)
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

function HeaderPage() {

    const location = useLocation();
    const path = location.pathname;
    const pathSegments = path.split('/').filter(x => x);
    const boxRef = useRef(null); 

    const createPathToSegment = (index) => {
        const path = pathSegments.slice(0, index + 1).join('/');
        return '/' + path.split('/').map(decodeURIComponent).join('/');
    };

  
    return (
        <Box
            ref={boxRef}
            sx={{
                width: '100%',
                backgroundColor: { xs: 'transparent', sm: 'white' , md: 'white' , lg: 'white' },
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: { xs: '20px', sm: '25px 60px 25px 60px' },
                top: 0,
                borderBottom: { xs: '1px solid #D5D5D5', sm: 'none' },
                height: { xs: '75px', sm: '100px' },
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextOutlinedIcon style={{ fontSize: '35px', color: ThemeColors.purple600 }} />}
            >
                {pathSegments.map((segment, index) => (
                    <Link
                        key={index}
                        component={RouterLink}
                        to={createPathToSegment(index)}
                        sx={{
                            color: index === pathSegments.length - 1 ? ThemeColors.purple600 : ThemeColors.gray30,
                            textDecoration: 'none',
                            fontSize: { xs: '16px', sm: '20px' },
                            fontFamily: 'Satoshi-Medium',
                        }}
                    >
                        {formatSegment(segment)}
                    </Link>
                ))}
            </Breadcrumbs>
        </Box>
    );
}

export default HeaderPage;
