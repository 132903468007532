// Description: function to change the profile Image of the user
// Updated:

export const ChangeProfileImage = async (newImage) => {
    try {
        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const res = await fetch(`${process.env.REACT_APP_BACKEND_AWS_URL}/profile/avatar`, {
            body: JSON.stringify({ base64_image: newImage }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'Client_ID': `${clientId}`
            },
            method: 'PUT'
        });

        const data = await res.json();

        return data;
    } catch (error) {
        return error.message;
    }
}