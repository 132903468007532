import axios from 'axios';

export async function ProfileGet() {
    try {
        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/profile/info`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                }
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}