import { Box, Typography } from '@mui/material';
import React from 'react';

const SubPage = ({ page }) => {
  return (
    <Box
      sx={{
        padding: { xs: '20px', sm: '49px 94px' },
        display: 'flex',
        flexDirection: 'column',
        gap: '45px',
        width: '100%',
        flex: '1',
        maxHeight: { xs: 'calc(100vh - 197.8px)', sm: 'auto' },
        overflowY: { xs: 'auto', sm: 'unset' },
        backgroundColor: { xs: '#fff', sm: 'unset' }
      }}
    >
      {page}
    </Box>
  );
}

export default SubPage;
