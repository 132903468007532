// Desc: Page to choice the list to add
// Update: 22/10/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from '@mui/material/Box';

import CardList from '../../components/CardList/CardList';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import Titles from '../../components/Titles/Titles';

import { verifyPlan } from '../../api/backend/Plans/verifyPlan';

function AddSheetsOptions() {

    const [planoAtual, setPlanoAtual] = useState("");
    const [loading, setLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    let navigate = useNavigate();

    const mapPlanFunctionsAllowed = {
        'FreeZap': ['WhatsApp Web', 'Email', 'WhatsApp API', 'Google Planilhas', 'API','Excel'],
        'StartZap': ['WhatsApp Web', 'Email', 'WhatsApp API', 'Google Planilhas', 'API','Excel'],
        'ProZap': ['WhatsApp Web', 'Email', 'WhatsApp API', 'Google Planilhas', 'API','Excel'],
        'UltraZap': ['WhatsApp Web', 'Email', 'WhatsApp API', 'Google Planilhas', 'API','Excel'],
    };

    const HandleRedirectAddGoogle = () => {
        navigate('/listagens/adicionar/google');
    }

    const HandleRedirectAddAPI = () => {
        navigate('/listagens/adicionar/api');
    }

    const HandleRedirectAddExcel = () => {
        navigate('/listagens/adicionar/excel');
    }

    const verifica_plano = () => {
        setLoading(true);
        const plano = verifyPlan().then((response) => {
            setPlanoAtual(response.plan_name);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setShowLoading(false);
        })
    }

    useEffect(() => {
        verifica_plano();
    }, []);

    const canAddList = (type) => {

        if (!mapPlanFunctionsAllowed[planoAtual]) {
            return false;
        }
        return mapPlanFunctionsAllowed[planoAtual].includes(type);
    }

    const ListData = [
        {
            title: "Google Planilhas",
            subtitle: "Importe e gerencie listagens diretamente do Google Planilhas.",
            text: "Facilite a organização e o envio de suas listagens utilizando a integração com o Google Planilhas. Ideal para quem busca uma solução simples e prática para gerenciar contatos, leads ou qualquer outro tipo de dado de forma automatizada.",
            icon: "Google",
            corFundo: "#DDD4FF",
            titleColor: "#8033F4",
            onClick: HandleRedirectAddGoogle,
            pago: canAddList('Google Planilhas')
        },
        {
            title: "Requisição Web (API)",
            subtitle: "Automatize a adição de listagens via API.",
            text: "A integração via API permite adicionar e atualizar listagens de forma dinâmica e escalável. Ideal para empresas que precisam sincronizar dados em tempo real, garantindo maior flexibilidade e automação no gerenciamento das listagens.",
            icon: "API",
            corFundo: "#DDD4FF",
            titleColor: "#8033F4",
            onClick: HandleRedirectAddAPI,
            pago: canAddList('API')
        },
        {
            title: "Arquivo Excel",
            subtitle: "Importe suas listagens através de um arquivo Excel.",
            text: "Facilite a organização e o envio de suas listagens utilizando a integração com o Google Planilhas. Ideal para quem busca uma solução simples e prática para gerenciar contatos, leads ou qualquer outro tipo de dado de forma automatizada.",
            icon: "Newspaper",
            corFundo: "#DDD4FF",
            titleColor: "#8033F4",
            onClick: HandleRedirectAddExcel,
            pago: canAddList('Excel')
        }
    ];

    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>
            <HeaderPage title="Add WhatsApp Instance" />
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: { sm: 'calc(100vh - 100px)' },
                    paddingBottom: 10,
                }}
            >
                <Box sx={{ padding: { xs: '20px 20px 0 20px', sm: '40px 40px 0 40px' } }}>
                    <Titles styles={{ fontSize: { xs: "20px", sm: "24px" } }} children={"Qual tipo deseja Adicionar?"} color={"#848484"} />
                    <CardList data={ListData} />
                </Box>
            </Box>

        </>
    );
}

export default AddSheetsOptions;