import { Box } from '@mui/material'
import React from 'react'
import DynamicInput from '../Input/DynamicInput'

const DynamicFields = ({ fields, updateFieldValue, formState }) => {
  return (
    <Box sx={{ display: 'flex', gap: '18px', flexWrap: 'wrap', width: '100%' }}>
        {fields.map((field, index) => (
            <DynamicInput key={index} fullWidth={field.fullWidth} title={field.fieldName} type={field.type} updateFieldValue={updateFieldValue} value={formState[field.fieldName]} options={field?.options} formState={formState} />
        ))}
    </Box>
  )
}

export default DynamicFields