// Description: Page to add an email channel
// Updated: 25/03/2025
// Author: Simon Franklin

// React
import React, { useEffect, useState } from 'react';

// Material UI Components
import { Box, Button, Divider, Typography, CircularProgress } from '@mui/material';

// Components and functions for the project
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import StepIndicator from '../../components/DynamicForm/StepIndicator';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import { useNavigate } from 'react-router-dom';
import { ChannelAddEmail } from '../../api/backend/Channels/ChannelAddEmail';
import { notify } from '../../utils/utils';

const FormContent = React.memo(({ currentStep, steps, name, email, setName, setEmail, firstTime, success, setCurrentStep }) => {
    const navigate = useNavigate();

    if (currentStep === 1) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray", margin: "30px 0" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                    {steps[currentStep - 1].title}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "16px" }, color: "#848484", marginBottom: "19px", textAlign: "center", maxWidth: { xs: "100%", sm: "80%" } }}>
                    {steps[currentStep - 1].description}
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px', width: '100%', marginTop: "20px" }}>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "rgb(82, 82, 91)", marginBottom: "8px" }}>
                            Escolha um nome para identificação
                        </Typography>
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            placeholder="Digite seu nome"
                            style={{
                                borderRadius: "12px",
                                borderColor: "gray",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "6px",
                                border: "none",
                                outline: "none",
                                padding: "14px",
                                width: "100%"
                            }}
                            key="name-field"
                        />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "rgb(82, 82, 91)", marginBottom: "8px" }}>
                            Informe o endereço de E-mail
                        </Typography>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            placeholder="Digite seu e-mail"
                            style={{
                                borderRadius: "12px",
                                borderColor: "gray",
                                backgroundColor: "#F9F9F9",
                                borderRadius: "6px",
                                border: "none",
                                outline: "none",
                                padding: "14px",
                                width: "100%"
                            }}
                            key="email-field"
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
    else if (currentStep === 2 && firstTime > 0 || currentStep === 3 && firstTime > 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "20px", sm: "30px" },
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                    Aguarde um instante! 😉
                </Typography>

                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        color: "#848484",
                        marginBottom: "19px",
                        textAlign: "center",
                        maxWidth: { xs: "100%", sm: "80%" },
                    }}
                >
                    Estamos processando os dados para a criação do canal de E-mail
                </Typography>

                <Box
                    sx={{
                        border: "2px solid #F5F3FF",
                        borderRadius: "50%", // Garante que seja um círculo
                        height: "200px", // Define o tamanho fixo para o círculo
                        width: "200px", // Define a mesma largura para o círculo
                        display: "flex",
                        flexDirection: "column", // Organiza os itens em coluna
                        justifyContent: "center", // Centraliza verticalmente
                        alignItems: "center", // Centraliza horizontalmente
                        textAlign: "center", // Alinhamento do texto centralizado
                        position: "relative"
                    }}
                >
                    <CircularProgress
                        sx={{
                            position: "absolute", // Faz com que o progresso circular fique em cima do Box
                            color: "#F5F3FF", // Cor do progresso circular
                        }}
                        size={200} // Tamanho do círculo
                        thickness={1} // Espessura do progresso
                    />
                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: "30px",
                            fontFamily: '"Open Sans", sans-serif',
                            color: "#4F189A", // Coloca o texto acima do CircularProgress
                            zIndex: 1, // Garante que o texto fique sobre o progresso circular
                        }}
                    >
                        {firstTime}
                    </p>
                </Box>


            </Box>
        );
    }
    else if (currentStep === 3 && firstTime === 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "20px", sm: "30px" },
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                    {steps[currentStep - 1].title}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "16px" }, color: "#848484", marginBottom: "19px", textAlign: "center", maxWidth: { xs: "100%", sm: "80%" } }}>
                    {steps[currentStep - 1].description}
                </Typography>
            </Box>
        );
    }
    else if (currentStep === 4) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "20px", sm: "30px" },
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: "15px" }}>
                    {steps[currentStep - 1].title}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                </Box>
                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        color: "#848484",
                        marginBottom: "19px",
                        textAlign: "center",
                        maxWidth: { xs: "100%", sm: "80%" },
                    }}
                >
                    {steps[currentStep - 1].description}
                </Typography>

                {success ? (
                    <Button
                        variant="contained"
                        sx={{
                            padding: { xs: "16px 20px", sm: "10px 25px" },
                            width: "100%", // Faz o botão ocupar toda a largura
                            maxWidth: "400px", // Define uma largura máxima opcional
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            fontSize: "15px",
                        }}
                        onClick={() => navigate("/canais")}
                    >
                        Verificar meus canais
                    </Button>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: { xs: "10px", sm: "10px" },
                            marginTop: { xs: "30px", sm: "20px" },
                            flexDirection: {
                                xs: "column", // Empilha os botões em telas menores
                                sm: "row", // Alinha os botões na horizontal em telas maiores
                            },
                            width: "100%", // Faz os botões ocuparem toda a largura
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "16px 20px", sm: "10px 25px" },
                                width: "100%", // Faz o botão ocupar toda a largura
                                maxWidth: "400px", // Define uma largura máxima opcional
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                fontSize: "15px",
                            }}
                        >
                            Falar com o suporte
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "16px 20px", sm: "10px 25px" },
                                width: "100%", // Faz o botão ocupar toda a largura
                                maxWidth: "400px", // Define uma largura máxima opcional
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                fontSize: "15px",
                                backgroundColor: "#300D68",
                            }}
                            onClick={() => setCurrentStep(3)}
                        >
                            Tentar novamente
                        </Button>
                    </Box>
                )}
            </Box>
        );
    }

    return null;

});

const ChannelPageAddEmail = () => {
    const [firstTime, setFirstTime] = useState(10);
    const [currentStep, setCurrentStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [verify, setVerify] = useState(false);
    const [instance, setInstance] = useState("");

    const steps = [
        {
            stepNumber: 1,
            title: "Vamos integrar seu E-mail?",
            description: (
                <span>
                    Informe um nome para <strong>identificar</strong> o email dentro <br /> da plataforma, e o endereço de <strong>email</strong> que deseja integrar. <br /> Você receberá um email de confirmação para validar a integração.
                </span>

            )
        },
        {
            stepNumber: 2,
            title: "",
            description: (
                <span>
                </span>
            ),
        },
        {
            stepNumber: 3,
            title: "Hora de verificar seu email",
            description: (
                <span>

                    Agora que o email foi adicionado, vá na sua caixa de entrada e clique no link da AWS que foi enviado para você.
                </span>
            ),
        },
        {
            stepNumber: 4,
            title: success ? "Email adicionado com sucesso!" : "Ops! Algo deu errado",
            description: success ? (
                <span>
                    Agora você pode começar a enviar Email para seus clientes. Clique no botão abaixo para verificar seus canais.
                </span>
            ) : (
                <span>
                    Parece que houve um problema ao adicionar o seu Email. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter ajuda.
                </span>
            ),
        }
    ];

    const canMoveForward = () => {
        if (currentStep === 1 && name !== "" && email !== "") {
            return true;
        }
        else if (currentStep === 3 && firstTime === 0) {
            return true;
        }

        notify("Error", "Preencha todos os campos", true);
        return false;
    }


    const handleAdvance = async () => {
        if (currentStep === 1) {
            setCurrentStep(2);

            await ChannelAddEmail({ email, name }).then((response) => {
                setInstance(response.instance)
            })
        }
        else if (currentStep === 3) {
            setFirstTime(30);

            const token = localStorage.getItem('jwt_token');
            const clientId = localStorage.getItem('idToken');

            await fetch(`${process.env.REACT_APP_BACKEND_AWS_URL}/channels/email/verify-identity`, {
                method: "POST",
                body: JSON.stringify({ code: instance }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response);
                    if (response.info === "Email already verified") {
                        setSuccess(true);
                    } else {
                        setSuccess(false);
                    }

                    setVerify(true);
                });
        }
    }

    useEffect(() => {

        if (firstTime > 0 && currentStep === 2 || firstTime > 0 && currentStep === 3) {

            const timer = setInterval(() => {
                setFirstTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
        if (firstTime === 0 && currentStep === 2) {
            setCurrentStep(3);
        }
        if (firstTime === 0 && currentStep === 3 && success === true && verify === true) {
            setCurrentStep(4);
            notify("Success", "Seu canal de email foi adicionado com sucesso", true);
        }
        if (firstTime === 0 && currentStep === 3 && success === false && verify === true) {
            notify("Alert", "Seu email ainda não foi verificado", true);
        }

    }, [firstTime, currentStep]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <HeaderPage />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: 'rgb(128,51,244)',
                    background: 'radial-gradient(circle, rgba(124,41,249,1) 54%, rgba(128,51,244,1) 100%)',
                    opacity: 0.8,
                    flex: 1,
                    gap: "15px",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                    }}
                >
                    <Box
                        id="form"
                        sx={{
                            background: "#FFF",
                            height: {
                                xs: "100vh", // Ocupa a altura inteira em telas pequenas
                                sm: "100%", // Altura personalizada em telas maiores
                                md: "600px",
                                lg: "600px",
                                xl: "600px",
                            },
                            minWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            maxWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            padding: "30px",
                            borderRadius: {
                                xs: "0", // Sem borda arredondada em telas pequenas
                                sm: "8px", // Bordas arredondadas em telas médias ou maiores
                            },
                            boxShadow: {
                                xs: "none", // Sem sombra em telas pequenas
                                sm: "0 4px 10px rgba(0, 0, 0, 0.2)", // Sombra em telas maiores
                            },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: { xs: "flex-start", sm: "flex-start", md: "space-between", lg: "space-between" }
                        }}
                    >
                        <FormContent
                            currentStep={currentStep}
                            steps={steps}
                            name={name}
                            setName={setName}
                            email={email}
                            setEmail={setEmail}
                            firstTime={firstTime}
                            success={success}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: { xs: "0px", sm: "10px" },
                                marginTop: { xs: "30px", sm: "20px" },
                                flexDirection: {
                                    xs: "column", // Empilha os botões em telas menores
                                    sm: "row", // Alinha os botões na horizontal em telas maiores
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    minWidth: {
                                        xs: '100%',
                                        sm: "154px",
                                    },
                                    display: currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 ? "none" : "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: { xs: "16px", sm: "16px" },
                                    marginRight: "auto",
                                }}
                                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    minWidth: {
                                        xs: '100%',
                                        sm: "154px", // Largura mínima em telas maiores
                                    },
                                    display: currentStep === 2 || currentStep === 3 && firstTime > 0 || currentStep === 4 ? "none" : "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: { xs: "16px", sm: "16px" },
                                    marginLeft: "auto",
                                    marginTop: { xs: "20px", sm: "0" },
                                }}
                                onClick={() => canMoveForward() ? handleAdvance() : null}
                            >
                                Próximo
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ChannelPageAddEmail