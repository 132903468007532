// Description: Component that return a icon
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PaletteIcon from '@mui/icons-material/Palette';
import TableRowsIcon from '@mui/icons-material/TableRows';
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ErrorIcon from '@mui/icons-material/Error';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ElectricalServicesRoundedIcon from '@mui/icons-material/ElectricalServicesRounded';
import PowerRoundedIcon from '@mui/icons-material/PowerRounded';
import PowerOffRoundedIcon from '@mui/icons-material/PowerOffRounded';
import Sheets from '../../assets/Icons/components/Sheets';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NewspaperIcon from '@mui/icons-material/Newspaper';

// Components and functions from the project
import Emoji01 from '../../assets/Icons/components/Emoji01';
import Emoji02 from '../../assets/Icons/components/Emoji02';
import Emoji03 from '../../assets/Icons/components/Emoji03';
import Emoji04 from '../../assets/Icons/components/Emoji04';
import Emoji05 from '../../assets/Icons/components/Emoji05';
import Emoji06 from '../../assets/Icons/components/Emoji06';
import Emoji07 from '../../assets/Icons/components/Emoji07';
import Emoji08 from '../../assets/Icons/components/Emoji08';
import Emoji09 from '../../assets/Icons/components/Emoji09';
import Emoji10 from '../../assets/Icons/components/Emoji10';
import Emoji11 from '../../assets/Icons/components/Emoji11';
import Emoji12 from '../../assets/Icons/components/Emoji12';
import Emoji13 from '../../assets/Icons/components/Emoji13';
import Emoji14 from '../../assets/Icons/components/Emoji14';
import Emoji15 from '../../assets/Icons/components/Emoji15';
import Emoji16 from '../../assets/Icons/components/Emoji16';
import Emoji17 from '../../assets/Icons/components/Emoji17';
import Emoji18 from '../../assets/Icons/components/Emoji18';
import Emoji19 from '../../assets/Icons/components/Emoji19';
import Emoji20 from '../../assets/Icons/components/Emoji20';
import Emoji21 from '../../assets/Icons/components/Emoji21';
import Emoji22 from '../../assets/Icons/components/Emoji22';
import Emoji23 from '../../assets/Icons/components/Emoji23';
import Emoji24 from '../../assets/Icons/components/Emoji24';
import Emoji25 from '../../assets/Icons/components/Emoji25';
import Emoji26 from '../../assets/Icons/components/Emoji26';
import Emoji27 from '../../assets/Icons/components/Emoji27';
import Emoji28 from '../../assets/Icons/components/Emoji28';
import Emoji29 from '../../assets/Icons/components/Emoji29';
import Emoji30 from '../../assets/Icons/components/Emoji30';
import Marketing from '../../assets/Icons/components/Marketing';
import Services from '../../assets/Icons/components/Services';
import Financeiro from '../../assets/Icons/components/Financeiro';


import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import BadgeIcon from '@mui/icons-material/Badge';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WebhookIcon from '@mui/icons-material/Webhook';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PixIcon from '@mui/icons-material/Pix';
import AddCardIcon from '@mui/icons-material/AddCard';
import PlusIcon from '../../assets/Icons/components/Plus';
import MinusIcon from '../../assets/Icons/components/Minus';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EditIcon from '@mui/icons-material/Edit';
import GoogleIcon from '@mui/icons-material/Google';
const StatusIcon = ({ status, width, height, color }) => {
    // Function that customizes the icon based on props data
    switch (status) {

        case 'Google':
            return <GoogleIcon style={{ color: color, width: width, height: height }} />;
        case 'AddCard':
            return <AddCardIcon style={{ color: color, width: width, height: height }} />;
        case 'Pix':
            return <PixIcon style={{ color: color, width: width, height: height }} />;
        case 'Cobrança':
            return <PaidOutlinedIcon style={{ color: color, width: width, height: height }} />;
        case 'ArrowBottom':
            return <KeyboardArrowDownIcon style={{ color: color, width: width, height: height }} />;
        case 'Close':
            return <CloseIcon style={{ color: color, width: width, height: height }} />;
        case 'Star':
            return <StarIcon style={{ color: color, fontSize: width }} />;
        case 'WhatsApp':
            return <WhatsAppIcon style={{ color: color, fontSize: width }} />;
        case "codeChat":
            return <WhatsAppIcon style={{ color: color, fontSize: width }} />;
        case "whatsAppOfficial":
            return <VerifiedIcon style={{ color: color, fontSize: width }} />;
        case 'WhatsApp Business':
            return <VerifiedIcon style={{ color: color, fontSize: width }} />;
        case 'SMS':
            return <SmsRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'Email':
            return <EmailRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'E-mail':
            return <EmailRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'ArrowBottom':
            return <ArrowDropDownIcon style={{ color: color, fontSize: width }} />;
        case 'ArrowTop':
            return <ArrowDropUpIcon style={{ color: color, fontSize: width }} />;
        case 'Delete':
            return <DeleteIcon style={{ color: color, fontSize: width }} />;
        case 'Edit':
            return <ModeIcon style={{ color: color, fontSize: width }} />;
        case 'Duplicate':
            return <CopyAllOutlinedIcon style={{ color: color, fontSize: width }} />;
        case 'Help':
            return <HelpIcon style={{ color: color, fontSize: width }} />;
        case 'Plus':
            return <AddIcon style={{ color: color, fontSize: width }} />;
        case 'Search':
            return <SearchOutlinedIcon style={{ color: color, fontSize: width }} />;
        case 'Palette':
            return <PaletteIcon style={{ color: color, fontSize: width }} />;
        case 'Table':
            return <TableRowsIcon style={{ color: color, fontSize: width }} />;
        case 'Label':
            return <SmartButtonOutlinedIcon style={{ color: color, fontSize: width }} />;
        case 'Button':
            return <IndeterminateCheckBoxOutlinedIcon style={{ color: color, fontSize: width }} />;
        case 'Emoji':
            return <EmojiEmotionsIcon style={{ color: color, fontSize: width }} />;
        case 'Letter':
            return <FormatColorTextIcon style={{ color: color, fontSize: width }} />;
        case 'Campanha':
            return <CampaignRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'Suporte':
            return <ContactSupportIcon style={{ color: color, fontSize: width }} />;
        case 'Sheets':
            return <Sheets style={{ color: color, fontSize: width }} />;
        case 'Alert':
            return <ErrorIcon style={{ color: color, fontSize: width }} />;
        case 'Electrical':
            return <ElectricalServicesRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'Power':
            return <PowerRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'Plus':
            return <PlusIcon width={width} height={height} color={color} />;
        case 'Minus':
            return <MinusIcon width={width} height={height} color={color} />;
        case 'PowerOff':
            return <PowerOffRoundedIcon style={{ color: color, fontSize: width }} />;
        case 'FastForward':
            return <FastForwardIcon style={{ color: color, fontSize: width }} />;
        case 'Check':
            return <ChecklistIcon style={{ color: color, fontSize: width }} />;
        case 'Pen':
            return <EditIcon style={{ color: color, fontSize: width }} />;
        case 'emoji01':
            return <Emoji01 width={width} height={height} backgroundColor={color} />;
        case 'emoji02':
            return <Emoji02 width={width} height={height} fill={color} />;
        case 'emoji03':
            return <Emoji03 width={width} height={height} />;
        case 'emoji04':
            return <Emoji04 width={width} height={height} />;
        case 'emoji05':
            return <Emoji05 width={width} height={height} />;
        case 'emoji06':
            return <Emoji06 width={width} height={height} />;
        case 'emoji07':
            return <Emoji07 width={width} height={height} />;
        case 'emoji08':
            return <Emoji08 width={width} height={height} />;
        case 'emoji09':
            return <Emoji09 width={width} height={height} />;
        case 'emoji10':
            return <Emoji10 width={width} height={height} />;
        case 'emoji11':
            return <Emoji11 width={width} height={height} />;
        case 'emoji12':
            return <Emoji12 width={width} height={height} />;
        case 'emoji13':
            return <Emoji13 width={width} height={height} />;
        case 'emoji14':
            return <Emoji14 width={width} height={height} />;
        case 'emoji15':
            return <Emoji15 width={width} height={height} />;
        case 'emoji16':
            return <Emoji16 width={width} height={height} />;
        case 'emoji17':
            return <Emoji17 width={width} height={height} />;
        case 'emoji18':
            return <Emoji18 width={width} height={height} />;
        case 'emoji19':
            return <Emoji19 width={width} height={height} />;
        case 'emoji20':
            return <Emoji20 width={width} height={height} />;
        case 'emoji21':
            return <Emoji21 width={width} height={height} />;
        case 'emoji22':
            return <Emoji22 width={width} height={height} />;
        case 'emoji23':
            return <Emoji23 width={width} height={height} />;
        case 'emoji24':
            return <Emoji24 width={width} height={height} />;
        case 'emoji25':
            return <Emoji25 width={width} height={height} />;
        case 'emoji26':
            return <Emoji26 width={width} height={height} />;
        case 'emoji27':
            return <Emoji27 width={width} height={height} />;
        case 'emoji28':
            return <Emoji28 width={width} height={height} />;
        case 'emoji29':
            return <Emoji29 width={width} height={height} />;
        case 'emoji30':
            return <Emoji30 width={width} height={height} />;
        case 'Marketing':
            return <Marketing width={width} height={height} />;
        case 'Services':
            return <Services width={width} height={height} />;
        case 'Financeiro':
            return <Financeiro width={width} height={height} />;
        case 'Check':
            return <CheckCircleOutlineIcon style={{ color: color, fontSize: width }} />;
        case 'PERSON':
            return <PersonIcon style={{ color: color, width: width, height: height }} />;
        case 'ORGANIZATION':
            return <BusinessIcon style={{ color: color, width: width, height: height }} />;
        case 'LOCATION':
            return <LocationOnIcon style={{ color: color, width: width, height: height }} />;
        case 'DATE':
            return <EventIcon style={{ color: color, width: width, height: height }} />;
        case 'EVENT':
            return <EventIcon style={{ color: color, width: width, height: height }} />;
        case 'TITLE':
            return <BadgeIcon style={{ color: color, width: width, height: height }} />;
        case 'QUANTITY':
            return <AddIcon style={{ color: color, width: width, height: height }} />;
        case 'OTHER':
            return <MoreHorizIcon style={{ color: color, width: width, height: height }} />;
        case 'WEBHOOK':
            return <WebhookIcon style={{ color: color, width: width, height: height }} />;
        case 'API':
            return <WebhookIcon style={{ color: color, width: width, height: height }} />;
        case 'Google planilhas':
            return <GoogleIcon style={{ color: color, width: width, height: height }} />;
        case 'WhatsApp WEB':
            return <WhatsAppIcon style={{ color: color, width: width, height: height }} />;
        case 'Newspaper':
            return <NewspaperIcon style={{ color: color, width: width, height: height }} />;
        case 'Excel':
            return <NewspaperIcon style={{ color: color, width: width, height: height }} />;
        default:
            return null;
    }
}; export default StatusIcon;