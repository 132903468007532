import axios from 'axios';

export async function CreateTemplate(data) {
    try {

        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/templates/insert`, 
            data,  
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                },
            }
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}
