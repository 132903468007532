// Desc: Page to display the list of spreadsheets

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-UI imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

// Fuctions and Components to project
import DynamicTable from "../../components/Tables/DynamicTable";
import DeleteIcon from '../../assets/Icons/components/Delete';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Titles from "../../components/Titles/Titles";
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import { DeleteListagens } from "../../api/backend/Listagens/DeleteListagens";
import { GetListagens } from "../../api/backend/Listagens/GetListagens";
import { isAuthenticated } from '../../authMiddleware';
import { truncateText } from "../../utils/utils";
import ModalR from "../../components/Modals/ModalR";

function Sheets() {

  // States to contro authetication and user info
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let navigate = useNavigate();

  // States to page control
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState("./listagens.mp4");
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [listagens, setListagens] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
    }
  }, [userInfo])

  const fetchData = async () => {

    try {

      const resultJson = await GetListagens();

      let listagens = resultJson['data'].map((item) => {
        return {
          id: item['code'],
          title: item['title'],
          desc: truncateText(item['description'], 50),
          link: item['url'],
          type: item['type'],
          icon: item['type']
        }
      });

      console.log('listagens', listagens);

      setListagens(listagens);
      setLoading(false);

    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
      setLoading(false);
    }
  }

  const handleDelete = async (item) => {
    try {

      const resultJson = await DeleteListagens(item.id);

      if (resultJson['message'] === 'success') {
        setOpenModalDelete(false);
        fetchData();
      }

    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
    }
  }

  const handlePageAdd = () => {
    navigate("/listagens/adicionar");
  };

  return (
    <Fragment>
      <Box width={"100%"} height={"100vh"}>
        <HeaderPage title="Planilhas" />
        {
          loading === false && listagens.length === 0 && (
            <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
              <Box sx={{ width: { xs: "80%", sm: "auto" } }}>
                <Box component="img" src={EmptyTemplate} alt="Você não tem modelos" sx={{ width: { xs: "247px", sm: "494px" }, height: { xs: "165px", sm: "330px" } }} />
                <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                  <Titles bold type={"H3"} children={"Parece que você não tem planilhas :("} color={"#8033F4"} />
                  <Titles type={"P1"} children={"Importe uma agora mesmo, é fácil e rápido"} />

                  <Button
                    variant='contained'
                    style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                    onClick={() => handlePageAdd()}
                  >
                    Importar Listagem
                  </Button>
                </Box>
              </Box>
            </Box>
          )
          || (
            <DynamicTable
              title="Listagens"
              data={listagens}
              columns={[
                { header: '', field: 'icon', render: (icon) => <StatusIcon status={icon} /> },
                { header: 'Título', field: 'title' },
                { header: 'Descrição', field: 'desc' },
                { header: 'Tipo', field: 'type', render: (type) => <StatusLabel status={type} usingOnTable /> }
              ]}
              actions={[
                {
                  icon: <ViewStreamIcon style={{ color: ThemeColors.gray30, fontSize: '14px' }}
                  />, tooltip: 'Visualizar', action: (item) => window.open(item.link, '_blank'), isVisible: (item) => item && item.type === 'Google planilhas'
                },
                {
                  icon: <DeleteIcon style={{ color: ThemeColors.gray30, fontSize: '14px' }}
                  />, tooltip: 'Excluir', action: (item) => (setOpenModalDelete(true), setSelectedList(item)), isVisible: (item) => item
                }
              ]}
              button={{ icon: <PlusIcon />, tooltip: 'Adicionar Listagem ', action: h => handlePageAdd() }}
              activeSearch={true}
              titleSearch={'Pesquisar Planilha'}
              titleLabel={'Planilhas por página'}
              loading={loading}
            />
          )
        }
      </Box>
      <ModalR
        cancelText={"Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={"Sim, desejo excluir"}
        subTitle={"Esta ação não poderá ser desfeita"}
        isOpen={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onConfirm={selectedList ? () => handleDelete(selectedList) : null}
        title={"Tem certeza que deseja excluir?"}
        emoji={"emoji10"}
        iconColor={ThemeColors.purple400}
      />
    </Fragment>
  );
}

export default Sheets;