// Description: Modal that will be rendered before the user download the reports
// Updated: 21/03/2025
// Author: Simon Franklin

// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Button, Typography } from '@mui/material';

// Components and Functions for the project
import themeColors from '../ThemeColors/ThemeColors';
import DynamicButton from '../DynamicButton/DynamicButton';
import checkIcon from '../../pages/SelfRegister/check-icon.png';
import Titles from '../Titles/Titles';
import { notify } from '../../utils/utils';

// Render the component
const DownloadReportsModal = ({ open, onClose, finishFunction, setName, name, generateList, setGenerateList }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const finish = async () => {
        if (!name.trim()) {
            setErrors(true);
            notify("Error", "Digite o nome do arquivo", true);
            return;
        }
    
        setLoading(true);
    
        try {
            await finishFunction();
                onClose();
        } catch (error) {
            console.error("Ocorreu algum erro ao gerar o relatório", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Box
                component="div"
                onClick={onClose}
                sx={{
                    width: '100vw', height: '100vh',
                    position: 'fixed', top: 0, left: 0,
                    display: open ? 'block' : 'none',
                    opacity: 0.5, zIndex: 5000, backgroundColor: '#000'
                }}
            />
    
            <Box
                sx={{
                    width: '461px', height: '457px',
                    display: open ? 'flex' : 'none',
                    flexDirection: 'column', padding: '40px',
                    borderRadius: '12px', position: 'fixed',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff', zIndex: 5000
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '24px', color: themeColors.purple500, fontWeight: 500, textAlign: 'center', marginBottom: '20px' }}>
                        Vamos gerar seu relatório?
                    </Typography>
                    <Typography sx={{ fontSize: '16px', color: '#52525B', textAlign: 'center' }}>
                        Para <b>gerar o relatório</b>, insira o nome no campo abaixo e clique em "Gerar Relatório".
                    </Typography>
                </Box>
    
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'start' }}>
                    <Typography sx={{ fontSize: '16px', color: '#000', marginTop: '36px', textAlign: 'center' }}>
                        Qual o nome do seu relatório?
                    </Typography>
                    <input
                        style={{
                            backgroundColor: errors ? themeColors.red01 : '#f6f6f6',
                            borderRadius: '6px', padding: '20px 16px',
                            width: '100%', border: 'none', outline: 'none',
                            fontSize: '16px'
                        }}
                        placeholder="Nome do arquivo"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setErrors(false);
                        }}
                    />
                </Box>
    
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: 'auto' }}>
                    <Button
                        variant="outlined"
                        sx={{ height: '48px', width: '100%', borderRadius: '6px' }}
                        onClick={onClose}
                    >
                        { loading ? 'Minimizar' : 'Fechar' }
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={async () => await finish()} // Chama o finish para iniciar o download
                        style={{ height: '48px', width: '100%', borderRadius: '6px' }}
                        variant="contained"
                    >
                        {loading ? 'Gerando...' : 'Gerar seu relatório'}
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default DownloadReportsModal;