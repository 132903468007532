// This endpoint is used to get all requests made by the user. It is used in the MyRequests page.
// This endpoint is used in the MyRequests page.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136773687/RequestRoutes

import axios from 'axios';

export async function RequestByID() {
    try {
        const token = localStorage.getItem('jwt_token');
        const client_id = localStorage.getItem('idToken');

        if (!token) {
            throw new Error('Token de autenticação não encontrado');
        }

        if (!client_id) {
            throw new Error('ID do cliente não encontrado');
        }

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/support/consult`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${client_id}`
                },
            }

        );

        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}