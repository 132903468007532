// This compnent is responsible for rendering the templates page, where the user can see all the templates that he has created, and also can create new ones.
// The user can also edit, delete and duplicate the templates.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/140312579/Como+criar+modelos
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=78-2221&t=GUuEhnacp2KG5Uq6-4

import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddCommentIcon from '@mui/icons-material/AddComment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ForumIcon from '@mui/icons-material/Forum';

import DeleteIcon from '../../assets/Icons/components/Delete';
import DuplicateIcon from '../../assets/Icons/components/Duplicate';
import EditIcon from '../../assets/Icons/components/Edit';
import DynamicTable from '../../components/Tables/DynamicTable';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { isAuthenticated } from '../../authMiddleware';
import { GetAllTemplates } from '../../api/backend/Templates/GetAllTemplates';
import ModalR from '../../components/Modals/ModalR';
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import { TemplateDelete } from '../../api/backend/Templates/TemplateDelete';
import { TemplateDuplicate } from '../../api/backend/Templates/TemplateDuplicate';
import { TemplateHidden } from '../../api/backend/Templates/TemplateHidden';
import Titles from '../../components/Titles/Titles';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { Visibility } from '@mui/icons-material';
import { notify } from '../../utils/utils';

function Templates() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmDuplicate, setOpenModalConfirmDuplicate] = useState(false);
  const [itemsToShow, setItemsToShow] = useState([]);

  const [dataCards, setDataCards] = useState([
    { title: 'Modelos Armazenados', dataKey: 'conectados', icon: ForumIcon, qtd: 0, },
    { title: 'Modelos Ativos', dataKey: 'desconectados', icon: AddCommentIcon, qtd: 0, }
  ]);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
    }
  }, [userInfo])

  const fetchData = async () => {
    try {

      const resultJson = await GetAllTemplates();

      const items = resultJson.templates.map(template => {
        return {
          id: template.id,
          title: template.title,
          integration_icons: template.channel_type,
          status: template.status,
          msg_type: template.msg_type,
          type: template.type,
          background: template.background,
          messageJson: template.message_json,
          Visibility: template.visibility ? 'Visível' : 'Oculto',
        };
      }).filter(item => item !== null);

      const newDataCards = [...dataCards];

      newDataCards[0].qtd = items.length;
      newDataCards[1].qtd = items.filter(item => item.status === 'Ativo').length;

      setDataCards(newDataCards);

      const newItens = [items]

      setItemsToShow(...newItens);

      console.log('itemsToShow', itemsToShow);

    } catch (error) {
      console.error('Erro ao obter modelos:', error);
    } finally {
      setLoading(false);
    }
  };

  const alterVisibility = async (id_template, visibility) => {
    try {
      let data = { 'id_template': id_template, 'visibility': visibility };

      const templateData = await TemplateHidden(data);

      if (templateData.message === 'success') {

        fetchData();
      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);

    }
  }

  const handleConfirmDelete = async () => {
    try {

      const resultJson = await TemplateDelete({ "id_template": selectedItem.id });

      if (resultJson.message === 'success') {
        const newItems = itemsToShow.filter(item => item.id !== selectedItem.id);
        setItemsToShow(newItems);
        notify("Success", "Modelo deletado com sucesso!", true);
      } else {
        alert('Erro ao excluir modelo. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao excluir modelo:', error);
    } finally {
      handleCloseModalConfirmDelete();
    }
  };

  const handleConfirmDuplicate = async () => {
    try {

      const resultJson = await TemplateDuplicate(selectedItem.id);

      if (resultJson.message === 'success') {
        fetchData();
        notify("Success", "Modelo duplicado com sucesso!", true);
      } else {
        alert('Erro ao duplicar modelo. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao duplicar modelo:', error);
    } finally {
      handleCloseModalConfirmDuplicate();
    }
  };

  useEffect(() => {
  }, [itemsToShow]);

  const handleOpenPageAdd = () => {
    Navigate('/modelos/canal');
  };

  const handleOpenModalConfirmDelete = (item) => {
    setSelectedItem(item);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirmDelete = () => {
    setOpenModalConfirm(false);
  }

  const handleOpenModalConfirmDuplicate = (item) => {
    setSelectedItem(item);
    setOpenModalConfirmDuplicate(true);
  }

  const handleCloseModalConfirmDuplicate = () => {
    setOpenModalConfirmDuplicate(false);
  }

  return (
    <Fragment>
      <Box width={"100%"} height={"100vh"}>
        < HeaderPage title="Modelos" />
        {
          loading === false && itemsToShow.length === 0 && (
            <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: { xs: "80%", sm: "100%" }, height: "90%" }}>
              <Box sx={{ width: { xs: "80%", sm: "auto" } }}>
                <Box component="img" src={EmptyTemplate} alt="Você não tem modelos" sx={{ width: { xs: "247px", sm: "494px" }, height: { xs: "165px", sm: "330px" } }} />
                <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                  <Titles bold type={"H3"} children={"Parece que você não tem modelos :("} color={"#8033F4"} />
                  <Titles type={"P1"} children={"Crie um agora mesmo, é fácil e rápido"} />

                  <Button
                    variant='contained'
                    style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                    onClick={handleOpenPageAdd}
                  >
                    Criar Modelo
                  </Button>
                </Box>
              </Box>
            </Box>
          ) || (
            <DynamicTable
              title="Listagem de Templates"
              data={itemsToShow}
              columns={[
                { header: '', field: 'integration_icons', render: (channel_type) => <StatusIcon status={channel_type} /> },
                { header: 'Nome', field: 'title' },
                { header: 'Tipo', field: 'msg_type', filter: true, render: (msg_type) => <StatusLabel status={msg_type} usingOnTable /> },
                // { header: 'Canal', field: 'integration_icons', render: (integration) => integrationIcons[integration] },
                { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },
              ]}
              actions={[

                { icon: <DuplicateIcon />, tooltip: 'Duplicar', action: (item) => handleOpenModalConfirmDuplicate(item), isVisible: (item) => true },
                { icon: <DeleteIcon />, tooltip: 'Deletar', action: (item) => handleOpenModalConfirmDelete(item), isVisible: (item) => true },
                {
                  icon: <EditIcon />, tooltip: 'Editar', action: (item) => {
                    localStorage.setItem('templateId', item.id);
                    if (item.type === "Custom" || item.type === "Custom123") {
                      Navigate("/modelos/editar")
                    } else if (item.type === "HTML") {
                      Navigate("/modelos/serviços/editar")
                    }
                  }, isVisible: (item) => true
                },
                { icon: <Visibility />, tooltip: 'Ocultar', action: (item) => alterVisibility(item.id, false), isVisible: (item) => item && item.Visibility === 'Visível' },
                { icon: <Visibility />, tooltip: 'Exibir', action: (item) => alterVisibility(item.id, true), isVisible: (item) => item && item.Visibility === 'Oculto' }
              ]}

              button={{ icon: <PlusIcon />, tooltip: 'Adicionar modelo ', action: handleOpenPageAdd }
              }

              activeSearch={true}
              titleSearch={'Pesquisar Modelos'}
              titleLabel={'Modelos por página'}
              loading={loading}
            />
          )
        }
      </Box>
      <ModalR
        cancelText={"Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={"Sim, desejo excluir"}
        subTitle={"Esta ação não poderá ser desfeita"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirmDelete}
        onConfirm={handleConfirmDelete} // Chama a função de confirmação
        title={"Tem certeza que deseja excluir?"}
        emoji={"emoji10"}
        iconColor={ThemeColors.purple400}
      />
      <ModalR
        cancelText={"Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={"Sim, desejo duplicar"}
        subTitle={""}
        isOpen={openModalConfirmDuplicate}
        onClose={handleCloseModalConfirmDuplicate}
        onConfirm={handleConfirmDuplicate}
        title={"Tem certeza que deseja duplicar?"}
        emoji={"emoji02"}
        iconColor={ThemeColors.purple400}
      />
    </Fragment>
  );
}

export default Templates;