// This endpoint is used to get all channels from the database.
// The response is an array of objects, each object represents a channel.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function GetInfoChannel(code) {
    try {

        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/channels/info`, 
            { 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}` 
                },
                params: {
                    code: code
                }
            }
        );

        console.log('response', response);
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}