// Description: This component render a card of a file that will appear to the client
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react'

// Material UI Components
import { Box } from '@mui/material';

// Components and functions from the project
import TrashIcon from '../../assets/Icons/image.png'
import StatusIcon from '../StatusLabel/StatusIcon';

// Render Your Component
const FileCard = ({ file, handleClick }) => {

    // file pode vim direto file.name ou file.file.name

    const name = file?.file?.name || file?.name

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            minHeight={'48px'}
            sx={{
                background: '#fff',
                borderRadius: '4px',
                padding: '0 10px'
            }}
        >
            <Box
                display={'flex'}
                gap={'10px'}
                alignItems={'end'}
            >
                {
                    file.base64String ? (
                        <img
                            src={file.base64String}
                            alt="file Image"
                            width={'24px'}
                            height={'24px'}
                            style={{ objectFit: 'cover' }}
                        />
                    ) : (
                        <StatusIcon status='Newspaper' />
                    )
                }
                <p style={{ fontSize: '13px', color: '#303035' }}>
                    {name.length > 25 ? name.slice(0, 25) + '...' : name}
                </p>
            </Box>

            <Box onClick={() => handleClick(file)} sx={{ cursor: 'pointer', '&:hover': { scale: '1.1' }, transition: '.3s' }}>
                <img src={TrashIcon} width={'11.3px'} height={'12.42px'} alt='trash icon' />
            </Box>
        </Box>
    )
}

export default FileCard