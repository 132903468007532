// Description: Component that will be render while fetching data for the table
// Updated:
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components
import { hexToRgb, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, LinearProgress, Box } from '@mui/material';

// Components and functions for the project
import themeColors from '../ThemeColors/ThemeColors';

// Render The Component 
const DynamicTableLoading = ({ tableColumns }) => {

    const slicedData = ["", "", "", "", ""];

    return (
        <TableContainer>

            <Table>
                <TableHead>
                    <TableRow>

                        {tableColumns.map((column, index) => (
                            <TableCell
                                key={index}
                                align={column.align ? column.align : 'left'}
                                sx={{
                                    fontSize: { xl: '16px', lg: '13px', sm: '13px' }, fontFamily: 'Satoshi', padding: { lg: '16px 30px 16px 30px', sm: '16px 10px' }, borderBottom: 'none', maxWidth: { sm: '150px', lg: '200px', xl: '100%' }, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: { xs: index < 2 ? 'visible' : 'hidden' },
                                    whiteSpace: 'nowrap',
                                    position: { xs: index < 2 ? 'sticky' : 'static', sm: 'static' },
                                    left: { xs: index === 0 ? 0 : index === 1 ? '50px' : 'auto', sm: 'auto' },
                                    zIndex: { xs: index < 2 ? 1 : 'auto', sm: 'auto' },
                                    background: { xs: index < 2 ? '#f9f9f9' : 'none', sm: 'none' },
                                }}
                            >
                                <Box display={'flex'} width={'100%'} alignItems={'center'}>
                                    {column.header}
                                </Box>
                            </TableCell>

                        ))}

                    </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: { xs: '#fff', sm: 'none' } }}>
                    {slicedData.map((item, idx) => (  // Aqui 'idx' é declarado corretamente como o segundo parâmetro
                        <React.Fragment key={item.id}>
                            <TableRow style={{ height: '5px' }} />
                            <TableRow id={item.id} hover sx={{ height: { xs: '45px', sm: '80px' } }} >
                                {tableColumns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        className={`item + ${index}`}
                                        sx={{
                                            padding: { lg: '16px 30px 16px 30px', sm: '16px 10px' },
                                            width: index === 0 ? '10px' : 'auto',
                                            borderBottom: 'none',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            position: { xs: index < 2 ? 'sticky' : 'static', sm: 'static' },
                                            left: { xs: index === 0 ? 0 : index === 1 ? '50px' : 'auto', sm: 'auto' },
                                            zIndex: { xs: index < 2 ? 1 : 'auto', sm: 'auto' },
                                            background: '#fff',
                                            textAlign: 'left',
                                            minWidth: index === 0 ? "72px" : { sm: '150px', lg: '200px', xl: '250px' },
                                            textOverflow: 'ellipsis',
                                            overflow: { xs: index === 1 ? 'visible' : 'hidden' },
                                            whiteSpace: 'nowrap',
                                            boxShadow: { xs: index === 1 ? '6px 0px 20px rgba(0, 0, 0, 0.3)' : 'none', sm: 'none' },
                                        }}
                                    >
                                        <LinearProgress
                                            sx={{
                                                height: '10px',
                                                borderRadius: '6px',
                                                minWidth: '50px',
                                                width: '100%',
                                                backgroundColor: themeColors.gray20,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: themeColors.gray30
                                                },
                                            }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow style={{ height: '5px' }} />
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DynamicTableLoading