import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar, { sidebarDatabase } from './Sidebar'
import SubPage from './Subpage';
import MobileNavbar from './MobileNavbar';


const ConfigurationPage = () => {
  const [selectedOption, setSelectedOption] = useState(sidebarDatabase[0]);
  const [page, setPage] = useState('General');

  useEffect(() => {
    setPage(selectedOption.page)
  }, [selectedOption])


  return (
    <Box sx={{
      backgroundColor: '#FFF',
      minHeight: { sm: '100vh' },
      display: 'flex',
      flexDirection: { xs: 'column-reverse', sm: 'row' }
    }}>
      <MobileNavbar selected={selectedOption} setSelected={setSelectedOption} />
      <Sidebar selected={selectedOption} setSelected={setSelectedOption} />
      <SubPage page={page} />
    </Box>
  )
}

export default ConfigurationPage