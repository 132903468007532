// This component is responsible for the recovery of the user's password. It has three steps:
// 1. The user enters their email address and receives a code to confirm their identity.
// 2. The user enters the code to confirm their identity.
// 3. The user enters a new password to change their password.
// It uses the TryRecovery, VerifyUrls, VerifyToken, and ChangePassword functions from the backend API to handle the recovery process.
// It uses the useNavigate and useParams hooks from react-router-dom to handle the redirection and URL parameters.
// It uses the useState and useEffect hooks from React to manage the component's state and side effects.
// It uses the Box and Grid components from the Material-UI library to create the layout of the recovery form.
// It uses the Logo component to render the company logo.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101089281/Login
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=34-1395&t=M6SZc7mIECmoXB0I-4

import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Box, Grid, useMediaQuery } from '@mui/material';

import { ChangePassword } from '../../api/backend/Auth/ChangePassword';
import Erro from '../Error/index'
import FormInitAnimate from '../../assets/ilustrations/FormInitAnimate.gif';
import FormRecovery from './FormRecovery';
import FormConfirm from './FormConfirm';
import FormChangePassword from './FormChangePassword';
import Logo from '../../assets/ilustrations/Logo';
import './styles.css';
import { TryRecovery } from '../../api/backend/Auth/TryRecovery';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { VerifyUrls } from '../../api/backend/Auth/VerifyUrls';
import { VerifyToken } from '../../api/backend/Auth/VerifyToken';
import MobileLoginRecovery from './MobileLoginRecovery';
import MobileChangePassword from './MobileChangePassword';
import MobileFormConfirm from './MobileFormConfirm';

const LoginRecoveryPage = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [codeConfirm, setCodeConfirm] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams();
    const [tokenType, setTokenType] = useState('');
    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const Navigate = useNavigate();

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!username) {
            setMessage('Por favor, preencha o campo de email.');
            return;
        }

        setLoading(true);

        let recoveryInfo = { 'user': username }

        try {

            const response = await TryRecovery(recoveryInfo);

            if (response.status === 404) {
                setMessage('Esse email ou usuário é inválido.');
            } else {
                setMessage('');
                console.log('Resposta da API:', response);
                setTokenType('url1');
                Navigate('/login/recovery/' + response.url1);
            }

            setLoading(false);

        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw error;
        }
    };

    const verifyLink1IsValid = async () => {

        if (token.length === 10) {

            let url1Info = { 'token': token, 'type': 'url1' }

            try {

                const response = await VerifyUrls(url1Info);

                console.log('Resposta da API1:', response);
                if (response.message === 'success') {
                    setTokenType('url1');
                    setShowForm(true);
                } else {
                    setShowForm(false);
                }

                setLoading(false);

            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
                throw error;
            }

        } else if (token.length === 30) {

            let url2Info = { 'token': token }

            try {

                const response = await VerifyUrls(url2Info);

                if (response.message === 'success') {
                    setTokenType('url2');
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setTokenType('error')
                }

                setLoading(false);

            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
                throw error;
            }
            finally {
                setLoading(false);
            }
        }
    };

    const handleSubmitConfirm = async (event) => {

        event.preventDefault();

       
        try {

            const confirmInfo = { 'code': codeConfirm, 'token': token }

            const response = await VerifyToken(confirmInfo);

            console.log('Resposta da API2:', response);

            if (response.url2) {
                setLoading(false);
                setMessage('');
                setTokenType('url2');
                Navigate('/login/recovery/' + response.url2);
            } else {
                setMessage('Código inválido.');
                setLoading(false);
            }
        }
        catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw error;
        }
    };

    const handleSubmitChangePassword = async (event) => {

        event.preventDefault();

        if (!newPassword || !confirmPassword) {
            setMessage('Por favor, preencha todos os campos.');
            return;

        }

        if (newPassword !== confirmPassword) {
            setMessage('As senhas não coincidem.');
            return;
        }

        setLoading(true);

        let changePasswordInfo = { 'password': newPassword, 'url2': token , 'confirmPassword': confirmPassword }

        try {

            const response = await ChangePassword(changePasswordInfo);

            console.log('Resposta da API3:', response);

            if (response.message === 'success') {
                Navigate('/', { state: { message: 'Senha alterada com sucesso.' } });
            } else {
                setMessage('Ocorreu um erro ao alterar a senha.');
            }

        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw error;
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        setLoading(true);

        try {

            setPassword('')
            setUsername('')
            setNewPassword('')
            setConfirmPassword('')
            setCodeConfirm('')

            if (token) {

                verifyLink1IsValid()

                console.log('Token:', token);
                if (token.length === 10 && showForm) {
                    setTokenType('url1')
                } else if (token.length === 30 && showForm) {
                    console.log('entrou no token 30')
                    setTokenType('url2')
                } else {
                    setTokenType('error01')
                }

            } else {
                setTokenType('initial')
            }

        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw error;
        }
        finally {
            setLoading(false);
            if (tokenType === 'error01') {
                setTokenType('error')
            }
        }

    }, [token]);

    return (

        <>

            {tokenType === 'url1' ? (
                <>
                    {
                        isSmallScreen ? (
                            <MobileFormConfirm loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitConfirm={handleSubmitConfirm} />
                        ) : (
                            <>
                                <div style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    padding: '10px',
                                }}>
                                    <Logo />
                                </div>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: ThemeColors.gray10 }}>
                                    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                                        <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                            <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                            <FormConfirm loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitConfirm={handleSubmitConfirm} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )
                    }
                </>
            ) : tokenType === 'initial' ? (
                <>
                    {
                        isSmallScreen ? (
                            <MobileLoginRecovery username={username} setUsername={setUsername} password={password} setPassword={setPassword} loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmit={handleSubmit} />
                        ) : (
                            <>
                                <div style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    padding: '10px',
                                }}>
                                    <Logo />
                                </div>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: ThemeColors.gray10 }}>
                                    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                                        <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                            <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                            <FormRecovery username={username} setUsername={setUsername} password={password} setPassword={setPassword} loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmit={handleSubmit} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )
                    }
                </>
            ) : tokenType === 'url2' ? (
                <>
                    {
                        isSmallScreen ? (
                            <MobileChangePassword loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitChangePassword={handleSubmitChangePassword} newPassword={newPassword} setNewPassword={setNewPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} />
                        ) : (
                            <>
                                <div style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    padding: '10px',
                                }}>
                                    <Logo />
                                </div>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: ThemeColors.gray10 }}>
                                    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                                        <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                            <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                            <FormChangePassword loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitChangePassword={handleSubmitChangePassword} newPassword={newPassword} setNewPassword={setNewPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        )
                    }
                </>

            ) : tokenType === 'error' ? (
                <Erro />
            ) : null}
        </>
    )
};

export default LoginRecoveryPage;