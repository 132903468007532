// Description: Navigation bar that will help the user navigate across the configurations page
// Updated:
// Author: Simon Franklin

// React
import React from "react";

// Material UI Components
import { Box, Typography } from "@mui/material";
import { sidebarDatabase } from "./Sidebar";

const MobileNavbar = ({ selected, setSelected }) => {
    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                height: "110px",
                width: "100%",
                backgroundColor: "#fff",
                display: { xs: "flex", sm: "none" },
                padding: "16px 24px",
                gap: "20px",
                zIndex: 20,
                boxShadow: "-6px 0px 8px rgba(0, 0, 0, 0.2)"
            }}
        >
            {sidebarDatabase.map((item) => (
                <Box
                    onClick={() => setSelected(item)}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "47px",
                            height: "47px",
                            borderRadius: "50%",
                            backgroundColor: selected.name === item.name ? "#DDD4FF" : "transparent",
                            justifyContent: "center",
                            alignItems: "center",
                            display: 'flex'
                        }}
                    >
                        <item.icon
                            color={selected.name === item.name ? "#8033F4" : "#666673"}
                        />
                    </Box>
                    <Typography
                        children={item.name}
                        color={selected.name === item.name ? "#8033F4" : "#666673"}
                        fontSize={"16px"}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default MobileNavbar;
