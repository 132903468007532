// React imports
import React, { useState, useEffect, useRef } from 'react';

// Material UI Components
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl, Select, MenuItem } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TextField from '@mui/material/TextField';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, TablePagination } from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

// Components and Functions for the project
import StatusLabel from '../StatusLabel/StatusLabel';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import axios from 'axios';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { CampaignsByUuidAccount } from '../../api/backend/Campaigns/CampaignsByUuidAccount';
import { ConsultReports } from '../../api/backend/Reports/consultReports';
import { notify } from '../../utils/utils';
import DownloadReportsModal from '../Modals/DownloadReportsModal';
import { set } from 'date-fns';
import { name } from 'dayjs/locale/pt-br';
import { select } from 'slate';

const CampaigsCheckBox = ({ campaigns, filteredCampaigns, setFilteredCampaigns, selectedLabel }) => {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);
    const triggerRef = useRef(null);
    const [dropdownWidth, setDropdownWidth] = useState("auto");

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    useEffect(() => {
        if (triggerRef.current) {
            setDropdownWidth(triggerRef.current.offsetWidth);
        }
    }, [open]);

    const alreadyAddedCampaign = (campaign) => {
        return filteredCampaigns.includes(campaign);
    };

    const addOrRemoveCampaign = (campaign) => {
        setFilteredCampaigns((prev) =>
            alreadyAddedCampaign(campaign)
                ? prev.filter((item) => item !== campaign)
                : [...prev, campaign]
        );
    };

    return (
        <Box sx={{ position: "relative" }} ref={dropdownRef}>
            <Box
                component={"div"}
                sx={{
                    background: "#f9f9f9",
                    borderRadius: "12px",
                    padding: "16.5px 14px",
                    display: "flex",
                    cursor: "pointer",
                    height: "56px",
                    width: '100%',
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                onClick={() => campaigns.length > 0 && selectedLabel !== 'total_messages_received' ? setOpen(!open) : null}
            >
                {campaigns.length === 0 && selectedLabel !== 'total_messages_received' ? (
                    <LinearProgress
                        sx={{
                            height: "10px",
                            borderRadius: "6px",
                            minWidth: "35px",
                            width: "100%",
                            backgroundColor: "#f0f0f0",
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: "#d0d0d0",
                            },
                        }}
                    />
                ) :

                    selectedLabel === 'total_messages_received' ? (
                        <>
                            <Typography sx={{ fontSize: "16px", color: ThemeColors.gray30, fontWeight: 500 }}>
                            </Typography>
                        </>
                    )
                        :
                        (
                            <>
                                <Typography sx={{ fontSize: "16px", color: ThemeColors.gray30, fontWeight: 500 }}>
                                    Filtrar Campanhas
                                </Typography>
                                <SearchIcon sx={{ color: ThemeColors.gray40 }} />
                            </>
                        )}
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    background: "#fff",
                    border: "1px solid #ddd",
                    padding: "10px 14px",
                    display: open ? "flex" : "none",
                    flexDirection: "column",
                    gap: "10px",
                    minHeight: "56px",
                    width: dropdownWidth,
                    top: "100%",
                    left: 0,
                    maxHeight: "330px",
                    overflowY: "auto",
                    zIndex: 10,
                    boxShadow: "2px 6px 8px rgba(0, 0, 0, 0.2)",
                }}
            >
                {campaigns.map((item) => (
                    <Box key={item.campaign} sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Box
                            component="div"
                            onClick={() => addOrRemoveCampaign(item.campaign)}
                            sx={{
                                minHeight: "15px",
                                minWidth: "15px",
                                borderRadius: "50%",
                                border: `1.5px solid ${ThemeColors.purple500}`,
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Box
                                sx={{
                                    background: alreadyAddedCampaign(item.campaign) ? ThemeColors.purple500 : "transparent",
                                    minHeight: "8px",
                                    minWidth: "8px",
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: "14px", color: "#000" }}>{item.campaign}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const Labels = ({ selectedLabel, handleIconClick, labelsValues, loading }) => {

    const labels = [
        { key: 'total_campaigns', label: 'Geral campanhas', icon: FactCheckOutlinedIcon },
        { key: 'total_campaigns_read', label: 'Lidas', icon: TaskAltOutlinedIcon },
        { key: 'total_campaigns_send', label: 'Enviadas', icon: SendOutlinedIcon },
        { key: 'total_campaigns_failed', label: 'Falhadas', icon: ErrorOutlineOutlinedIcon },
        { key: 'total_messages_received', label: 'Recebidas', icon: AccessTimeOutlinedIcon },
    ];

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(x => x + x).join('');
        }
        const num = parseInt(hex, 16);
        return `${(num >> 16) & 255}, ${(num >> 8) & 255}, ${num & 255}`;
    };

    return (
        <>
            <Box sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px',
                borderRadius: '10px',
                marginTop: '20px',
                gap: 2,
            }}>
                {labels.map((label, index) => (
                    <Box
                        key={index}
                        onClick={loading ? null : () => handleIconClick(label.key)}
                        sx={{
                            cursor: loading ? 'wait' : 'pointer',
                            backgroundColor: selectedLabel === label.key ? ThemeColors.purple100 : 'fff',
                            padding: '10px',
                            borderRadius: '10px',
                            flex: 1,
                            maxWidth: { xs: '140px', sm: 'calc(33.33% - 20px)' },
                            minWidth: { xs: '140px', sm: 'none' },
                            border: selectedLabel === label.key ? 'none' : '1px solid #D9D9DC',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                width: { xl: '50px', lg: '50px', sm: '40px' },
                                height: { xl: '50px', lg: '50px', sm: '40px' },
                                borderRadius: { xl: '10px', sm: '6px' },
                                background: selectedLabel === label.key ? ThemeColors.purple200 : ThemeColors.gray20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <label.icon sx={{ color: selectedLabel === label.key ? ThemeColors.purple600 : ThemeColors.gray40 }} />
                            </Box>
                            <Box sx={{ marginLeft: '10px' }}>
                                {loading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'left', minHeight: '20px' }}>
                                        <LinearProgress
                                            sx={{
                                                height: '10px',
                                                borderRadius: '6px',
                                                minWidth: '50px',
                                                width: '100%',
                                                backgroundColor: `rgba(${hexToRgb(ThemeColors.gray20)}, 0.8)`,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: ThemeColors.gray20
                                                },
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <Typography sx={{
                                        fontSize: { xl: '18px', lg: '18px', sm: '12px' },
                                        fontFamily: 'Satoshi-Medium',
                                        color: selectedLabel === label.key ? ThemeColors.purple600 : ThemeColors.gray40
                                    }}>
                                        {labelsValues[label.key]}
                                    </Typography>
                                )}
                                <Typography sx={{
                                    color: 'rgb(0 0 0 / 50%)',
                                    fontSize: { xl: '14px', lg: '14px', sm: '11px' }
                                }}>
                                    {label.label}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export const Filter = ({ selectedLabel, setStartDate, setEndDate, startDate, endDate, downloadExcel, loadingExcel, searchCampaigns, loadingSearch, loadingLabels, campaigns, filteredCampaigns, setFilteredCampaigns }) => {

    const startDateTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#F9F9F9',
                        color: ThemeColors.gray30,
                        borderRadius: '12px',
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.purple400,
                        borderRadius: '2px',
                        borderWidth: '1px',
                        border: '1px solid',
                        backgroundColor: '#FFF',
                        '&:hover': {
                            backgroundColor: ThemeColors.purple400,
                            color: '#FFF',
                        },
                        '&.Mui-selected': {
                            backgroundColor: ThemeColors.purple400 + " !important",
                            color: '#FFF !important',
                        },
                    }
                }

            }

        }
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                padding: '20px',
                marginTop: '20px',
            }}
        >
            <FormControl variant="outlined" sx={{ flex: 1 }}>
                <CampaigsCheckBox campaigns={campaigns} filteredCampaigns={filteredCampaigns} setFilteredCampaigns={setFilteredCampaigns} selectedLabel={selectedLabel} />
            </FormControl>
            <FormControl variant="outlined" sx={{ flex: 1 }}>
                <ThemeProvider theme={startDateTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DesktopDatePicker
                            value={startDate}
                            onChange={(newValue) => (setStartDate(newValue))}
                            maxDate={dayjs()}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </FormControl>
            <FormControl variant="outlined" sx={{ flex: 1 }}>
                <ThemeProvider theme={startDateTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DesktopDatePicker
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            maxDate={dayjs()}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="DD/MM/YYYY"
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </FormControl>
            <Button
                variant="contained"
                sx={{
                    minWidth: { xs: 'auto', sm: '250px' }, padding: { xs: '15px', sm: '0px' }, borderRadius: '6px',
                    backgroundColor: loadingSearch || loadingExcel || loadingLabels ? `${ThemeColors.gray10} !important` : ThemeColors.purple300,
                    color: loadingSearch || loadingExcel || loadingLabels ? `${ThemeColors.gray40} !important` : ThemeColors.purple950,
                    '&:hover': {
                        backgroundColor: ThemeColors.purple400 + 'CC',
                    },
                }}

                onClick={() => searchCampaigns()}
                disabled={loadingSearch || loadingExcel || loadingLabels}
            >
                {loadingSearch ? 'Pesquisando...' : loadingExcel ? 'Aguarde...' : loadingLabels ? 'Aguarde...' : 'Pesquisar'}
            </Button>

            <Button
                variant="contained"
                sx={{
                    minWidth: { xs: 'auto', sm: '250px' },
                    padding: { xs: '15px', sm: '0px' },
                    borderRadius: '6px',
                    backgroundColor: loadingSearch || loadingExcel || loadingLabels ? `${ThemeColors.gray10} !important` : ThemeColors.green01,
                    color: loadingSearch || loadingExcel || loadingLabels ? `${ThemeColors.gray40} !important` : ThemeColors.green03,
                    '&:hover': {
                        backgroundColor: ThemeColors.green01 + 'CC',
                    },
                }}
                disabled={loadingSearch || loadingExcel || loadingLabels}
                onClick={() => downloadExcel()}
            >
                {loadingExcel ? 'Baixando...' : loadingSearch ? 'Aguarde...' : loadingLabels ? 'Aguarde...' : 'Baixar Excel'}
            </Button>
        </Box>
    );
};

const TableRowsLoader = ({ rowsNum, columns }) => {
    return [...Array(rowsNum)].map((_, index) => (
        <TableRow key={index}>
            {columns.map((_, colIndex) => (
                <TableCell
                    key={colIndex}
                    sx={{
                        backgroundColor: index % 2 === 0 ? 'rgb(247 247 252)' : '#ffffff',
                        padding: '30px',
                        borderBottom: 'none',
                    }}
                >
                    <Skeleton animation="wave" variant="text" width="100%" />
                </TableCell>
            ))}
        </TableRow>
    ));
};

export const DynamicTable = ({ columns, data, totalItems, page, rowsPerPage, handlePageChange, loading, selectedLabel }) => {

    const renderColumn = (column, value) => {
        if (column === "Data de envio") {
            return formatDate(value)
        } else if (column === "Status/Entrega") {
            return <StatusLabel status={translateStatus(value)} usingOnNewTable />
        } else {
            return value
        }
    }

    const translateStatus = (status) => {
        if (status === 'read') {
            return 'Lido'
        } else if (status === 'delivered') {
            return 'Entregue'
        } else if (status === 'failed') {
            return 'Falha'
        } else if (status === 'received') {
            return 'Recebido'
        } else {
            return 'Desconhecido'
        }
    };

    const formatDate = (data) => {
        return data
    };

    const translateColumn = (columnName) => {
        switch (columnName) {
            case 'Campanha':
                return 'campaign';
            case 'Modelo':
                return 'template';
            case 'Data Execução':
                return 'last_execution';
            case 'Mensagens totais':
                return 'sent';
            case 'Entregues':
                return 'delivered';
            case 'Lidas':
                return 'read';
            case 'Recebidas':
                return 'received';
            case 'Falhas':
                return 'failed';
            case 'Descrição':
                return 'descricao_campanha';
        }
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', padding: '20px' }}>
            <TableContainer component={Paper} elevation={0} sx={{ border: 'none' }}>
                <Table sx={{ minWidth: 650, border: 'none' }} aria-label="dynamic table">
                    <TableHead>
                        <TableRow sx={{ borderBottom: 'none' }}>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '13px', sm: '16px' },
                                        borderBottom: 'none',
                                        textAlign: index === 0 ? 'left' : 'center',
                                        position: { xs: index < 2 ? 'sticky' : 'static', sm: 'static' },
                                        zIndex: { xs: index < 1 ? 1 : 'auto', sm: 'auto' },
                                        background: '#fff'
                                    }}
                                >
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowsLoader rowsNum={100} columns={columns} />
                        ) : (
                            data.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{
                                        borderBottom: 'none',
                                        backgroundColor: rowIndex % 2 === 0 ? 'rgb(247 247 252)' : '#ffffff',
                                    }}
                                >
                                    {columns.map((column, colIndex) => (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                borderBottom: 'none',
                                                textAlign: colIndex === 0 ? 'left' : 'center',
                                                padding: selectedLabel === 'total_campaigns' ? '30px' : '20px',
                                                position: { xs: colIndex < 2 ? 'sticky' : 'static', sm: 'static' },
                                                zIndex: { xs: colIndex < 1 ? 1 : 'auto', sm: 'auto' },
                                                fontSize: { xs: '13px', sm: '16px' },
                                                backgroundColor: rowIndex % 2 === 0 ? 'rgb(247 247 252)' : '#ffffff',
                                            }}
                                        >
                                            {renderColumn(column, row[column])}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            { totalItems > 0 && !loading && (
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                />
            )}
        </Box>
    );
};

const IndicatorsList = () => {

    const [selectedLabel, setSelectedLabel] = useState('total_campaigns');
    const [loadingLabels, setLoadingLabels] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [labelsValues, setLabelsValues] = useState({});
    const [columns, setColumns] = useState(['Título da campanha', 'Modelo utilizado', 'Destinatário', 'Data de envio', 'Status/Entrega']);
    const [data, setData] = useState([]);
    const [reports, setReports] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [filename, setFilename] = useState("");
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [filteredMockup, setFilteredMockup] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [generateList, setGenerateList] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleIconClick = (label) => {
        setSelectedLabel(label);
    }

    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const rowsPerPage = 100;

    function formatISOStringIntoDate(isoString) {
        return new Date(isoString).toISOString().slice(0, 10);
    };


    useEffect(() => {

        setLoadingLabels(true);
        setLoadingSearch(true);

        const body = {
            filter_status: selectedLabel === 'total_campaigns_read' ? 'read' : selectedLabel === 'total_campaigns_failed' ? 'failed' : selectedLabel === 'total_campaigns_send' ? 'delivered' : null,
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
            page_id: page + 1,
            page_size: rowsPerPage
        };

        const url = process.env.REACT_APP_BACKEND_URL;

        axios.post(`${url}/painel/data/messages/general/total`, body, {
            headers: { 'Authorization': localStorage.getItem('jwt_token'), 'clientid': localStorage.getItem('idToken') }
        }).then((response) => {
            setLabelsValues(response.data || {});
        }).catch((error) => {
            console.error("Erro ao buscar os indicadores:", error);
        }).finally(() => {
            setLoadingLabels(false);
            setLoadingSearch(false);

        }
        );

    }, []);

    useEffect(() => {

        setLoadingTable(true);
        setLoadingLabels(true);

        const authorization = localStorage.getItem('jwt_token');

        if (selectedLabel === 'total_campaigns_read' || selectedLabel === 'total_campaigns_failed' || selectedLabel === 'total_campaigns_send' || selectedLabel === 'total_campaigns') {

            setColumns(['Título da campanha', 'Modelo utilizado', 'Destinatário', 'Data de envio', 'Status/Entrega']);
            setLoadingTable(true);

            if (campaignOptions.length === 0) {
                (async () => {
                    CampaignsByUuidAccount().then((response) => {
                        const mappedData = response.campaigns.map((item) => ({
                            campaign: item.title,
                            template: item.code,
                            ...item
                        }));

                        setCampaignOptions(mappedData);
                        console.log('campanhas', mappedData);

                    }).catch((error) => {
                        console.error('Erro ao buscar os indicadores:', error);
                    });
                })();
            }

            const filterStatus = selectedLabel === 'total_campaigns_read' ? 'read' : selectedLabel === 'total_campaigns_failed' ? 'failed' : selectedLabel === 'total_campaigns_send' ? 'delivered' : null;
            const body = {
                filter_status: filterStatus,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                page_id: page + 1,
                page_size: rowsPerPage
            };

            const url = process.env.REACT_APP_BACKEND_URL;

            axios
                .post(`${url}/painel/data/messages/general`, body, {
                    headers: { Authorization: authorization, clientid: localStorage.getItem('idToken') }
                })
                .then((response) => {

                    console.log('aquiiiiiiiii', response.data);
                    const mappedData = response.data.data.map((item) => ({
                        "Título da campanha": item['Título do Agendamento'],
                        "Modelo utilizado": item['Título do Modelo'],
                        "Destinatário": item['Contato Destinatário'],
                        "Data de envio": item['Data de Criação'],
                        "Status/Entrega": item['Último Status']
                    }));
                    setColumns(Object.keys(mappedData[0] || {}));
                    setData(mappedData);
                    setTotalItems(response.data.total_items);
                })
                .catch((error) => {
                    console.error('Erro ao buscar os indicadores:', error);
                })
                .finally(() => {
                    setLoadingTable(false);
                    setLoadingLabels(false);
                });

        } else if (selectedLabel === 'total_messages_received') {

            setColumns(['Data de recebimento', 'Nome do contato', 'Contato', 'Mensagem', 'Status/Entrega']);
            setLoadingTable(true);

            const body = {
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                page_id: page + 1,
                page_size: rowsPerPage
            };

            const token = localStorage.getItem('jwt_token');
            const client_id = localStorage.getItem('idToken');

            const url = process.env.REACT_APP_BACKEND_URL;

            axios.post(`${url}/painel/data/messages/received`, body, {
                headers: { Authorization: authorization, clientid: client_id }
            })
                .then((response) => {
                    const mappedData = response.data.data.map((item) => ({
                        "Nome do contato": item.pushName.split(' ')[0],
                        "Contato": item.remoteJid,
                        "Mensagem": item.conversation.length > 50 ? item.conversation.substring(0, 40) + '...' : item.conversation,
                        "Data de recebimento": item.timestamp,
                        'Status/Entrega': 'received'
                    }));
                    setData(mappedData);
                    setTotalItems(response.data.total_items);
                })
                .catch((error) => {
                    console.error('Erro ao buscar os indicadores:', error);
                })
                .finally(() => {
                    setLoadingTable(false);
                    setLoadingLabels(false);
                });
        }

    }, [selectedLabel, page]);

    useEffect(() => {
        setFilteredMockup([]);
    }, [selectedLabel])

    const downloadExcel = async () => {
        if (['total_campaigns', 'total_campaigns_read', 'total_campaigns_failed', 'total_campaigns_send'].includes(selectedLabel)) {
            setLoadingExcel(true);
            notify('Download', 'Seu relatório está sendo gerado', true);

            const filterStatus = {
                total_campaigns_read: 'read',
                total_campaigns_failed: 'failed',
                total_campaigns_send: 'delivered'
            }[selectedLabel] || null;

            const body = {
                filter_status: filterStatus,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                name_campaign: searchCampaigns,
                file_name: filename,
                generate_list: generateList
            };

            const url = process.env.REACT_APP_BACKEND_URL;

            await axios.post(`${url}/painel/data/messages/general/download`, body, {
                headers: { Authorization: localStorage.getItem('jwt_token'), clientid: localStorage.getItem('idToken') },
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            })
                .then((response) => {
                    const url_download = response.data.url;
                    const link = document.createElement('a');
                    link.href = url_download;
                    link.download = url_download.split('/').pop();
                    link.click();
                    notify('Success', 'Seu Relatório foi baixado com sucesso!', true);
                    setOpenModal(false);
                })
                .catch((error) => {
                    console.error('Erro ao buscar os indicadores:', error);
                    notify('Error', 'Ocorreu algum erro no download', true);
                })
                .finally(() => {
                    setLoadingExcel(false);
                    setProgress(0);
                    setFilename("");
                    setGenerateList(false);
                });
        }
    };

    function searchCampaigns() {

        if (selectedLabel === 'total_campaigns_read' || selectedLabel === 'total_campaigns_failed' || selectedLabel === 'total_campaigns_send' || selectedLabel === 'total_campaigns') {

            setColumns(['Título da campanha', 'Modelo utilizado', 'Destinatário', 'Data de envio', 'Status/Entrega']);
            setLoadingTable(true);

            if (campaignOptions.length === 0) {
                (async () => {
                    CampaignsByUuidAccount().then((response) => {
                        const mappedData = response.campaigns.map((item) => ({
                            campaign: item.title,
                            template: item.code,
                            ...item
                        }));

                        setCampaignOptions(mappedData);
                        console.log('campanhas', mappedData);

                    }).catch((error) => {
                        console.error('Erro ao buscar os indicadores:', error);
                    });
                })();
            }

            const filterStatus = selectedLabel === 'total_campaigns_read' ? 'read' : selectedLabel === 'total_campaigns_failed' ? 'failed' : selectedLabel === 'total_campaigns_send' ? 'delivered' : null;
            const body = {
                filter_status: filterStatus,
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                page_id: page + 1,
                page_size: rowsPerPage,
                name_campaign: filteredMockup
            };

            const url = process.env.REACT_APP_BACKEND_URL;

            axios
                .post(`${url}/painel/data/messages/general`, body, {
                    headers: { Authorization: localStorage.getItem('jwt_token'), clientid: localStorage.getItem('idToken') }
                })
                .then((response) => {

                    console.log('aquiiiiiiiii', response.data);
                    const mappedData = response.data.data.map((item) => ({
                        "Título da campanha": item['Título do Agendamento'],
                        "Modelo utilizado": item['Título do Modelo'],
                        "Destinatário": item['Contato Destinatário'],
                        "Data de envio": item['Data de Criação'],
                        "Status/Entrega": item['Último Status']
                    }));
                    setColumns(Object.keys(mappedData[0] || {}));
                    setData(mappedData);
                    setTotalItems(response.data.total_items);
                })
                .catch((error) => {
                    console.error('Erro ao buscar os indicadores:', error);
                })
                .finally(() => {
                    setLoadingTable(false);
                    setLoadingLabels(false);
                });

        } else if (selectedLabel === 'total_messages_received') {

            setLoadingSearch(true);
            setLoadingTable(true);
            setLoadingLabels(true);

            setColumns(['Data de recebimento', 'Nome do contato', 'Contato', 'Mensagem', 'Status/Entrega']);

            const body = {
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
            };

            const url = process.env.REACT_APP_BACKEND_URL;

            axios.post(`${url}/painel/data/messages/received`, body, {
                headers: {
                    Authorization: localStorage.getItem('jwt_token'), clientid: localStorage.getItem('idToken')
                }
            })
                .then((response) => {
                    const mappedData = response.data.data.map((item) => ({
                        "Nome do contato": item.pushName.split(' ')[0],
                        "Contato": item.remoteJid,
                        "Mensagem": item.conversation,
                        "Data de recebimento": item.timestamp,
                        'Status/Entrega': 'received'
                    }));
                    setData(mappedData);
                })
                .catch((error) => {
                    console.error('Erro ao buscar os indicadores:', error);
                })
                .finally(() => {
                    setLoadingTable(false);
                    setLoadingSearch(false);
                });

            axios.post(`${url}/painel/data/messages/general/total`, body, {
                headers: { Authorization: localStorage.getItem('jwt_token'), clientid: localStorage.getItem('idToken') }
            }).then((response) => {
                setLabelsValues(response.data || {});
            }).catch((error) => {
                console.error("Erro ao buscar os indicadores:", error);
            }).finally(() => {
                setLoadingLabels(false);
            }
            );
        }

    }

    return (
        <>
            {progress > 0 && progress < 100 && (
                <progress
                    style={{
                        position: 'fixed',
                        top: '100px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 10000,
                        width: '300px',
                        height: '20px',
                        backgroundColor: '#FFF',
                        borderRadius: '8px'
                    }}
                    value={progress}
                    max="100"
                >
                    {progress}%
                </progress>
            )}
            <Labels selectedLabel={selectedLabel} handleIconClick={handleIconClick} labelsValues={labelsValues} loading={loadingLabels} />
            <Filter selectedLabel={selectedLabel} setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} downloadExcel={() => setOpenModal(true)} loadingExcel={loadingExcel} searchCampaigns={searchCampaigns} loadingSearch={loadingSearch} loadingLabels={loadingLabels} campaigns={campaignOptions} filteredCampaigns={filteredMockup} setFilteredCampaigns={setFilteredMockup} />
            <DynamicTable columns={columns} selectedLabel={selectedLabel} data={reports.length > 0 && filteredMockup.length > 0 ? reports : data} totalItems={totalItems} page={page} rowsPerPage={rowsPerPage} handlePageChange={(event, newPage) => setPage(newPage)} loading={loadingTable} />
            <DownloadReportsModal name={filename} open={openModal} onClose={() => setOpenModal(false)} setName={setFilename} finishFunction={downloadExcel} generateList={generateList} setGenerateList={setGenerateList} />
        </>
    );
};

export default IndicatorsList;