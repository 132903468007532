// Desc: Page to add new sheets to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Select, MenuItem, Button, FormHelperText, Menu } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";

import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { isAuthenticated } from "../../authMiddleware";
import { PostListagens } from "../../api/n8n/Listagens/PostListagens";
import { id } from "date-fns/locale";
import Loading from "../../components/Loading/Loading";

import CampaignModal from "../../components/Modals/CampaignModal";
import { notify } from "../../utils/utils";

// Render Form to add new sheets
function SheetsForm({ setDataR, copyRespostas, setCopyRespostas, setErrors, errors, setTabValue, setErroLoading, uuidClient }) {

  // State to authenticate control
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();


  // States to control the form fields
  const [formErros, setFormErros] = useState({});
  const [setFormateList, setListagensOptions] = useState([]);
  const [formateSelected, setFormateSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);

  const listOptions = [
    { id: 2, title: "Google planilhas" }
  ];

  const [respostas, setRespostas] = useState({
    title: copyRespostas?.title || "",
    desc: copyRespostas?.desc || "",
    url: copyRespostas?.url || "",
    formatSheets: "Google planilhas",
    deleteDuplicatedVariables: copyRespostas?.deleteDuplicatedVariables || ""
  });

  useEffect(() => {
    setListagensOptions(listOptions);
    if (!authenticated) {
      navigate('/login');
    } else {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  async function CheckSheetsPermission() {

    setLoading(true);

    try {

      if (!respostas.title) formErros.title = "Campo obrigatório";
      if (!respostas.desc) formErros.desc = "Campo obrigatório";
      if (!respostas.url && respostas.formatSheets === "Google planilhas") formErros.url = "Campo obrigatório";
      if (!respostas.formatSheets) formErros.formatSheets = "Campo obrigatório";

      if (Object.keys(formErros).length > 0) {
        setCopyRespostas(respostas);
        setErrors(formErros);
        return;
      }

      const token = localStorage.getItem('jwt_token');

      if (respostas.formatSheets === "Google planilhas") {

        const response = await axios.get('https://api.123zap.com.br/webhook/sheets/google/check' + '?url=' + respostas.url, {
          headers: {
            'Authorization': token
          }
        });

        const items = Object.entries(response.data["item_example"]).map(([field, value]) => ({
          field,
          value
        })).filter((item) => item.field !== "row_number");

        if (items.length > 0) {
          setDataR(items);
          setTabValue(1);
          setErroLoading(false);
        } else {
          setErroLoading(true);
          formErros.url = "Link inválido";
          setErrors(formErros);
        }

      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);
      setLoading(false);
      setErroLoading(true);
      formErros.url = "Ocorreu um erro com o link";
      setErrors(formErros);
    } finally {
      setCopyRespostas(respostas);
      setErrors(formErros);
      setLoading(false);
    }

  }

  const handleInputChange = (name, value) => {
    setRespostas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px', 'padding': '15px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          variant="outlined"
          name="title"
          label="Digite um nome para identificar a listagem"
          value={respostas.title || ""}
          onChange={(e) => handleInputChange("title", e.target.value)}
          sx={{ background: errors.title ? '#F8D6D3' : respostas.title ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={errors.title}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.title && <FormHelperText style={{ color: "red" }}>{errors.title}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="desc"
          label="Digite uma descrição para a listagem"
          sx={{ background: errors.desc ? '#F8D6D3' : respostas.desc ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={respostas.desc || ""}
          onChange={(e) => handleInputChange("desc", e.target.value)}
          error={errors.desc}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.desc && <FormHelperText style={{ color: "red" }}>{errors.desc}</FormHelperText>}
      </FormControl>


      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="url"
          label="Digite ou cole aqui o link da sua planilha:"
          sx={{ background: errors.url ? '#F8D6D3' : respostas.url ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={respostas.url || ""}
          onChange={(e) => handleInputChange("url", e.target.value)}
          error={errors.url}
          fullWidth
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.url && <FormHelperText style={{ color: "red" }}>{errors.url}</FormHelperText>}
      </FormControl>
      <Button
        variant="contained"
        onClick={() => {
          setLoading(true);
          CheckSheetsPermission();
        }}
        fullWidth
        sx={{ padding: '15px', marginTop: '10px', fontSize: '16px' }}
        disabled={loading}
      >
        Carregar dados
      </Button>
    </Grid>
  );
}

function DataDisplayTable({ data, setData, handleSubmit, variablesOfTemplate, page, setPage }) {
  // State to control the form fields
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = useState({});

  // UseEffect para evitar a re-renderização das páginas
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const newInputValues = {};

    currentItems.forEach((item, i) => {
      const absoluteIndex = startIndex + i;
      newInputValues[absoluteIndex] = item.variable || '';
    })
    setValues(newInputValues);
  }, [data, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeSelect = (value, index) => {
    console.log(values, 'values');
    console.log(data, 'data');

    setValues({
      ...values,
      [index]: value === "Selecione" ? null : value
    });

    setData(prevData =>
      prevData.map((dataItem, dataIndex) =>
        dataIndex === index ? { ...dataItem, variable: value === "Selecione" ? null : value } : dataItem
      )
    );
  };

  const verifyIfVariableIsSelected = (variable) => {

    if (variable === 'WhatsApp' || variable === 'Email') {
      return false;
    }
    return data.some(item => item.variable === variable);
  }

  const renderSelects = () => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);

    return currentItems.map((item, index) => {
      const absoluteIndex = startIndex + index;
      return (
        <TableRow key={absoluteIndex}>
          <TableCell>{item.field}</TableCell>
          <TableCell>
            <Select
              labelId={"type_send_id" + index}
              id={"type_send" + index}
              value={values[absoluteIndex] ? values[absoluteIndex] : "Selecione"}
              onChange={(e) => {
                handleChangeSelect(e.target.value, absoluteIndex)
              }} // Passa o index para a função
              fullWidth
              style={{ boxShadow: 'none' }} // Remove o sombreamento do Select
              MenuProps={{
                PaperProps: {
                  style: { boxShadow: 'none', border: '1px solid #d3d3d3' }
                },
              }}
              size="small"
              sx={{ background: values[absoluteIndex] === "Selecione" || !values[absoluteIndex] ? '#f9f9f9' : '#f5f3ff', marginTop: '5px', borderRadius: '12px' }}
            >
              <MenuItem value="Selecione">Selecione</MenuItem>
              {Object.values(variablesOfTemplate).flat().map((value) => (
                <MenuItem key={value} value={value} disabled={verifyIfVariableIsSelected(value)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
      <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CAMPO</TableCell>
              <TableCell>REPRESENTA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSelects()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{ padding: '15px', marginTop: '10px', fontSize: '16px' }}>
        Enviar
      </Button>
    </Grid>
  );
}

function PageAddSheets() {

  // State to authenticate control
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  const creatingCampaignWithHelpMode = JSON.parse(sessionStorage.getItem("creating_campaign")) || null;

  // State to control the form fields
  const [copyRespostas, setCopyRespostas] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [countEmptyVariables, setCountEmptyVariables] = useState(0);
  const [dataR, setDataR] = useState([]);
  const [errors, setErrors] = useState({});
  const [fieldChannelSelected, setFieldChannelSelected] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [verifyEmptyVariables, setVerifyEmptyVariables] = useState(false);
  const [erroLoading, setErroLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [padronizationPage, setPadronizationPage] = useState(0);
  const [openModalCampaign, setOpenModalCampaign] = useState(false);

  const steps = [
    { desc: "Escolha um nome para identificar a listagem" },
    { desc: "Crie uma descrição para a listagem" },
    { desc: "Indique o formato da listagem" },
    { desc: "Cole o link da sua planilha google ou selecione a API" },
    { desc: 'Clique em "Carregar dados" para visualizar os campos da listagem' },
    { desc: "Para cada campo, selecione o que ele representa" }
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
      console.log("Pegando os dados")
    }
  }, [userInfo]);

  const fetchData = async () => {
    try {

      let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
      info = JSON.stringify(info);

      const translations = {
        "Personal_information": "Informações Pessoais",
        "Contact": "Contato",
        "Address": "Endereço",
        "Financial_References": "Referências Financeiras"
      };

      const resultJson = await GetVariables();

      console.log('resultJson', resultJson);

      const result = resultJson.variables.reduce((acc, item) => {
        const category = translations[item.category] || item.category;
        if (!acc[category]) {
          acc[category] = [];
        }

        acc[category].push(item.variableName);

        return acc;

      }, {});

      setVariablesOfTemplate(result);

    } catch (error) {
      console.error('Error fetching data from API:', error);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(variablesOfTemplate).length !== 0 && tabValue === 1) {
      setDataR((prev) =>
        prev.map((item) => {
          const normalize = (str) => str?.trim().toLowerCase();
          const findVariable = Object.values(variablesOfTemplate).flat().find((variable) => normalize(variable) === normalize(item.field));

          if (findVariable) {
            return {
              ...item,
              variable: findVariable
            }
          } else {
            return {
              ...item
            }
          }
        })
      );

    }
  }, [variablesOfTemplate, tabValue])

  function TabPanel(props) {

    const { children, value, index, ...other } = props;
    const [tabValue, setTabValue] = useState(0);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const preSubmit = () => {

    const newErrors = {};
    if (!copyRespostas.title) newErrors.title = "Campo obrigatório";
    if (!copyRespostas.desc) newErrors.desc = "Campo obrigatório";
    if (!copyRespostas.url && copyRespostas.formatSheets === "Google planilhas") newErrors.url = "Campo obrigatório";
    if (!copyRespostas.formatSheets) newErrors.formatSheets = "Campo obrigatório";
    if (!copyRespostas.deleteDuplicatedVariables) newErrors.deleteDuplicatedVariables = "Campo obrigatório";

    setErrors(newErrors);

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    const hasEmptyVariable = variables.some((item) => !item.variable);
    const countEmptyVariable = variables.filter((item) => !item.variable).length;

    setVerifyEmptyVariables(hasEmptyVariable);
    setCountEmptyVariables(countEmptyVariable);

    const variablesWhatsApp = variables.filter((item) => item.variable === 'WhatsApp');
    const variablesEmail = variables.filter((item) => item.variable === 'Email');

    if (variablesWhatsApp.length > 0 || variablesEmail.length > 0) {
      setFieldChannelSelected(true);
    } else {
      setFieldChannelSelected(false);
      setOpenModalConfirm(true);
      return false;
    }

    setOpenModalConfirm(true);

  }

  const handleSubmit = async () => {

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable === "Selecione" ? null : item.variable
      }
    });

    let data = { ...copyRespostas, variables, 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
    const response = await PostListagens(data);

    if (response.message === "success") {

      if (creatingCampaignWithHelpMode) {
        setOpenModalConfirm(false);
        setOpenModalCampaign(true);
      } else {
        navigate('/listagens');
      }
      notify("Success", "Listagem adicionada com sucesso!", true);

    } else {

      //alert('Erro ao enviar os dados');

    }

  };

  const handleModalConfirm = () => {
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  // useEffect(() => {
  //   // Simulate a API call
  //   setTimeout(() => setShowLoading(false), 1000);
  // }, []);

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      < HeaderPage title="Adicionar Listagem" />

      <Grid container spacing={4} sx={{ padding: { xs: 2.5, sm: 5 } }}>
        <Grid item xs={12} lg={6} sx={{ display: "flex", flexDirection: "column" }}>
          <HelpAdd Steps={steps} Title="Importe sua listagem" />
          {
            (copyRespostas.title === "" || copyRespostas.desc === "" || copyRespostas.formatSheets === "" || copyRespostas.url === "") && (
              <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
            )
          }
          {
            !fieldChannelSelected && dataR.length > 0 && (
              <AlertSnackbar message="WhatsApp ou Email não selecionado" severity="error" open={true} />
            )
          }
          {
            verifyEmptyVariables && (
              <AlertSnackbar message={`Existem ${countEmptyVariables} campos sem seleção`} severity="warning" open={true} />
            )
          }
          {
            erroLoading && (
              <AlertSnackbar message="Lembre-se de compartilhar a planilha google com o e-mail:<br>planilhas-n8n@projeto-brio-n8n.iam.gserviceaccount.com" severity="error" open={true} />
            )
          }
        </Grid>
        <Grid item xs={12} lg={6}  >
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Form and Standardization Tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
            <Tab label="Formulário" />
            <Tab label="Padronização" disabled={!copyRespostas.title || !copyRespostas.desc || !copyRespostas.formatSheets || !copyRespostas.url || erroLoading} />
          </Tabs>
          <TabPanel value={tabValue} index={0} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
            <SheetsForm setDataR={setDataR} copyRespostas={copyRespostas} setCopyRespostas={setCopyRespostas} setErrors={setErrors} errors={errors} setTabValue={setTabValue} setErroLoading={setErroLoading} uuidClient={userInfo['UUID']} />
          </TabPanel>
          <TabPanel value={tabValue} index={1} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
            <DataDisplayTable data={dataR} setData={setDataR} handleSubmit={preSubmit} variablesOfTemplate={variablesOfTemplate} page={padronizationPage} setPage={setPadronizationPage} />
          </TabPanel>
        </Grid>
      </Grid>
      <ModalR
        cancelText={!fieldChannelSelected ? "Voltar" : "Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={!fieldChannelSelected ? "" : "Sim, tenho certeza"}
        subTitle={!fieldChannelSelected ? "Selecione a coluna onde esteja os WhatsApps ou Emails em sua listagem"
          : "Você deseja finalizar a importação da listagem?"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
        title={!fieldChannelSelected ? "Alguns campos são obrigatórios"
          : verifyEmptyVariables ? `Existem ${countEmptyVariables} campos sem seleção` : "Finalizar importação"}
        emoji={verifyEmptyVariables ? "emoji04" : "emoji01"}
        iconColor={ThemeColors.purple400}
      />
      <CampaignModal
        cancelText="Adicionar mais uma listagem"
        confirmText="Retornar ao formulário"
        subTitle={"Percebemos que você está configurando uma campanha. Gostaria de retornar ao formulário?"}
        isOpen={openModalCampaign}
        onClose={() => setOpenModalCampaign(false)}
        onCancel={() => navigate("/listagens/adicionar")}
        onConfirm={() => (navigate("/campanhas/adicionar/modo-ajuda"), sessionStorage.setItem("ReturToCampaign", true))}
        title={"Listagem adicionada com sucesso"}
        iconColor={ThemeColors.purple400}
      />
    </>
  );
}

export default PageAddSheets;