// Desc: Formulário de Email para criação de novos modelos de email e também para visualização e edição de modelos de email já existentes.
// Update: 18/02/2025 (OK)

// React Components
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

// Material UI Components
import Button from '@mui/material/Button';
import { Grid, FormControl } from '@mui/material';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TemplateInput from '../../components/Input/TemplateInput';
import DynamicButton from '../../components/DynamicButton/DynamicButton';


// render the form of WhatsApp
const FormWhatsApp = ({ messageTemporaryWhatsApp, setMessageTemporary, variablesOfTemplate, setViewWhatsAppScreenProps, saveTemplate, viewWhatsAppScreenProps, edit = false }) => {

    const [imageBase64, setImageBase64] = useState('');

    // State to control the visibility of the variables
    const [expanded, setExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (imageBase64) {
            setViewWhatsAppScreenProps((prevProps) => {
                const newMessage = {
                    id: prevProps.messages.messages.length + 1,
                    type: 'img',
                    message: `${imageBase64}`,
                    fromMe: true
                };

                return {
                    ...prevProps,
                    messages: {
                        ...prevProps.messages,
                        messages: [...prevProps.messages.messages, newMessage]
                    }
                };
            });
        }
    }, [imageBase64, setViewWhatsAppScreenProps]);


    // Function to toggle the visibility of the variables
    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the change of the accordion
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Function to add a variable in the temporary message
    const addVariableInTemporaryMessage = (variable) => {
        setMessageTemporary(messageTemporaryWhatsApp + '{{' + variable + '}}');
    };

    //  Function to add a message
    function addMessage() {
        setViewWhatsAppScreenProps((prevProps) => {
            const newMessage = {
                id: prevProps.messages.messages.length + 1,
                type: 'whatsapp',
                message: messageTemporaryWhatsApp,
                fromMe: true
            };

            return {
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: [...prevProps.messages.messages, newMessage]
                }
            };
        });

        setMessageTemporary('');
    }

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImageBase64(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <TemplateInput type={'whatsapp'} value={messageTemporaryWhatsApp} handleChange={setMessageTemporary} variablesOfTemplate={variablesOfTemplate} />
                <Grid item xs={12}>
                    <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button style={{
                                        width: '100%', padding: '23px', background: 'white', color: 'black',
                                        border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray30, fontSize: '14px'
                                    }}
                                        startIcon={<AddPhotoAlternateIcon style={{ color: ThemeColors.gray30, fontSize: '22px' }} />}
                                    >
                                        Clique para adicionar uma imagem
                                    </Button>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Button style={{ width: '100%', padding: '13px' }} onClick={addMessage} variant="outlined"
                        >
                            Adicionar Mensagem
                        </Button>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ display: viewWhatsAppScreenProps.messages.messages.length > 0 ? 'block' : 'none' }}>
                        <DynamicButton handleFunction={saveTemplate} style={{ width: '100%', padding: '13px' }} variant={"contained"} text={edit ? "Editar Modelo" : "Salvar Modelo"} />
                    </FormControl>
                </Grid>
            </Grid >
        </>
    );
};

export default FormWhatsApp;