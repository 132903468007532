// This endpoint is used to set the channel configuration in the database.
// The response is an object with a message.
// Author: Vitor de Jesus

// Description: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136806402/Channels

import axios from 'axios';

export async function ChannelConfig(data) {
    try {

        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_AWS_URL}/channels/whatsapp/web/config`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'Client_ID': `${clientId}`   
            }
        });
        
        const responseData = await response.json();

        if (response.status === 200 && responseData.message === 'success') {

            responseData.status = 'OK';

        } else {

            responseData.status = 'ERROR';
        
        }

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}