// Description: This component render an Alert when the user do something
// Update: 28/02/2025

// React Components and DOM Elements
import React, { useEffect, useState } from 'react'

// Material UI Components
import { Box } from '@mui/material';

// Components and functions from the project
import themeColors from '../ThemeColors/ThemeColors'
import StatusIcon from '../StatusLabel/StatusIcon'

// Render Your Component
const CustomAlert = ({ type, message, right }) => {
  // State variables
  const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);
  
  // Effect to check if the sidebar is collapsed or not
  useEffect(() => {
    const mainContent = document.querySelector('.MainContent');
    if (mainContent) {
      setIsMainContentCollapsed(mainContent.classList.contains('collapsed'));
    }
  }, []);

  const renderColors = (type) => {
    switch(type) {
      case "Success": 
        return { color: themeColors.green03, background: themeColors.green01 }
      case "Alert":
        return { color: themeColors.yellow03, background: themeColors.yellow01 }
      case "Error":
        return { color: themeColors.red03, background: themeColors.red01 }
      case "Download":
        return { color: '#1E22AA', background: '#CBDFF6' }
      default: {
        return { color: '#000', background: '#FFF' }
      }
    }
  }

  return (
    <Box sx={{
      background: renderColors(type).background,
      color: renderColors(type).color,
      padding: '10px',
      borderRadius: '8px',
      fontFamily: 'Satoshi',
      maxWidth: '100vw',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      left: { xs: right === true ? 'unset' : '20px', sm: right === true ? 'unset' : isMainContentCollapsed ? '100px' : '320px' },
      right: { xs: right === true ? '20px' : 'unset', sm: right === true ? '40px' : 'unset' },
      position: 'fixed',
      bottom: '20px'
    }}>
      <StatusIcon status={'Alert'} sx={{ marginLeft: '10px' }} width={'16px'} height={'16px'} color={renderColors(type).color} />
      {message}
    </Box>
  )
}

export default CustomAlert