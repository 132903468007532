import axios from 'axios';

// Request to save campaign data to the backend
// Update in 10/02/2025

export async function CampaignSave(data) {
  try {
    const token = localStorage.getItem('jwt_token');
    const clientId = localStorage.getItem('idToken');

    if (!token) throw new Error('Token de autenticação não encontrado');

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_AWS_URL}/campaigns/schedule/add`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Client_ID': `${clientId}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error saving campaign data:', error);
    throw new Error('Error saving campaign data');
  }
}
