// This endpoint is used to update the user's notification status.
// The data is fetched from the backend API using an axios POST request.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/135593992/Dashboard

import axios from 'axios';

export async function ReadN(data) {
    try {
        
        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if(!token) throw new Error('Token de autenticação não encontrado');

        if(!clientId) throw new Error('ID do cliente não encontrado');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/painel/notification/read`, 
            data,
            { 
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,  // Envia o token no cabeçalho Authorization
                    'Client_ID': `${clientId}`
                },
            }
        );
        
        return response.data;

    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
