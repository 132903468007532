// Desc: Formulário de Email para criação de novos modelos de email e também para visualização e edição de modelos de email já existentes.
// Update: 17/02/2025 (OK)

// React Components
import React, { useEffect, useState } from 'react';

// Material UI Components
import { Grid, FormControl, Button } from '@mui/material';
import TemplateInput from '../../components/Input/TemplateInput';
import DynamicButton from '../../components/DynamicButton/DynamicButton';
import { notify } from '../../utils/utils';


// render the form of SMS
const FormSMS = ({ messageTemporary, setMessageTemporary, variablesOfTemplate, setViewWhatsAppScreenProps, saveTemplate, edit = false }) => {

    const [imageBase64, setImageBase64] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (imageBase64) {
            setViewWhatsAppScreenProps((prevProps) => {
                const newMessage = {
                    id: prevProps.messages.messages.length + 1,
                    type: 'img',
                    message: `${imageBase64}`,
                    fromMe: true
                };

                return {
                    ...prevProps,
                    messages: {
                        ...prevProps.messages,
                        messages: [...prevProps.messages.messages, newMessage]
                    }
                };
            });
        }
    }, [imageBase64, setViewWhatsAppScreenProps]);

    const onSubmit = async () => {
        if (messageTemporary.length) {
            setLoading(true);

            try {
                await saveTemplate();
            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
                throw new Error('Erro ao buscar dados da API');
            } finally {
                setLoading(false);
            }
            
        } else {
            notify("Error", "Escreva uma mensagem antes de salvar o modelo", true);
        }
    }

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <TemplateInput handleChange={setMessageTemporary} type={'sms'} value={messageTemporary} variablesOfTemplate={variablesOfTemplate} />

                <Grid item xs={6}>

                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Button onClick={onSubmit} disabled={loading} style={{ width: '100%', padding: '13px' }} variant={"contained"} children={edit ? "Editar Modelo" : "Salvar Modelo"} />
                    </FormControl>
                </Grid>
            </Grid >
        </>
    );
};

export default FormSMS;