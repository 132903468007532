// This component is responsible for rendering the Channel page, where the user can see all the channels that are connected to the platform, and can also connect, disconnect, and delete them. The user can also add a new channel by clicking on the "Adicionar Canal" button. The user can also search for a specific channel by typing the name of the channel in the search bar. The user can also see the number of connected and disconnected channels in the cards at the top of the page. The user can also see the status of the channel, the type of message, and the integration icons of the channel in the table. The user can also edit the channel by clicking on the edit icon, connect the channel by clicking on the connect icon, disconnect the channel by clicking on the disconnect icon, and delete the channel by clicking on the delete icon. The user can also reconnect the channel by clicking on the reconnect icon. The user can also see a message if there are no channels connected to the platform.
// Used to connect, disconnect, delete, and reconnect channels, and to get the channels connected to the platform.
// Author: Vitor de Jesus

// Documentations:
// https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137035778/Como+pausar+o+WhatsApp
// https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136773644/Como+iniciar+o+WhatsApp
// https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137363457/Como+adicionar+WhatsApp
// https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137396228/Como+configurar+o+WhatsApp
// https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137789442/Como+remover+WhatsApp+da+plataforma

import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

import { Channels } from '../../api/backend/Channels/Channels';
import { ChannelDisconnect } from '../../api/backend/Channels/ChannelDisconnect';
import { ChannelConnect } from '../../api/backend/Channels/ChannelConnect';
import { ChannelRemoved } from '../../api/backend/Channels/ChannelRemoved';
import DynamicTable from '../../components/Tables/DynamicTable';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { isAuthenticated } from '../../authMiddleware';
import ModalR from '../../components/Modals/ModalR';
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';

function ChannelPage() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    let Navigate = useNavigate();

    const [openModalR, setOpenModalR] = useState(false);
    const [optionSelected, setOptionSelected] = useState('');

    const [loading, setLoading] = useState(true);

    const [selectedConexao, setSelectedConexao] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const [itemsToShow, setItemsToShow] = useState([]);

    const [dataCards, setDataCards] = useState([
        { title: 'WhatsApps Conectados', dataKey: 'conectados', qtd: 0, icon: null },
        { title: 'WhatsApps Desconectados', dataKey: 'desconectados', qtd: 0, icon: null }
    ]);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            fetchData();
        }
    }, [userInfo])

    const handleOpenModalView = (item) => {
        setSelectedConexao(item);
        Navigate('/canais/configurações', { state: { selectedItem: item } });
    };

    const formatPhoneNumber = (phoneNumber) => {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (numericPhoneNumber.length < 11) {
            return phoneNumber;
        }
        const countryCode = numericPhoneNumber.substring(0, 2);
        const areaCode = numericPhoneNumber.substring(2, 4);
        const firstPart = numericPhoneNumber.substring(4, 8);
        const secondPart = numericPhoneNumber.substring(8);
        return `+${countryCode} (${areaCode}) 9 ${firstPart}-${secondPart}`;
    };

    const formatFixedPhoneNumber = (phoneNumber) => {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (numericPhoneNumber.length < 10) {
            return phoneNumber;
        }
        const countryCode = numericPhoneNumber.substring(0, 2);
        const areaCode = numericPhoneNumber.substring(2, 4);
        const firstPart = numericPhoneNumber.substring(4, 8);
        const secondPart = numericPhoneNumber.substring(8);
        return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
    };

    const verifyPhoneNumber = (phoneNumber) => {
        const phoneNumberArray = phoneNumber.split('');
        if (phoneNumberArray[4] === '3') {
            return formatFixedPhoneNumber(phoneNumber);
        } else {
            return formatPhoneNumber(phoneNumber);
        }
    }

    const fetchData = async () => {

        try {
            setLoading(true);

            let resultJson = await Channels();

            resultJson = resultJson.channels;

            console.log('resultJson:', resultJson);

            const instancesToShow = resultJson.map((instance) => ({
                instance_name: instance.code,
                number: verifyPhoneNumber(instance.channel_identifier),
                name: instance.channel_name,
                status: instance.status === 'open' ? 'Conectado' : instance.status === 'paused' ? 'Pausado' : 'WAIT' ? 'Verificando' : 'Indisponível',
                type_msg: instance.type,
                integration_icons: instance.integration
            }));

            console.log('instancesToShow:', instancesToShow);
            setItemsToShow(instancesToShow);
        } catch (error) {
            console.error('Erro ao carregar dados:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleConnect = async () => {

        try {
            const code = selectedConexao.instance_name;
            const resultJson = await ChannelConnect(code);
            if (resultJson.message === 'success') {
                setTimeout(() => {
                    fetchData();
                }, 1000);
            }
        } catch (error) {
            console.error('Erro ao conectar instância:', error);
        } finally {
            setOpenModalR(false);
        }
    };

    const handleDelete = async () => {
        try {
            const code = selectedConexao.instance_name;
            const resultJson = await ChannelRemoved(code);

            if (resultJson.message === 'sucess') {
                setTimeout(() => {
                    fetchData();
                }, 1000);
            }
        } catch (error) {
            console.error('Erro ao excluir instância:', error);
        } finally {
            setOpenModalR(false);
        }
    };

    const handleOpenModalAdd = () => {
        Navigate('/canais/adicionar');
    };

    const handleOpenReconnect = () => {
        Navigate('/canais/adicionar/whatsapp');
    };

    const handleDisconnect = async () => {
        try {
            const code = selectedConexao.instance_name;
            const resultJson = await ChannelDisconnect(code);

            if (resultJson.message === 'success') {

                setTimeout(() => {
                    fetchData();
                }, 1000);
            }
        } catch (error) {
            console.error('Erro ao desconectar instância:', error);
        } finally {
            setOpenModalR(false);
        }
    };

    const handleCloseModalConfirm = () => {
        setOpenModalR(false);
    };

    const OpenModalConfirm = (option) => {
        setOptionSelected(option);
        setOpenModalR(true);
    };

    return (

        <Fragment>
            <Box width={"100%"} height={"100vh"}>

                <HeaderPage title="Canais" />
                {
                    loading === false && itemsToShow.length === 0 && (
                        <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                            <Box sx={{ width: { xs: "80%", sm: "auto" } }}>
                                <Box component="img" src={EmptyTemplate} alt="Você não tem modelos" sx={{ width: { xs: "247px", sm: "494px" }, height: { xs: "165px", sm: "330px" } }} />
                                <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                                    <Titles bold type={"H3"} children={"Parece que você não tem canais :("} color={"#8033F4"} />
                                    <Titles type={"P1"} children={"Adicione um agora mesmo, é fácil e rápido"} />

                                    <Button
                                        variant='contained'
                                        style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                                        onClick={handleOpenModalAdd}
                                    >
                                        Adicionar Canal
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    ) || (
                        <DynamicTable
                            title="Listagem de Templates"
                            data={itemsToShow}
                            columns={[
                                { header: '', field: 'integration_icons', render: (integration) => <StatusIcon status={integration} /> },
                                { header: 'Nome', field: 'name' },
                                { header: 'Saida', field: 'number' },
                                { header: 'Tipo de Mensagem', field: 'type_msg', render: (type_msg) => <StatusLabel status={type_msg} usingOnTable />, filter: true },
                                { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },

                            ]}

                            actions={[
                                {
                                    icon: <StatusIcon status={'Edit'} color={ThemeColors.gray40} width={20} height={20} />,
                                    tooltip: 'Editar',
                                    action: (item) => handleOpenModalView(item),
                                    isVisible: (item) => item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'WhatsApp WEB'
                                },
                                {
                                    icon: <StatusIcon status={'Power'} color={ThemeColors.green02} width={20} height={20} />,
                                    tooltip: 'Iniciar',
                                    action: (item) => {
                                        OpenModalConfirm('connect');
                                        setSelectedConexao(item);
                                    },
                                    isVisible: (item) => item && item.status === 'Pausado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'WhatsApp WEB' && item.status !== 'Conectado' && item.status !== 'Indisponível',
                                },
                                {
                                    icon: <StatusIcon status={'PowerOff'} color={ThemeColors.red02} width={20} height={20} />,
                                    tooltip: 'Pausar',
                                    action: (item) => {
                                        OpenModalConfirm('disconnect');
                                        setSelectedConexao(item);
                                    },
                                    isVisible: (item) => item && item.status === 'Conectado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'WhatsApp WEB' && item.status !== 'Pausado' && item.status !== 'Indisponível',
                                },
                                {
                                    icon: <StatusIcon status={'Electrical'} color={ThemeColors.green02} width={20} height={20} />,
                                    tooltip: 'Reconectar',
                                    action: (item) => {
                                        OpenModalConfirm('reconnect');
                                        setSelectedConexao(item);
                                    },
                                    isVisible: (item) => item && item.status === 'Indisponível' && (item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'WhatsApp WEB')
                                },
                                {
                                    icon: <StatusIcon status={'Delete'} color={ThemeColors.red02} width={20} height={20} />,
                                    tooltip: 'Excluir',
                                    action: (item) => {
                                        OpenModalConfirm('delete')
                                        setSelectedConexao(item)
                                    },
                                    isVisible: (item) => item && item.status === 'Pausado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'WhatsApp WEB' && item.status !== 'Conectado'
                                }
                            ]}


                            button={{ icon: <PlusIcon />, tooltip: 'Adicionar Canal ', action: handleOpenModalAdd }
                            }

                            activeSearch={true}
                            titleSearch={'Pesquisar Canal'}
                            titleLabel={'Canais por página'}
                            loading={loading}
                        />

                    )
                }

            </Box>

            <ModalR
                cancelText={"Mudei de ideia"}
                color={ThemeColors.purple500}
                confirmText={optionSelected === 'reconnect' ? "Sim, desejo reconectar" : optionSelected === 'connect' ? "Sim, desejo iniciar" : optionSelected === 'disconnect' ? "Sim, desejo pausar" : "Sim, desejo excluir"}
                subTitle={optionSelected === 'reconnect' ? "Você será redirecionado para a página de reconexão" : optionSelected === 'connect' ? "Seu canal voltará a ficar disponível para uso." : optionSelected === 'disconnect' ? "Seu canal será pausado e não estará disponível para uso." : "Seu canal será excluído e não estará disponível para uso."}
                onClose={handleCloseModalConfirm}
                isOpen={openModalR}
                onConfirm={optionSelected === 'reconnect' ? handleOpenReconnect : optionSelected === 'connect' ? handleConnect : optionSelected === 'disconnect' ? handleDisconnect : optionSelected === 'delete' ? handleDelete : console.log('')}
                title={optionSelected === 'reconnect' ? "Deseja realmente reconectar?" : optionSelected === 'connect' ? "Deseja realmente conectar?" : optionSelected === 'disconnect' ? "Deseja realmente desconectar?" : optionSelected === 'delete' ? "Deseja realmente excluir?" : "Deseja realmente excluir?"}
                emoji={optionSelected === 'disconnect' ? 'emoji10' : optionSelected === 'connect' ? 'emoji02' : optionSelected === 'reconnect' ? 'emoji02' : 'emoji10'}
                iconColor={ThemeColors.purple400}
            />

        </Fragment>

    );
}

export default ChannelPage;