// Description: Render a Dynamic Input that will appear in the Dynamic Form
// Updated: 17/02/2025
// Author: Simon Franklin

// React
import React, { useEffect, useState } from "react";

// Material UI Components
import {
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import themeColors from "../ThemeColors/ThemeColors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt-BR";
import LockIcon from "@mui/icons-material/Lock";

const DynamicInput = ({
  title,
  value,
  type,
  fullWidth,
  updateFieldValue,
  formState,
  options = null,
}) => {

  const [minTime, setMinTime] = useState(null);

  const handleTimeChange = (value, field) => {
    if (value && value < minTime) {
      updateFieldValue(field, value);
    } else {
      updateFieldValue(field, value);
    }
  };

  useEffect(() => {
    const now = new Date();
    const startDay = new Date(formState["Data de início"]);

    if (
      startDay.getFullYear() > now.getFullYear() ||
      startDay.getMonth() > now.getMonth() ||
      startDay.getDate() > now.getDate()
    ) {
      setMinTime(null);
    } else {
      const minimumTime = new Date(now.getTime() + 65 * 60000);

      minimumTime.setMinutes(0);
      minimumTime.setSeconds(0);
      minimumTime.setMilliseconds(0);

      setMinTime(minimumTime);
    }
  }, [formState["Data de início"]]);

  const startDateTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#F9F9F9",
            color: themeColors.gray30,
            borderRadius: "6px",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: themeColors.purple400,
            borderRadius: "2px",
            borderWidth: "1px",
            border: "1px solid",
            backgroundColor: "#FFF",
            "&:hover": {
              backgroundColor: themeColors.purple400,
              color: "#FFF",
            },
            "&.Mui-selected": {
              backgroundColor: themeColors.purple400 + " !important",
              color: "#FFF !important",
            },
          },
        },
      },
    },
  });

  const startTimeTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#F9F9F9",
            borderRadius: "6px",
            color: themeColors.gray30,
          },
        },
      },
      MuiPickersClock: {
        styleOverrides: {
          clock: {
            backgroundColor: "#FFF",
            borderRadius: "12px",
            border: "1px solid #DBDBDB",
            padding: "10px",
          },
          pin: {
            backgroundColor: themeColors.purple400,
          },
        },
      },
    },
  });

  const renderInput = () => {
    switch (type) {
      case "textarea":
        return (
          <TextField
            name={title}
            variant="outlined"
            placeholder={title}
            value={value === false ? "" : value}
            onChange={(e) => updateFieldValue(title, e.target.value)}
            rows={4}
            sx={{ background: '#F9F9F9', borderRadius: '6px', '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px' } }}
          />
        );
      case "date":
        return (
          <ThemeProvider theme={startDateTheme}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DesktopDatePicker
                sx={{
                  '.css-12qs7hp-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '13px',
                    paddingRight: '0px'
                  }
                }}
                name={title}
                label={title}
                inputFormat="DD/MM/YYYY"
                value={value ? dayjs(value) : null}
                onChange={(newValue) => (updateFieldValue(title, newValue.toISOString()), title === "Data de início" ? updateFieldValue("Horário de início", null) : null)}
                minDate={dayjs()}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      case "time":
        return (
          <ThemeProvider theme={startTimeTheme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <TimePicker
                sx={{ '.css-12qs7hp-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px', paddingRight: '0px' } }}
                name={title}
                label={title}
                ampm={false}
                minutesStep={60}
                value={value}
                onChange={(newValue) => handleTimeChange(newValue, title)}
                minTime={minTime}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      case "select":
        return (
          <>
            <InputLabel style={{ color: themeColors.gray30 }}>
              {title}
            </InputLabel>
            <Select
              name={title}
              sx={{
                background: "#f9f9f9",
                borderRadius: "6px",
                minWidth: "100%",
                ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "13px",
                },
              }}
              onChange={(e) => updateFieldValue(title, e.target.value)}
              value={value}
            >
              {
                options &&
                  title === "Adicionar Listagem" ? (
                  options.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {(option.title + ': ' + option.desc).substring(0, 30) + '...'}
                    </MenuItem>
                  ))
                ) : (
                  options.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))
                )
              }
            </Select>
          </>
        );
      case "block":
        return (
          <TextField
            placeholder={`${title} (Bloqueado)`}
            id={title}
            variant="outlined"
            fullWidth
            sx={{ background: '#F9F9F9', borderRadius: '6px', '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px' } }}
            autoComplete='off'
            disabled={true}
            InputProps={{
              endAdornment: (
                <LockIcon style={{ color: themeColors.gray30 }} />
              ),
            }}
          />
        )
      default:
        return (
          <TextField
            name={title}
            variant="outlined"
            placeholder={title}
            value={value === false ? "" : value}
            onChange={(e) => updateFieldValue(title, e.target.value)}
            fullWidth={fullWidth}
            rows={1}
            sx={{ background: '#F9F9F9', borderRadius: '6px', '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px' } }}
          />
        );
    }
  };

  return (
    <FormControl sx={{ width: fullWidth ? "100%" : "calc(50% - 9px)" }}>
      {renderInput()}
    </FormControl>
  );
};

export default DynamicInput;