import { useState, useMemo, useEffect } from "react";
import { notify } from "../../utils/utils";

const useDynamicForm = (initialFields, steps) => {

  const initializeFormState = () => {
    const savedState = JSON.parse(sessionStorage.getItem("formState"));
    if (savedState) {
      return Object.keys(savedState).reduce((acc, fieldName) => {
        const value = savedState[fieldName];

        const dateFields = [
          "Data de início",
          "Data de término",
          "Horário de início"
        ];

        if (dateFields.includes(fieldName)) {
          const dateValue = new Date(value);
          acc[fieldName] = isNaN(dateValue.getTime()) ? value : dateValue;
        } else {
          acc[fieldName] = value;
        }

        return acc;
      }, {});
    }

    return initialFields.reduce((acc, field) => {
      acc[field.fieldName] = field.type === "date" || field.type === "time" ? null : field.value || "";
      return acc;
    }, {});
  };

  const [formState, setFormState] = useState(initializeFormState());

  const [currentStep, setCurrentStep] = useState(parseInt(sessionStorage.getItem("currentStep"), 10) || 1);

  const [stepData, setStepData] = useState(() => {
    const currentStepData = steps.find((step) => step.stepNumber === currentStep);
    return currentStepData;
  })

  useEffect(() => {
    const currentStepData = steps.find((step) => step.stepNumber === currentStep);
    setStepData(currentStepData ? currentStepData : null);
  }, [currentStep, steps]);


  const fields = useMemo(
    () => initialFields.filter((field) => field.step === currentStep),
    [currentStep, initialFields]
  );

  const updateFieldValue = (name, value) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const canProceedToNextStep = (formState, fields) => {
    return fields.every((field) => {
      const value = formState[field.fieldName];
      return value !== null && value !== undefined && value !== '';
    });
  };

  const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, steps.length));
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 1));

  const handleNextStep = () => {
    if (canProceedToNextStep(formState, fields)) {
      nextStep();
    } else {
      notify("Error", "Por favor, preencha todos os campos obrigatórios.", true);
    }
  };

  return {
    formState,
    currentStep,
    fields,
    stepData,
    updateFieldValue,
    handleNextStep,
    prevStep,
    setFormState
  };
};

export default useDynamicForm;