import React, { useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import Titles from '../../components/Titles/Titles';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const Comprehend = ({ sentiments, totalMessages, loading }) => {

    const colorMappingSentiments = {
        'Positive': {
            iconColor: '#4C8435',
            backgroundColor: '#D3EAC8',
            textColor: '#345427',
        },
        'Negative': {
            iconColor: '#CD4747',
            backgroundColor: ThemeColors.red01,
            textColor: '#4E1B1D',
        },
        'Neutral': {
            iconColor: '#8142FC',
            backgroundColor: '#DDD4FF',
            textColor: '#4F189A',
        },
        'Mixed': {
            iconColor: '#848484',
            backgroundColor: '#E5E5E5',
            textColor: '#303035',
        }
    };

    const hexToRgb = (hex) => {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
            hex = hex.split('').map(x => x + x).join('');
        }
        const num = parseInt(hex, 16);
        return `${(num >> 16) & 255}, ${(num >> 8) & 255}, ${num & 255}`;
    };

    return (
        <>
            <Box
                flex={1}
                borderRadius={'12px'}
                border={'1px solid #D9D9DC'}
                padding={'30px'}
                sx={{
                    background: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '25px',
                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                }}
            >
                <Typography sx={{ color: '#8033F4', fontWeight: 'bold', fontSize: { sm: '24px', xs: '20px' } }}>
                    Análise Geral de Sentimentos <Typography component="span" style={{ fontWeight: 'normal', fontSize: '20px' }}> ( Clientes )</Typography>
                </Typography>
                <Box display="flex" flexDirection="column" width="100%" height="90%" alignItems="lefts">

                    {!loading && sentiments && Object.keys(sentiments).map((key, index) => (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding="0 10px"
                            key={index}
                            sx={{
                                background: colorMappingSentiments[key].backgroundColor,
                                height: '32px',
                                borderRadius: '6px',
                                width: `100%`,
                                marginBottom: '10px',
                                minWidth: '100%',
                            }}
                        >
                            <Box display="flex" gap="7px" alignItems="center" flex="1">
                                <StatusIcon status="Alert" color={colorMappingSentiments[key].iconColor} width="12px" height="12px" />
                                <Titles children={key === 'Mixed' ? 'Sentimentos mistos' : key === 'Neutral' ? 'Sentimentos neutros' : key === 'Positive' ? 'Sentimentos positivos' : 'Sentimentos negativos'} color={colorMappingSentiments[key].textColor} type="P2" />
                                <Titles children={`: ${sentiments[key].toFixed(2)}%`} color={colorMappingSentiments[key].textColor} type="P2" />
                            </Box>
                        </Box>
                    ))}

                    {loading &&
                        Object.keys(colorMappingSentiments).map((key, index) => (
                            <Box
                                display="flex"
                                gap="7px"
                                alignItems="center"
                                flex="1"
                                sx={{marginBottom: '10px'}}
                            >
                                <LinearProgress
                                    sx={{
                                        height: '32px',
                                        width: '100%',
                                        borderRadius: '6px',
                                        minWidth: '200px',
                                        backgroundColor: `rgba(${hexToRgb(colorMappingSentiments[key].backgroundColor)}, 0.95)`,
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: colorMappingSentiments[key].backgroundColor,
                                        }
                                    }}
                                />
                            </Box>

                        ))
                    }
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                    {
                        loading ?
                            <LinearProgress
                                sx={{
                                    height: '10px',
                                    borderRadius: '6px',
                                    minWidth: '200px',
                                    backgroundColor: `rgba(${hexToRgb(ThemeColors.gray20)}, 0.8)`,
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: ThemeColors.gray20
                                    },
                                }}
                            />
                            :
                            <Titles children={`${totalMessages} mensagens analisadas`} color={ThemeColors.gray60} type="P2" />
                    }
                </Box>
            </Box>
        </>
    );
};

export default Comprehend;
