// Description: Component that render an tutorial for the new Users
// Updated: 20/02/2025
// Author: Simon Franklin

// React
import React, { useState } from 'react';

// Material UI Components
import { Accordion, AccordionDetails, Box, Typography, AccordionSummary } from '@mui/material';

// Components and Functions for the project
import HelpAdd from '../../components/Cards/HelpAdd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import themeColors from '../../components/ThemeColors/ThemeColors';

// Render The Component
const TutorialComponent = ({ tutorialData, selectedTutorial, setSelectedTutorial }) => {
    const [expandedTitle, setExpandedTitle] = useState(
        selectedTutorial.tutorial.length > 0 ? selectedTutorial.tutorial[0].title : null
    );

    const handleAccordionChange = (index) => {
        setExpandedTitle(expandedTitle === index ? null : index);
    };

    return (
        <>
            <Box sx={{
                display: { xs: 'none', sm: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '20px',
                paddingTop: '20px',
                gap: 2,
            }}>
                {tutorialData.map((item, index) => (
                    <Box key={index} onClick={() => setSelectedTutorial(item)} sx={{ cursor: 'pointer', backgroundColor: selectedTutorial === item ? themeColors.purple100 : '#fff', padding: '10px', borderRadius: '10px', flex: 1, maxWidth: { xs: '140px', sm: 'calc(33.33% - 20px)' }, minWidth: { xs: '140px', sm: 'none' }, border: selectedTutorial === item ? `1px solid ${themeColors.purple100}` : '1px solid #D9D9DC', display: 'flex', alignItems: 'center' }}>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box sx={{
                                minWidth: { xl: '50px', lg: '50px', xs: '40px' },
                                minHeight: { xl: '50px', lg: '50px', xs: '40px' },
                                borderRadius: '10px',
                                background: selectedTutorial === item ? themeColors.purple200 : "#E5E5E5",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 900,
                                padding: '15px',
                                color: selectedTutorial === item ? themeColors.purple500 : "#666673"
                            }}>
                                0{index + 1}
                            </Box>
                            <Box sx={{ color: selectedTutorial === item ? themeColors.purple500 : "#345427", fontWeight: 500, fontSize: '14px' }}>
                                {item.cardTitle}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Box sx={{ flex: 1, backgroundColor: '#fff', minHeight: '400px', display: 'flex', flexDirection: 'column', gap: '50px', margin: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '36px' }}>
                    {
                        !selectedTutorial ? (
                            <>
                                <Box sx={{ background: '#F5F3FF', width: '100%', borderRadius: '6px', padding: '20px' }}>
                                    <Typography sx={{ fontSize: { xs: '16px', sm: '20px' }, color: '#000' }}>
                                        {selectedTutorial.text}
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                {selectedTutorial.tutorial.map((item) => (
                                    <Accordion
                                        key={item.title}
                                        expanded={expandedTitle === item.title}
                                        onChange={() => handleAccordionChange(item.title)}
                                        sx={{
                                            boxShadow: 'none',
                                            background: expandedTitle === item.title ? '#F5F3FF' : '#FFFFFF',
                                            '.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root': {
                                                padding: '0px 27px 0px 35px',
                                            },
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls={`panel-${item.title}-content`}
                                            id={`panel-${item.title}-header`}
                                        >
                                            <Typography
                                                component="span"
                                                sx={{ fontSize: { xs: '16px', sm: '24px' }, color: expandedTitle === item.title ? '#4F189A' : '#7B25FF' }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <HelpAdd Steps={item.data} />
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                        )}
                </Box>
            </Box>
        </>

    )
}

export default TutorialComponent