export async function TemplateDelete(templateId) {
    try {

        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/templates/delete`,  
            {
                method: "DELETE",
                body: JSON.stringify(templateId),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}