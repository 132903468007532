// Description: Navbar that will be rendered in mobile version of the application
// Updated: 02/12/2024
// Author: Simon Franklin

// React
import React, { useEffect, useState } from 'react';

// Material UI Components
import { Box } from '@mui/material';

// Components and functions for the project
import Logo from "../../assets/ilustrations/Logo";
import HamburguerMenu from '../../assets/Icons/components/HamburguerMenu';
import Close from '../../assets/Icons/components/Close';
import Bell from '../../assets/Icons/components/Bell';
import NavigationMenu from './NavigationMenu';
import Notifications from './MobileNotifications';
import { isAuthenticated } from '../../authMiddleware';
import { DashboardData } from '../../api/backend/Dashboard/DashboardData';
import { colorMapping } from '../../utils/utils';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// Render the Component
const MobileNavbar = () => {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [allNotifications, setAllNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);
    const [currentNotifications, setCurrentNotifications] = useState([]);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        async function fetchDataWrapper() {
            if (userInfo && userInfo['UUID']) {

                try {

                    const data = await DashboardData();

                    const jsonData = data;

                    const mappedNotifications = jsonData['notifications'].map((notification) => ({
                        id: notification.id,
                        text: notification.text,
                        ...colorMapping[notification.colormapping],
                        date: notification.timestamp.split(' ')[0],
                        read: notification.read
                    }));

                    setAllNotifications(mappedNotifications);

                    const mappedNewNotifications = jsonData['notifications']
                        .filter((notification) => !notification.read)
                        .map((notification) => ({
                            id: notification.id,
                            text: notification.text,
                            ...colorMapping[notification.colormapping],
                            date: notification.timestamp.split(' ')[0],
                            read: notification.read
                        }));

                    setNewNotifications(mappedNewNotifications);

                    setCurrentNotifications(mappedNewNotifications.length > 0 ? mappedNewNotifications : mappedNotifications);

                } catch (error) {
                    console.log(error.message)
                }
            }
        }
        fetchDataWrapper();
    }, [userInfo]);

    return (
        <>
            <Box sx={{ padding: '24px', display: 'flex', justifyContent: 'space-between', position: 'fixed', width: '100%', top: '0', left: '0', background: 'white', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', zIndex: '20' }}>
                <Logo />

                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div onClick={() => setOpenNotifications((prev) => !prev)} style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '-5px', right: '-5px', background: '#F00202', width: '19px', height: '19px', borderRadius: '50%', color: '#fff', display: newNotifications.length > 0 ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                            {newNotifications.length > 0 && newNotifications.length}
                        </div>
                        {
                            openNotifications ? <Close  sx={{ color: '#52525B' , fontSize: '30px' }} /> : <CircleNotificationsIcon sx={{ color: '#52525B' , fontSize: '30px' }} />
                        }
                    </div>
                    <div onClick={() => setOpenMenu((prev) => !prev)}>
                        <MenuOpenIcon sx={{ color: '#52525B' , fontSize: '35px' }} />
                    </div>
                </Box>
            </Box>
            <NavigationMenu open={openMenu} setOpen={setOpenMenu} />
            <Notifications open={openNotifications} currentNotifications={currentNotifications} allNotifications={allNotifications} newNotifications={newNotifications} setCurrentNotifications={setCurrentNotifications} setAllNotifications={setAllNotifications} setNewNotifications={setNewNotifications} />
        </>
    )
}

export default MobileNavbar