// Description: Dynamic Form That render the current step and fields
// Updated: 17/02/2025
// Author: Simon Franklin

// React
import React from 'react'

// Material Ui
import { Box, Typography } from '@mui/material'

// Components and functions for the project
import StepIndicator from './StepIndicator';
import DynamicFooter from './DynamicFooter';
import DynamicFields from './DynamicFields';
import { useNavigate } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";

// Render the current step
const HelpDynamicForm = ({ steps, currentStep, nextStep, prevStep, fields, formState, title, description, updateFieldValue, stepData, loading, initialFields, finishing }) => {
    const navigate = useNavigate();

    const saveFormStateToSessionStorage = (state) => {
        const validState = Object.keys(state).reduce((acc, key) => {
            const dateFields = [
                "Data de início",
                "Data de término",
                "Horário de início"
            ];

            if (dateFields.includes(key) && state[key] instanceof Date) {
                acc[key] = state[key].toISOString();
            } else {
                acc[key] = state[key];
            }
            return acc;
        }, {});

        sessionStorage.setItem("formState", JSON.stringify(validState));
    };

    const saveDataBeforeRedirect = () => {
        sessionStorage.setItem("creating_campaign", true);
        sessionStorage.setItem("currentStep", currentStep);
        sessionStorage.setItem("initial_fields", JSON.stringify(initialFields));
        saveFormStateToSessionStorage(formState);
    };

    return (
        <Box sx={{
            minWidth: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column', flex: 1, paddingTop: { xs: '0', sm: '25px' }, gap: '25px', justifyContent: 'space-between', backgroundColor: { xs: '#FFF', sm: 'none' },
            background: 'rgb(128,51,244)',
            background: 'radial-gradient(circle, rgba(124,41,249,1) 54%, rgba(128,51,244,1) 100%)',
        }}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: { xs: 'start', sm: 'center' }, height: '100%', width: '100%' }}>
                <Box sx={{ width: { xs: '100vw', sm: '700px' }, height: { xs: 'calc(100vh - 162px)', sm: '585px' }, borderRadius: { xs: '0px', sm: '12px' }, background: '#FFF', padding: '40px', display: 'flex', flexDirection: 'column', boxShadow: { xs: 'none', sm: '0px 4px 6px rgba(0, 0, 0, 0.25)' }, alignItems: 'center', justifyContent: 'center' }}>
                    {
                        loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <StepIndicator currentStep={currentStep} steps={steps} />
                                <Box sx={{ width: '100%', marginTop: "15px", marginBottom: "15px", border: "1.5px solid #D7D7D7" }} />
                                <Typography sx={{ fontSize: { xs: '18px', sm: '24px' }, fontWeight: 600, color: '#000', marginBottom: '15px' }}>
                                    {title}
                                </Typography>
                                <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#848484', marginBottom: '19px', textAlign: 'center' }}>
                                    {description}
                                </Typography>

                                <DynamicFields fields={fields} updateFieldValue={updateFieldValue} formState={formState} />

                                {
                                    stepData.redirect && (
                                        <Typography onClick={() => (saveDataBeforeRedirect(), navigate(stepData.redirectPath))} sx={{ fontSize: { xs: '13px', sm: '18px' }, color: '#8142FC', margin: 'auto 0', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}>
                                            {stepData.pathText}
                                        </Typography>
                                    )
                                }

                                <DynamicFooter currentStep={currentStep} nextStep={() => (saveDataBeforeRedirect(), nextStep())} prevStep={prevStep} finishing={finishing} />
                            </>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default HelpDynamicForm