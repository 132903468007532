// This component renders the Dashboard page, which is the main page of the application.
// It displays the user's information, notifications, and performance indicators.
// The user's information is displayed on the left side of the page, while the notifications and performance indicators are displayed on the right side.
// The notifications are displayed in a list format, and the user can view all notifications or only the new ones.
// The performance indicators are displayed in a chart format, showing the user's performance over time.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101416971/Dashboard
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=986-58&t=mEOidrARSPxeHbDw-4

import React, { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import { verifyPlan } from '../../api/backend/Plans/verifyPlan';

import { isAuthenticated } from '../../authMiddleware';

import Welcome from './Welcome';
import Notifications from './Notifications';

import TutorialComponent from './TutorialComponent';
import tutorialData from './tutorialData';
import ModeGraphic from '../../components/Performance/ModeGraphic';

import { Typography, FormControl, Select, MenuItem } from '@mui/material';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { useAuth } from '../../context/AuthContext';

const DashboardHeader = ({ selectedMode, handleChange, modeList }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: { xs: 'start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            margin: '20px',
            paddingTop: '20px',
        }}>
            <Typography sx={{
                color: ThemeColors.purple600,
                fontSize: { xl: '30px', lg: '30px', xs: '20px' },
                fontFamily: 'Satoshi-Medium',
                flex: { xs: '1', sm: 'none' },
            }}>
                {selectedMode === 'Modo Tutorial' ? 'Por onde começar?' : 'Indicadores'}
            </Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: { xs: '100%', sm: '0' },
                paddingLeft: '15px',
                width: { xs: '100%', sm: 'auto' },
                marginTop: { xs: '20px', sm: '0' },
            }}>
                <FormControl sx={{
                    width: '100%',
                }}>
                    <Select
                        sx={{
                            minWidth: '200px',
                            color: ThemeColors.purple600,
                            fontFamily: 'Satoshi-Medium',
                            backgroundColor: ThemeColors.purple100,
                            borderRadius: '6px',
                            border: `1px solid ${ThemeColors.purple100}`,
                            "&:hover": {
                                backgroundColor: ThemeColors.purple200,
                            },
                        }}
                        value={selectedMode}
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    boxShadow: 'none',
                                    border: `1px solid ${ThemeColors.purple100}`,
                                },
                            },
                        }}
                    >
                        {modeList.map((mode, index) => (
                            <MenuItem
                                key={index}
                                value={mode}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: ThemeColors.purple300,
                                        color: 'white',
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: ThemeColors.purple300,
                                    },
                                }}
                            >
                                {mode}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

function DashboardPage() {

    const { userAuthenticated, userInfo, setUserInfo } = useAuth();

    const [dataFromAPI, setDataFromAPI] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userAvatar, setUserAvatar] = useState('');

    const [AllNotifications, setAllNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [showAllNotifications, setShowAllNotifications] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 4;

    const [planoAtual, setPlanoAtual] = useState(null);
    const [loadingPlan, setLoadingPlan] = useState(true);

    const [selectedMode, setSelectedMode] = useState('Modo Gráfico');
    const modeList = ['Modo Gráfico', 'Modo Tutorial'];

    const [selectedLabel, setSelectedLabel] = useState('total_read');
    const [selectedTutorial, setSelectedTutorial] = useState(tutorialData[0]);


    const handleIconClick = (label) => {
        setSelectedLabel(prevLabel => (prevLabel === label ? null : label));
    };

    useEffect(() => {
        if (showAllNotifications) {
            setNewNotification(AllNotifications);
        }
    }, [showAllNotifications]);

    useEffect(() => {
        if (userAuthenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);


    useEffect(() => {
        (async () => {
            await verifyPlan().then((response) => {
                console.log(response);
                setPlanoAtual(response.plan_name);
            }).catch((error) => {
                console.log(error);
            })
        })().then(() => setLoadingPlan(false));
    }, []);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files-public-139308022743-prod.s3.us-east-1.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
        }
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = notificationsPerPage * (currentPage - 1);
    const currentNotifications = !showAllNotifications
        ? newNotification.slice(indexOfFirstNotification, indexOfLastNotification)
        : AllNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

    return (
        <Fragment>
            {!loading && (
                <Fade in={!loading}>
                    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'20px'} sx={{ flexDirection: { xl: 'row', lg: 'row', sm: 'column' } }}>
                            <Welcome userInfo={userInfo} dataFromAPI={dataFromAPI} userAvatar={userAvatar} planoAtual={planoAtual} loadingPlan={loadingPlan} />
                            <Notifications AllNotifications={AllNotifications} currentNotifications={currentNotifications} newNotification={newNotification} setShowAllNotifications={setShowAllNotifications} showAllNotifications={showAllNotifications} notificationsPerPage={notificationsPerPage} setAllNotifications={setAllNotifications} setCurrentPage={setCurrentPage} setNewNotification={setNewNotification} currentPage={currentPage} />
                        </Box>
                        <Box sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            border: '1px solid #D9D9DC',
                            margin: '0px 20px 20px',
                        }}>
                            <DashboardHeader selectedMode={selectedMode} handleChange={(e) => { setSelectedMode(e.target.value) }} modeList={modeList} />

                            {
                                selectedMode === 'Modo Tutorial' ? (
                                    <TutorialComponent selectedTutorial={selectedTutorial} setSelectedTutorial={setSelectedTutorial} tutorialData={tutorialData} />
                                ) : (
                                    selectedMode === 'Modo Gráfico' ? (
                                        <ModeGraphic />
                                    ) :
                                        null
                                )
                            }
                        </Box>
                    </Box>
                </Fade>
            )}
        </Fragment>
    );
}

export default DashboardPage;