// Description: DynamicButton to be used on the platform that will re-render when the user call the backend
// Updated: 17/02/2025
// Author: Simon Franklin

// React
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'

const DynamicButton = ({ variant, text, handleFunction, style, loadingText }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        console.log('Re-renderizando o botão')
        setLoading(true);

        try {
            await handleFunction();
        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw new Error('Erro ao buscar dados da API');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button variant={variant} disabled={loading} sx={style} onClick={async() => await handleClick()}>
            {loading === true && loadingText ? loadingText : text}
        </Button>
    )
}

export default DynamicButton