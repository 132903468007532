// Description: Page to tell the user that his session has expired
// Updated: 26/02/2025
// Author: Simon Franklin

// React
import React from 'react';

// MUI Material Components
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import themeColors from '../../components/ThemeColors/ThemeColors';
import { useNavigate } from 'react-router-dom';
import Waves from './waves-bg.png';
import Logo from '../../assets/ilustrations/Logo';

const ClockIcon = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox='0 0 51 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M25.5 43.0832C37.236 43.0832 46.75 34.3154 46.75 23.4998C46.75 12.6843 37.236 3.9165 25.5 3.9165C13.7639 3.9165 4.25 12.6843 4.25 23.4998C4.25 34.3154 13.7639 43.0832 25.5 43.0832Z' stroke='#8142FC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M25.5 11.75V23.5L34 27.4167' stroke='#8142FC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    )
}

// Render the Page
const SessionExpired = () => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    return (
        <Box sx={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Box sx={{ position: 'absolute', top: '24px', left: '24px' }}>
                <Logo />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 10, marginBottom: '80px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '25px' }}>
                    <Typography children='SUA SESSÃO EXPIROU' sx={{ color: themeColors.purple500, fontWeight: 600, fontSize: { xs: '24px', sm: '48px' } }} />
                    <ClockIcon width={isSmallScreen ? '26' : '51'} height={isSmallScreen ? '26' : '47'} />
                </Box>

                <Typography sx={{ maxWidth: { xs: '254px', sm: '509px' }, textAlign: 'center', color: '#848484', fontSize: { xs: '12px', sm: '16px' }, fontWeight: 500, marginBottom: '45px' }}>
                    Sua sessão acaba de expirar. <br />
                    Clique no botão abaixo e você será redirecionado para a página de login.
                </Typography>

                <Button onClick={() => navigate('/login')} sx={{ background: 'linear-gradient(90deg, rgba(129, 66, 252, 0.98) 0%, rgba(77, 39, 150, 0.98) 100%)', width: { xs: '290px', sm: '361px' }, height: { xs: '44px', sm: '54px' }, borderRadius: '30px', fontWeight: 600, fontSize: '20px' }}>
                    Entrar
                </Button>
            </Box>

            <Box sx={{ position: 'absolute', bottom: 0, left: 0, maxHeight: '290px', width: '100%', height: '290px', backgroundImage: `url(${Waves})`, backgroundSize: 'cover', opacity: '60%' }} />
        </Box>
    )
}

export default SessionExpired