import axios from 'axios';

export async function VerifyAPI(data) {
  try {

    const token = localStorage.getItem('jwt_token');
    const clientId = localStorage.getItem('idToken');

    if (!token) throw new Error('Token de autenticação não encontrado');
    if (!clientId) throw new Error('ID do cliente não encontrado');

    const response = await axios.post('https://api.123zap.com.br/webhook/sheets/api/check', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Client_ID': clientId
      }
    });

    return response.data;

  } catch (error) {
    console.error('Erro ao obter dados da API:', error);
    if (error.response) {
       return { data: null, status: error.response.status, message: error.response.data || 'Erro no servidor.' };
    } else {
       return { data: null, status: 'Ocorreu um erro na requisição.' };
    }
  }
}
