// This endpoint is used to get the data for the dashboard page.
// The data is used to populate the charts and tables on the dashboard page.
// The data is fetched from the backend API using an axios GET request.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/135593992/Dashboard

import axios from 'axios';
export async function DashboardData() {
    try {
        const token = localStorage.getItem('jwt_token');
        const client_id = localStorage.getItem('idToken');

        if (!token) {
            throw new Error('Token de autenticação não encontrado');
        }

        if (!client_id) {
            throw new Error('ID do cliente não encontrado');
        }
        
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/painel/data`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${client_id}`
                }
            }
        );
        
        console.log('response', response);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados do painel:', error.message);
        console.log('error', error);

        // Você pode optar por exibir uma mensagem ao usuário ou lançar novamente o erro
        throw error;
    }
}
