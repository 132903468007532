// This component is responsible for rendering the reports page of the dashboard
// The client can see the general analysis of the sentiments of the messages sent by the customers
// The client can download the report of the analysis of the messages sent by the customers
// The client can see the total of messages analyzed by the system
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/134512643/Estrutura
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=2138-436&t=Wrecb0FHQVrbZ5lv-4

import React, { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Button, useMediaQuery } from '@mui/material';

import Typography from '@mui/material/Typography';
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import { verifyPlan } from '../../api/backend/Plans/verifyPlan';

import { isAuthenticated } from '../../authMiddleware';

import Welcome from './Welcome';
import Notifications from './Notifications';
import Comprehend from './Comprehend';

import ThemeColors from '../../components/ThemeColors/ThemeColors';

import ModeGraphic from '../../components/Performance/ModeGraphic';
import IndicatorsList from '../../components/Performance/IndicatorsList';

import { FormControl, MenuItem, Select } from '@mui/material';

import axios from 'axios';

const DashboardHeader = ({ selectedMode, handleChange, modeList }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: { xs: 'start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
        }}>
            <Typography sx={{
                color: ThemeColors.purple600,
                fontSize: { xl: '30px', lg: '30px', xs: '20px' },
                fontFamily: 'Satoshi-Medium',
                padding: '20px',
                flex: { xs: '1', sm: 'none' },
            }}>
                {selectedMode === 'Modo Tutorial' ? 'Por onde começar?' : 'Indicadores'}
            </Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: { xs: '100%', sm: '0' },
                paddingLeft: '15px',
                width: { xs: '100%', sm: 'auto' },
                marginTop: { xs: '20px', sm: '0' },
            }}>
                <FormControl sx={{
                    padding: { xs: '10px 30px 0px 0px', sm: '30px 30px 0px 0px' },
                    width: '100%',
                }}>
                    <Select
                        sx={{
                            width: '100%',
                            color: ThemeColors.purple600,
                            fontFamily: 'Satoshi-Medium',
                            backgroundColor: ThemeColors.purple100,
                            borderRadius: '6px',
                            border: `1px solid ${ThemeColors.purple100}`,
                            "&:hover": {
                                backgroundColor: ThemeColors.purple200,
                            },
                        }}
                        value={selectedMode}
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    boxShadow: 'none',
                                    border: `1px solid ${ThemeColors.purple100}`,
                                },
                            },
                        }}
                    >
                        {modeList.map((mode, index) => (
                            <MenuItem
                                key={index}
                                value={mode}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: ThemeColors.purple300,
                                        color: 'white',
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: ThemeColors.purple300,
                                    },
                                }}
                            >
                                {mode}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

function DashboardPage() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    const [dataFromAPI, setDataFromAPI] = useState(null);

    const [selectedMode, setSelectedMode] = useState('Modo Gráfico');
    const modeList = ['Modo Gráfico', 'Modo Relatório'];

    const [userAvatar, setUserAvatar] = useState('');

    const [planoAtual, setPlanoAtual] = useState(null);
    const [loadingPlan, setLoadingPlan] = useState(false);

    const [sentiments, setSentiments] = useState(null);
    const [loadingComprehend, setLoadingComprehend] = useState(false);
    const [totalMessages, setTotalMessages] = useState(0);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);


    useEffect(() => {
        setLoadingComprehend(true);

        const authorization = localStorage.getItem('jwt_token');
        const url = process.env.REACT_APP_BACKEND_AWS_URL;

        const startTime = Date.now();

        axios.post(`${url}/painel/data/messages/received/comprehend`, {}, {
            headers: { 'Authorization': authorization, 'client_id': localStorage.getItem('idToken') }
        }).then((response) => {
            setSentiments(response.data['Sentimentos Gerais']);
            setTotalMessages(response.data['Total de Mensagens']);
        }).catch((error) => {
        }).finally(() => {
            const elapsedTime = Date.now() - startTime;
            const minDelay = 4000;
            const delay = Math.max(minDelay - elapsedTime, 0);
            setTimeout(() => {
                setLoadingComprehend(false);
            }, delay);
        });
    }, []);


    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files-public-139308022743-prod.s3.us-east-1.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
            <Box
                display="flex"
                gap={3}
                padding={3}
                paddingLeft="20px"
                paddingRight="20px"
                paddingTop="20px"
                sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
            >
                <Welcome userInfo={userInfo} dataFromAPI={dataFromAPI} userAvatar={userAvatar} planoAtual={planoAtual} loadingPlan={loadingPlan} />
                <Comprehend sentiments={sentiments} totalMessages={totalMessages} loading={loadingComprehend} />
            </Box>
            <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                border: '1px solid #D9D9DC',
                margin: '0px 20px 20px',
            }}>
                <DashboardHeader selectedMode={selectedMode} handleChange={(e) => { setSelectedMode(e.target.value) }} modeList={modeList} />

                {
                    selectedMode === 'Modo Relatório' ? (
                        <IndicatorsList />
                    ) : (
                        selectedMode === 'Modo Gráfico' ? (
                            <ModeGraphic />
                        ) : null
                    )
                }
            </Box>
        </Box>
    )

}

export default DashboardPage;