// Modal that will render one table with the filtered data
// Updated: 26/03/2025
// Author: Simon Franklin

// React
import React, { useEffect, useRef, useState } from 'react';

// Material UI Components
import { Box, Button, createTheme, FormControl, InputAdornment, LinearProgress, TextField, ThemeProvider, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CloseOutlined } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Components and Functions for the project
import dayjs from 'dayjs';
import { DynamicTable } from './IndicatorsList';
import themeColors from '../ThemeColors/ThemeColors';
import axios from 'axios';
import { notify } from '../../utils/utils';
import { CampaignsByUuidAccount } from '../../api/backend/Campaigns/CampaignsByUuidAccount';
import Modal from '@mui/material/Modal';
import { set } from 'date-fns';

const Filter = ({ setStartDate, setEndDate, startDate, endDate, downloadExcel, loadingExcel, searchCampaigns, loadingSearch, loadingLabels, campaigns, setFilteredCampaigns, filteredCampaigns }) => {

  const startDateTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#F9F9F9',
            color: themeColors.gray30,
            borderRadius: '12px',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: themeColors.purple400,
            borderRadius: '2px',
            borderWidth: '1px',
            border: '1px solid',
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: themeColors.purple400,
              color: '#FFF',
            },
            '&.Mui-selected': {
              backgroundColor: themeColors.purple400 + " !important",
              color: '#FFF !important',
            }
          }
        }
      }
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
        padding: '20px',
        marginTop: '20px',
        justifyContent: 'space-between',
        width: '100%', // Garante que o Box ocupe 100% da largura disponível
      }}
    >
      <FormControl variant="outlined" sx={{ flex: 1 }}>
        <ThemeProvider theme={startDateTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DesktopDatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              maxDate={dayjs()}
              InputLabelProps={{ shrink: true }}
              renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </ThemeProvider>
      </FormControl>

      <FormControl variant="outlined" sx={{ flex: 1 }}>
        <ThemeProvider theme={startDateTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DesktopDatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              maxDate={dayjs()}
              InputLabelProps={{ shrink: true }}
              renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </ThemeProvider>
      </FormControl>

      <Button
        variant="contained"
        sx={{
          flex: 1,
          padding: { xs: '15px', sm: '0px' },
          borderRadius: '6px',
          backgroundColor:
            loadingSearch || loadingExcel || loadingLabels
              ? `${themeColors.gray10} !important`
              : themeColors.purple300,
          color:
            loadingSearch || loadingExcel || loadingLabels
              ? `${themeColors.gray40} !important`
              : themeColors.purple950,
          '&:hover': {
            backgroundColor: themeColors.purple400 + 'CC',
          },
        }}
        onClick={searchCampaigns}
        disabled={loadingSearch || loadingExcel || loadingLabels}
      >
        {loadingSearch
          ? 'Pesquisando...'
          : loadingExcel
            ? 'Aguarde...'
            : loadingLabels
              ? 'Aguarde...'
              : 'Pesquisar'}
      </Button>

      <Button
        variant="contained"
        sx={{
          flex: 1,
          padding: { xs: '15px', sm: '0px' },
          borderRadius: '6px',
          backgroundColor:
            loadingSearch || loadingExcel || loadingLabels
              ? `${themeColors.gray10} !important`
              : themeColors.green01,
          color:
            loadingSearch || loadingExcel || loadingLabels
              ? `${themeColors.gray40} !important`
              : themeColors.green03,
          '&:hover': {
            backgroundColor: themeColors.green01 + 'CC',
          },
        }}
        onClick={downloadExcel}
        disabled={loadingSearch || loadingExcel || loadingLabels}
      >
        {loadingExcel
          ? 'Baixando...'
          : loadingSearch
            ? 'Aguarde...'
            : loadingLabels
              ? 'Aguarde...'
              : 'Baixar Excel'}
      </Button>
    </Box>

  );
};


const TableModal = ({ open, onClose, data }) => {

  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState(['Título da campanha', 'Modelo utilizado', 'Destinatário', 'Data de envio', 'Status/Entrega']);
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [progress, setProgress] = useState(0);
  const [valueSearch, setValueSearch] = useState('');
  const [loadingLabels, setLoadingLabels] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [filteredMockup, setFilteredMockup] = useState([]);

  const rowsPerPage = 100;

  useEffect(() => {
    if (data?.date) {
      const [year, month] = data.date.split('-');
      const firstDay = dayjs(`${year}-${month}-01`);
      const lastDay = dayjs(firstDay).endOf('month');

      setStartDate(firstDay);
      setEndDate(lastDay);
    }
  }, [data?.date]);

  const fetchDataToCreateModal = async () => {
    setLoading(true);

    const startDate = dayjs(`${data.date}-01`);
    const endDate = startDate.endOf("month");

    const url = process.env.REACT_APP_BACKEND_URL;

    if (data.label === "total_read" || data.label === "total_send" || data.label === "total_failed" || data.label === "total_received") {

      setColumns(['Campanha', 'Descrição', 'Data Execução', 'Mensagens totais', 'Entregues', 'Lidas', 'Falhas']);

      const body = {
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD")
      };

      await fetch(`${url}/painel/data/messages/summary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwt_token"),
          "clientid": localStorage.getItem("idToken")
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then((items) => {
          const mappedData = items.data.map((item) => ({
            Campanha: item.nome_campanha,
            Descrição: item.descricao_campanha,
            'Mensagens totais': item.mensagens_totais,
            'Data Execução': item.ultima_execucao,
            Falhas: item.status_falha,
            Lidas: item.status_lido,
            Entregues: item.status_entregue
          }));
          setColumns(Object.keys(mappedData[0] || {}));
          setCampaigns(mappedData || {});
          setTotalItems(items.total_items);
        })
        .catch(error => {
          console.error("Erro ao buscar os indicadores:", error);
        })
        .finally(() => setLoading(false));
    } 
  };

  useEffect(() => {
    if (open && data !== null) {
      fetchDataToCreateModal();
    }
  }, [open, data, page])

  useEffect(() => {

    if (campaignOptions.length === 0) {
      (async () => {
        CampaignsByUuidAccount().then((response) => {
          const mappedData = response.campaigns.map((item) => ({
            campaign: item.title,
            template: item.code,
            ...item
          }));

          setCampaignOptions(mappedData);
          setLoadingTable(false);
          setLoadingLabels(false);
        });
      })();
    };

  }, []);

  const resetModal = () => {
    setLoading(true);
    setPage(0);
    setTotalItems(0);
    setCampaigns([]);
    setFilteredMockup([]);
    onClose();
  }

  const downloadExcel = async () => {

    setLoadingExcel(true);

    notify('Normal', 'Seu relatório está sendo baixado', true);

    const body = {
      filter_status: null,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      name_campaign: valueSearch,
    };

    const url = process.env.REACT_APP_BACKEND_URL;

    axios.post(`${url}/painel/data/messages/general/download`, body, {
      headers: { Authorization: localStorage.getItem('jwt_token'), clientid: localStorage.getItem('idToken') },
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      }
    })
      .then((response) => {
        const url_download = response.data.url;
        const link = document.createElement('a');
        link.href = url_download;
        link.download = url_download.split('/').pop();
        link.click();
        notify('Success', 'Seu Relatório foi baixado com sucesso!', true);
      })
      .catch((error) => {
        console.error('Erro ao buscar os indicadores:', error);
        notify('Error', 'Ocorreu algum erro no download', true);
      })
      .finally(() => {
        setLoadingExcel(false);
        setProgress(0);
      });
  };

  function formatISOStringIntoDate(isoString) {
    return new Date(isoString).toISOString().slice(0, 10);
  };

  const searchCampaigns = async () => {

    if (data.label === 'total_read' || data.label === 'total_failed' || data.label === 'total_send' || data.label === 'total_received') {

      setLoadingSearch(true);
      setLoadingTable(true);
      setLoadingLabels(true);
      setLoading(true);

      setColumns(['Campanha', 'Descrição', 'Data Execução', 'Mensagens totais', 'Entregues', 'Lidas', 'Falhas']);

      const body = {
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD')
      };

      const url = process.env.REACT_APP_BACKEND_URL;

      await fetch(`${url}/painel/data/messages/summary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("jwt_token"),
          "clientid": localStorage.getItem("idToken")
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then((items) => {
          const mappedData = items.data.map((item) => ({
            Campanha: item.nome_campanha,
            Descrição: item.descricao_campanha,
            'Mensagens totais': item.mensagens_totais,
            'Data Execução': item.ultima_execucao,
            Falhas: item.status_falha,
            Lidas: item.status_lido,
            Entregues: item.status_entregue
          }));
          setColumns(Object.keys(mappedData[0] || {}));
          setCampaigns(mappedData || {});
          setTotalItems(items.total_items);
        })
        .catch(error => {
          console.error("Erro ao buscar os indicadores:", error);
        })
        .finally(() => {
          setLoading(false);
          setLoadingTable(false);
          setLoadingSearch(false);
          setLoadingLabels(false);
        });

    } 
  }

  return (
    <Modal
      open={open}
      onClose={resetModal}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <>
        <Box
          sx={{
            width: { xs: '100%', sm: '1100px', md: '1100px ', lg: '1100px' },
            height: { xs: '100%', sm: '600px', md: '600px', lg: '600px' },
            display: 'flex',
            backgroundColor: '#fff',
            borderRadius: { xs: '0px', sm: '12px' },
            zIndex: 5000,
            position: 'relative',
            padding: { xs: '10px', sm: '10px' },
            overflow: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: `${themeColors.gray30} ${themeColors.gray10}`,
          }}
        >
          <div onClick={resetModal} style={{ cursor: 'pointer' }}>
            <CloseOutlined
              sx={{
                color: themeColors.gray40,
                display: 'block', 
                position: 'absolute',
                top: { xs: '10px', sm: '30px' },  
                right: { xs: '10px', sm: '30px' }, 
              }}
            />
          </div>
          <Box
            component="div"
            sx={{
              minHeight: '100%',
              minWidth: '100%',
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              overflow: 'auto',
              padding: { xs: '0px', sm: '20px' },
            }}
          >
            <Filter
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              downloadExcel={downloadExcel}
              loadingExcel={loadingExcel}
              loadingLabels={loadingLabels}
              searchCampaigns={searchCampaigns}
              loadingSearch={loadingSearch}
              setValueSearch={setValueSearch}
              valueSearch={valueSearch}
              campaigns={campaignOptions}
              filteredCampaigns={filteredMockup}
              setFilteredCampaigns={setFilteredMockup}
            />
            <DynamicTable
              columns={columns}
              data={campaigns}
              handlePageChange={(event, newPage) => setPage(newPage)}
              loading={loading}
              page={page}
              rowsPerPage={rowsPerPage}
              totalItems={totalItems}
              selectedLabel={""}
            />
          </Box>
        </Box>
      </>
    </Modal>
  )
}

export default TableModal