import axios from 'axios';

export async function ExcelInsert(data) {
  try {
    const token = localStorage.getItem('jwt_token');
    const clientId = localStorage.getItem('idToken');

    if (!token) throw new Error('Token de autenticação não encontrado');
    if (!clientId) throw new Error('ID do cliente não encontrado');

    // Não defina o 'Content-Type' manualmente!
    const response = await axios.post('https://api.123zap.com.br/webhook/sheets/excel/add', data, {
      headers: {
        'Authorization': token,
        'Client_ID': clientId,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao obter dados da API:', error);
    return { data: null, status: 'Ocorreu um erro na requisição.' };
  }
}
