// Desc: This file contains the configuration page for the WhatsApp channel
// Update: 10/03/2025

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';

// Material-UI Components
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Grid, Typography, TextField, Box } from '@mui/material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterData from '../../assets/ilustrations/FilterData.png';

// Components and functions from the project
import SearchIcon from '../../assets/Icons/components/Search';
import Titles from '../Titles/Titles';
import DynamicTableLoading from './DynamicTableLoading';

// Render Your Component
const DynamicTable = ({ data, columns, actions, activeSearch, titleSearch, button, titleLabel, itensPerPage, loading }) => {
  // Search state variable
  const [search, setSearch] = useState('');

  const countOculto = data.filter((item) => item.Visibility === 'Oculto').length;

  const [dataFiltered, setFilteredDataState] = useState(data);


  useEffect(() => {

    console.log(data, 'data to filter');
    const filter = data.filter((item) => item.Visibility !== 'Oculto')

    console.log(filter, 'filter');
    setFilteredDataState(filter);

  }, [data]);

  const showEverything = () => {
    setFilteredDataState(data); // Atualiza o estado com todos os dados
    setPage(0); // Volta para a primeira página
  };

  const removeShowEverything = () => {
    setFilteredDataState(data.filter((item) => item.Visibility !== 'Oculto')); // Atualiza o estado com os dados visíveis
    setPage(0); // Volta para a primeira página
  };

  const filteredData = search.length > 0
    ? dataFiltered.filter(item => {
      return Object.keys(item).some(key => {
        const value = item[key];
        return value !== null && value !== undefined && value.toString().toLowerCase().includes(search.toLowerCase());
      }
      );
    })
    : dataFiltered;

  // State variables
  const [colorAlert, setColorAlert] = useState('');
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(itensPerPage ? itensPerPage : 15);
  const [page, setPage] = useState(0);
  const showOptions = dataFiltered.length > 0; // Verifica se há dados para exibir as opções
  const sortedData = stableSort(filteredData, getComparator(order, orderBy));
  const slicedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const tableColumns = dataFiltered.length > 0 ? columns : columns.map(() => ({ header: '', field: '' }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const workingThis = () => {
    setMessage('Ainda estamos trabalhando nessa opção.');
    setColorAlert('#4CAF');
    setSnackbarOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item); // Adiciona o item selecionado ao estado
    console.log(item, 'item teste');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null); // Limpa o item selecionado quando o menu é fechado
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };


  const visibleActions = item => actions.some(action => action.isVisible(item));


  return (
    <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '20px', minHeight: 'calc(100% - 100px)', display: 'flex', flexDirection: 'column' }}>


      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        {activeSearch ? (

          <TextField
            id="search"
            variant="outlined"
            size="small"
            value={search}
            onChange={handleSearch}
            sx={{ width: { xs: '50%', sm: '25%' }, backgroundColor: '#fff', borderRadius: '5px', marginBottom: '20px', '.css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input': { padding: '6px 14px' } }}

            // change propriety of label 
            placeholder={titleSearch ? titleSearch : 'Pesquisar'}
            // change color of label
            InputLabelProps={{
              style: { color: '#52525B' } // Muda a cor do label para vermelho
            }}

            // Icone de pesquisa

            InputProps={{
              startAdornment: (
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}> {/* Adiciona margem à direita do ícone */}
                  <SearchIcon style={{ paddingLeft: '10px', color: '#52525B' }} />
                </div>
              )
            }}
          />

        ) : null}

        {button ? (


          <Button
            variant="contained"
            sx={{
              marginBottom: '20px',
              marginLeft: '5px',
              backgroundColor: '#8142FC',
              color: '#fff',
              textTransform: 'none',
              fontFamily: 'Satoshi',
              fontSize: { xs: '12px', sm: '16px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'none',
              padding: '3.5px 16px'
            }}
            onClick={button.action ? button.action : workingThis}
          >
            <span style={{ display: 'flex', alignItems: 'center', marginRight: '15px', marginTop: '2px' }}>
              {button?.icon}
            </span>

            {button?.tooltip}
          </Button>

        ) : null}

      </div>

      {
        loading === true ? (
          <DynamicTableLoading tableColumns={columns} />
        ) : (
          <>
            {
              filteredData.length === 0 && loading === false ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flex: 1 }}>
                  <Box sx={{ width: { xs: '80%', sm: 'auto' } }}>
                    <Box component='img' src={FilterData} alt='Itens ocultos' sx={{ width: { xs: '247px', sm: '494px' }, height: { xs: '165px', sm: '330px' }, objectFit: 'contain' }} />
                    <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px', justifyContent: 'center', textAlign: 'center' }}>
                      <Titles bold styles={{ fontSize: { xs: '18px', sm: '20px', lg: '24px' } }} children={'Não tem itens a serem exibidos :('} color={'#8033F4'} />
                      <Titles styles={{ fontSize: { xs: '12px', sm: '13px', lg: '16px' } }} children={'Desative os filtros para exibir todos os itens na tabela'} />
                    </Box>
                  </Box>
                </div>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>

                        {tableColumns.map((column, index) => (
                          <TableCell
                            key={index}
                            align={column.align ? column.align : 'left'}
                            sx={{
                              fontSize: { xl: '16px', lg: '13px', sm: '13px' }, fontFamily: 'Satoshi', padding: { lg: '16px 30px 16px 30px', sm: '16px 10px' }, borderBottom: 'none', maxWidth: { sm: '150px', lg: '200px', xl: '100%' }, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: { xs: index < 2 ? 'visible' : 'hidden' },
                              whiteSpace: 'nowrap',
                              position: { xs: index < 2 ? 'sticky' : 'static', sm: 'static' },
                              left: { xs: index === 0 ? 0 : index === 1 ? '50px' : 'auto', sm: 'auto' },
                              zIndex: { xs: index < 2 ? 1 : 'auto', sm: 'auto' },
                              background: { xs: index < 2 ? '#f9f9f9' : 'none', sm: 'none' },
                            }}
                            onClick={column.filter ? () => handleSort(column.field) : null}
                          >
                            <Box display={'flex'} width={'100%'} alignItems={'center'}>
                              {column.header}
                              {column.filter && (
                                orderBy === column.field ? (
                                  order === 'asc' ? (
                                    <Box component={'img'}
                                      src='data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%206%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M2.37873%200.265338L0%202.71856L1.24253%204L3%202.1875L4.75747%204L6%202.71856L3.62127%200.265338C3.45648%200.0954422%203.23301%200%203%200C2.76699%200%202.54352%200.0954422%202.37873%200.265338Z%22%2F%3E%0A%3C%2Fsvg%3E%0A'
                                      sx={{ marginLeft: 'auto', filter: 'opacity(40%)', width: '10px', height: '10px', lineHeight: 'inherit', cursor: 'pointer' }}
                                      fontSize="small"
                                      alt="Ascending Icon"
                                    />
                                  ) : (
                                    <Box component={'img'}
                                      src='data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%206%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M3%207.8125L1.24253%206L0%207.28144L2.37873%209.73466C2.54352%209.90456%202.76699%2010%203%2010C3.23301%2010%203.45648%209.90456%203.62127%209.73466L6%207.28144L4.75747%206L3%207.8125Z%22%2F%3E%0A%3C%2Fsvg%3E%0A'
                                      sx={{ marginLeft: 'auto', filter: 'opacity(40%)', width: '10px', height: '10px', lineHeight: 'inherit', cursor: 'pointer' }}
                                      fontSize="small"
                                      alt="Descending Icon"
                                    />
                                  )
                                ) : (
                                  <Box component={'img'}
                                    src='data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%206%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20dsx%3D%22fill%3A%23757575%3B%22%20d%3D%22M2.37873%200.265338L0%202.71856L1.24253%204L3%202.1875L4.75747%204L6%202.71856L3.62127%200.265338C3.45648%200.0954422%203.23301%200%203%200C2.76699%200%202.54352%200.0954422%202.37873%200.265338V0.265338Z%22%2F%3E%0A%3Cpath%20d%3D%22M3%207.8125L1.24253%206L0%207.28144L2.37873%209.73466C2.54352%209.90456%202.76699%2010%203%2010C3.23301%2010%203.45648%209.90456%203.62127%209.73466L6%207.28144L4.75747%206L3%207.8125Z%22%2F%3E%0A%3C%2Fsvg%3E%0A'
                                    sx={{ marginLeft: 'auto', filter: 'opacity(40%)', width: '10px', height: '10px', lineHeight: 'inherit', cursor: 'pointer' }}
                                    fontSize="small"
                                    alt="Neutral Icon"
                                  />
                                )
                              )}
                            </Box>
                          </TableCell>

                        ))}

                        <TableCell
                          align="center"
                          sx={{
                            fontSize: { xl: '16px', lg: '13px', sm: '13px' }, fontFamily: 'Satoshi', paddingLeft: '30px', paddingRight: '30px', borderBottom: 'none'
                          }}
                        >

                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: { xs: '#fff', sm: 'none' } }}>
                      {slicedData.map((item, idx) => (  // Aqui 'idx' é declarado corretamente como o segundo parâmetro
                        <React.Fragment key={item.id}>
                          <TableRow style={{ height: '5px' }} />
                          <TableRow id={item.id} hover >
                            {tableColumns.map((column, index) => (
                              <TableCell
                                key={index}
                                className={`item + ${index}`}
                                sx={{
                                  fontSize: { xl: '16px', lg: '13px', xs: '13px' },
                                  fontFamily: 'Satoshi',
                                  padding: { lg: '16px 30px 16px 30px', sm: '16px 10px' },
                                  width: index === 0 ? '10px' : 'auto',
                                  borderBottom: 'none',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  position: { xs: index < 2 ? 'sticky' : 'static', sm: 'static' },
                                  left: { xs: index === 0 ? 0 : index === 1 ? '50px' : 'auto', sm: 'auto' },
                                  zIndex: { xs: index < 2 ? 1 : 'auto', sm: 'auto' },
                                  background: '#fff',
                                  textAlign: column.align ? column.align : 'left',
                                  maxWidth: { sm: '150px', lg: '200px', xl: '100%' },
                                  textOverflow: 'ellipsis',
                                  overflow: { xs: index === 1 ? 'visible' : 'hidden' },
                                  whiteSpace: 'nowrap',
                                  boxShadow: { xs: index === 1 ? '6px 0px 20px rgba(0, 0, 0, 0.3)' : 'none', sm: 'none' }
                                }}
                              >
                                {column.render ? column.render(item[column.field]) : item[column.field]}
                              </TableCell>
                            ))}

                            <TableCell align="center" style={{ borderBottom: 'none', width: '10px', background: '#fff' }}>

                              {visibleActions(item) && (

                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(event) => handleClick(event, item)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                              )}

                              <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={
                                  // retira o box-shadow do menu
                                  {
                                    '& .MuiPaper-root': {
                                      boxShadow: 'none',
                                      borderRadius: '8px',
                                      border: '1px solid #DBDBDB',
                                      // alinha o menu a esquerda do ícone
                                      marginLeft: '-35px',
                                      marginTop: '-5px'
                                    }

                                  }
                                }
                              >

                                {actions.map((action, index) => (
                                  action.isVisible(selectedItem) ? (
                                    <MenuItem
                                      key={index}
                                      onClick={() => {
                                        action.action(selectedItem);
                                        handleClose();
                                      }}
                                    >
                                      <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                        <Grid item style={{ marginTop: '8px' }} >
                                          {action.icon}
                                        </Grid>
                                        <Grid item style={{ paddingLeft: '20px' }}>
                                          {action.tooltip}
                                        </Grid>
                                      </Grid>
                                    </MenuItem>
                                  ) : null
                                ))}


                              </Menu>

                            </TableCell>

                          </TableRow>
                          <TableRow style={{ height: '5px' }} />
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            }
          </>
        )
      }
      <Grid container justifyContent="space-between" alignItems="center" alignContent={'center'}>
        <Grid item>
          <TablePagination
            rowsPerPageOptions={[4, 10, 15]}
            component="div"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ marginLeft: '-25px' }}
            labelRowsPerPage={titleLabel ? titleLabel : 'Linhas por página'}
            // Define a fonte do componente como Satoshi e alinha ao centro
            SelectProps={{
              IconComponent: () => null,
              style: { fontFamily: 'Satoshi', backgroundColor: 'rgb(235 235 235)', borderRadius: '2px' },
              inputProps: {
                style: {
                  textAlign: 'center', // Centraliza o texto
                }
              }, MenuProps: {
                PaperProps: {
                  sx: {
                    boxShadow: 'none', // Removendo o sombreamento
                    border: '1px solid #DBDBDB', // Adicionando borda
                    marginTop: '10px', // Adicionando margem superior
                    marginBottom: '10px', // Adicionando margem inferior
                  }
                }
              }

            }}


            // Retira estilos padrão do componente
            sx={{
              ".MuiTablePagination-displayedRows": {
                color: "#52525ba6",
                fontFamily: 'Satoshi',
              },
              ".MuiTablePagination-selectLabel": {
                color: "#52525ba6",
                fontFamily: 'Satoshi',
              },

              // Remove o espaço padrao do ic
            }}




          />
        </Grid>
        <Grid item>
          {filteredData.length !== data.length ? (
            <Typography style={{ fontFamily: 'Satoshi', fontSize: '16px', color: '#52525B' }}>
              {countOculto} itens ocultos (
              <span style={{ color: '#8142FC', cursor: 'pointer' }} onClick={showEverything}>
                Exibir todos
              </span>
              )
            </Typography>
          ) : filteredData.length === data.length && countOculto > 0 ? (
            <Typography style={{ fontFamily: 'Satoshi', fontSize: '16px', color: '#52525B' }}>
              {countOculto} itens ocultos (
              <span style={{ color: '#8142FC', cursor: 'pointer' }} onClick={removeShowEverything}>
                Ocultar todos
              </span>
              )
            </Typography>
          ) : (
            <Typography style={{ fontFamily: 'Satoshi', fontSize: '16px', color: '#52525B' }}>
              Total de {data.length} itens
            </Typography>
          )}

        </Grid>
      </Grid>

    </div>
  );
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
    : (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
}

function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export default DynamicTable;