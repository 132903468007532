import { Box, Button } from '@mui/material'
import React, { useState } from 'react'

const DynamicFooter = ({ currentStep, nextStep, prevStep, finishing }) => {
  const [loading, setLoading] = useState(false);

  const handleFinish = async() => {
    setLoading(true);

    try {
      await nextStep();
    } catch (error) {
      console.log(error);
      throw new Error(`Erro salvando Campanha: ` + error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', justifySelf: 'end', width: '100%', marginTop: 'auto' }}>
      <Button onClick={prevStep} variant='outlined' sx={{ width: '154px', height: '36px', display: currentStep === 1 ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '6px', fontSize: '15px' }}>Voltar</Button>
      <Button onClick={finishing ? handleFinish : nextStep} disabled={loading} variant='contained' sx={{ width: '154px', height: '36px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '6px', fontSize: '15px', marginLeft: 'auto' }}>{finishing === true ? 'Criar Campanha' : 'Próximo'}</Button>
    </Box>
  )
}

export default DynamicFooter