// This endpoint is used to create a new request. It is used in the CreateRequest page.
// This endpoint is used in the CreateRequest page.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136773687/RequestRoutes

import axios from 'axios';

export async function RequestCreate(data) {
    const token = localStorage.getItem('jwt_token');
    const clientId = localStorage.getItem('idToken');

    if(!token) throw new Error('Token de autenticação não encontrado');

    if(!clientId) throw new Error('ID do cliente não encontrado!');

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_AWS_URL}/support/insert`, 
            {
                title: data.title,
                description: data.description,
                sector: data.sector,
                imageURLS: data.body.files
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                    'Client_ID': `${clientId}`
                }
            }
        );
        
        console.log(response);
        const responseData = response.data;


        if (response.status === 201 || response.status === 200 && responseData.message === 'Request created successfully') {

            responseData.status = 'OK';

        } else {

            responseData.status = 'ERROR';
        
        }

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}