// Description: Render an input for creating email
// Updated: 10/02/2025
// Author: Simon Franklin

// React
import React, { useEffect, useRef, useState } from "react";

// Material UI Components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, MenuItem, FormControl, TextField } from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";

// Render the Component
const TemplateInput = ({ value, handleChange, type, title, variablesOfTemplate }) => {
    // State variables
    const [isVisible, setIsVisible] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const templateInputStyle = {
        background: "white",
        color: "black",
        borderRadius: "12px",
        margin: 0,
        border: `1px solid ${themeColors.gray20}`,
        color: themeColors.gray40,
        height: type === "whatsapp" ? "309px" : "401px",
        width: "100%",
        padding: "0px",
        outline: "none",
        whiteSpace: "pre-wrap",
        overflowY: "auto",
        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            padding: '0px'
        },
        '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '14px'
        },
        '.css-1ntdzxq-MuiInputBase-root-MuiOutlinedInput-root': {
            padding: '0px'
        }
    }

    // Function to toggle the visibility of the variables
    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the change of the accordion
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const closeAccordion = () => {
        setExpanded(false);
    };

    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(null);

    const handleFocus = () => {
        const input = inputRef.current;
        if (input) {
            setCursorPosition(input.selectionStart);
        }
    };

    const addVariableInTemporaryMessage = (variable) => {
        const position = cursorPosition ?? value.length;
        const newText =
            value.slice(0, position) + ` {{${variable}}}` + value.slice(position);

        handleChange(newText);

        setTimeout(() => {
            const input = inputRef.current;
            if (input) {
                const newCursorPosition = position + ` {{${variable}}}`.length;
                input.focus();
                input.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    return (

        <>
            <Grid item xs={6}>
                <Typography style={{ color: themeColors.gray40, fontSize: '20px' }}>Mensagem</Typography>
            </Grid>
            <Grid item xs={6} style={{ position: 'relative' }}>
                <FormControl fullWidth>
                    <Typography onClick={toggleAccordionVisibility} style={{
                        color: themeColors.purple500,
                        marginRight: '5px',
                        marginTop: '2px',
                        fontFamily: 'Satoshi-Medium',
                        fontSize: '18px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }}>
                        {isVisible ? "Esconder Variáveis" : "+ Adicionar Variáveis"}
                    </Typography>
                    {isVisible && (
                        <div style={{
                            position: 'absolute',
                            right: 0,
                            top: '100%',
                            width: 'auto',
                            backgroundColor: themeColors.purple200,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            overflow: 'hidden',
                            zIndex: 1,
                            borderRadius: '12px'
                        }}>
                            {Object.entries(variablesOfTemplate).map(([category, values]) => (
                                <Accordion key={category} expanded={expanded === category} onChange={handleChangeAccordion(category)} style={{ background: themeColors.purple200, border: 'none !important' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{category}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ border: 'none !important', padding: 0 }}>
                                        {values.length > 0 ? (
                                            values.map((value) => (
                                                <MenuItem key={value} onClick={() => {
                                                    addVariableInTemporaryMessage(value);
                                                    toggleAccordionVisibility();
                                                    closeAccordion();
                                                }} style={{ color: themeColors.purple950 }}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <Typography>Adicionar variáveis</Typography>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField inputRef={(el) => {
                        inputRef.current = el;
                    }} value={value} multiline minRows={9} placeholder={"Seu Texto"} onClick={handleFocus} onKeyUp={handleFocus} onFocus={handleFocus} sx={templateInputStyle} onChange={(e) => (handleChange(e.target.value), handleFocus())} />
                </FormControl>
            </Grid>
        </>

    );
};

export default TemplateInput;