// Description: Page to add an whatsapp business channel
// Updated: 
// Author: Simon Franklin

// React
import React, { useEffect, useState } from 'react';

// Material UI Components
import { Box, Button, Divider, Typography, CircularProgress } from '@mui/material';

// Components and functions for the project
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import StepIndicator from '../../components/DynamicForm/StepIndicator';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../utils/utils';
import { ChannelAddWppAPI } from '../../api/backend/Channels/ChannelAddWppAPI';

const FormContent = React.memo(({ currentStep, steps, name, token, setName, setToken, url, setUrl, firstTime, success, setCurrentStep }) => {
    const navigate = useNavigate();

    if (currentStep === 1) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray", margin: "30px 0" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                    {steps[currentStep - 1].title}
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "16px" }, color: "#848484", marginBottom: "19px", textAlign: "center", maxWidth: { xs: "100%", sm: "80%" } }}>
                    {steps[currentStep - 1].description}
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%', marginTop: "20px" }}>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        placeholder="Escolha um nome para identificação"
                        style={{
                            borderRadius: "12px",
                            borderColor: "gray",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "6px",
                            border: "none",
                            outline: "none",
                            padding: "14px",
                            width: "100%"
                        }}
                        key="name-field"
                    />
                    <input
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        fullWidth
                        placeholder="Digite A URL ofericida pela Meta API"
                        style={{
                            borderRadius: "12px",
                            borderColor: "gray",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "6px",
                            border: "none",
                            outline: "none",
                            padding: "14px",
                            width: "100%"
                        }}
                        key="url-field"
                    />
                    <input
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        fullWidth
                        placeholder="Digite o token fixo da Meta API"
                        style={{
                            borderRadius: "12px",
                            borderColor: "gray",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "6px",
                            border: "none",
                            outline: "none",
                            padding: "14px",
                            width: "100%"
                        }}
                        key="token-field"
                    />
                </Box>
            </Box>
        );
    }
    else if (currentStep === 2 && firstTime > 0) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "20px", sm: "30px" },
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: { xs: "0px", sm: "15px" } }}>
                    Aguarde um instante! 😉
                </Typography>

                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        color: "#848484",
                        marginBottom: "19px",
                        textAlign: "center",
                        maxWidth: { xs: "100%", sm: "80%" },
                    }}
                >
                    Estamos processando os dados para a criação do canal de E-mail
                </Typography>

                <Box
                    sx={{
                        border: "2px solid #F5F3FF",
                        borderRadius: "50%", // Garante que seja um círculo
                        height: "200px", // Define o tamanho fixo para o círculo
                        width: "200px", // Define a mesma largura para o círculo
                        display: "flex",
                        flexDirection: "column", // Organiza os itens em coluna
                        justifyContent: "center", // Centraliza verticalmente
                        alignItems: "center", // Centraliza horizontalmente
                        textAlign: "center", // Alinhamento do texto centralizado
                        position: "relative"
                    }}
                >
                    <CircularProgress
                        sx={{
                            position: "absolute", // Faz com que o progresso circular fique em cima do Box
                            color: "#F5F3FF", // Cor do progresso circular
                        }}
                        size={200} // Tamanho do círculo
                        thickness={1} // Espessura do progresso
                    />
                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: "30px",
                            fontFamily: '"Open Sans", sans-serif',
                            color: "#4F189A", // Coloca o texto acima do CircularProgress
                            zIndex: 1, // Garante que o texto fique sobre o progresso circular
                        }}
                    >
                        {firstTime}
                    </p>
                </Box>


            </Box>
        );
    }
    else if (currentStep === 3) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "20px", sm: "30px" },
                    width: "100%",
                }}
            >
                <StepIndicator currentStep={currentStep} steps={steps} />
                <Divider sx={{ width: "90%", borderColor: "gray" }} />
                <Typography sx={{ fontSize: { xs: "22px", sm: "24px" }, fontWeight: 600, color: "#000", marginBottom: "15px" }}>
                    {steps[currentStep - 1].title}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                    <StatusIcon color={"red"} status={success ? "emoji01" : "emoji10"} height={"80px"} width={"80px"} />
                </Box>
                <Typography
                    sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        color: "#848484",
                        marginBottom: "19px",
                        textAlign: "center",
                        maxWidth: { xs: "100%", sm: "80%" },
                    }}
                >
                    {steps[currentStep - 1].description}
                </Typography>

                {success ? (
                    <Button
                        variant="contained"
                        sx={{
                            padding: { xs: "16px 20px", sm: "10px 25px" },
                            width: "100%", // Faz o botão ocupar toda a largura
                            maxWidth: "400px", // Define uma largura máxima opcional
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "6px",
                            fontSize: "15px",
                        }}
                        onClick={() => navigate("/canais")}
                    >
                        Verificar meus canais
                    </Button>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: { xs: "10px", sm: "10px" },
                            marginTop: { xs: "30px", sm: "20px" },
                            flexDirection: {
                                xs: "column", // Empilha os botões em telas menores
                                sm: "row", // Alinha os botões na horizontal em telas maiores
                            },
                            width: "100%", // Faz os botões ocuparem toda a largura
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "16px 20px", sm: "10px 25px" },
                                width: "100%", // Faz o botão ocupar toda a largura
                                maxWidth: "400px", // Define uma largura máxima opcional
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                fontSize: "15px",
                            }}
                        >
                            Falar com o suporte
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                padding: { xs: "16px 20px", sm: "10px 25px" },
                                width: "100%", // Faz o botão ocupar toda a largura
                                maxWidth: "400px", // Define uma largura máxima opcional
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                fontSize: "15px",
                                backgroundColor: "#300D68",
                            }}
                            onClick={() => setCurrentStep(1)}
                        >
                            Tentar novamente
                        </Button>
                    </Box>
                )}
            </Box>
        );
    }

    return null;

});

const ChannelPageAddEmail = () => {
    const [firstTime, setFirstTime] = useState(15);
    const [currentStep, setCurrentStep] = useState(1);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [token, setToken] = useState("");
    const [verify, setVerify] = useState(false);

    const steps = [
        {
            stepNumber: 1,
            title: "Solicite o Whatsapp Business",
            description: (
                <span>
                    Após a configuração do seu WhatsApp <strong style={{ color: 'black' }}>meta/facebook</strong> adicione as informações abaixo para solicitar o envio de mensagens.
                </span>

            )
        },
        {
            stepNumber: 2,
            title: "",
            description: (
                <span>
                </span>
            ),
        },
        {
            stepNumber: 3,
            title: success ? "WhatsApp adicionado com sucesso!" : "Ops! Algo deu errado",
            description: success ? (
                <span>
                    Seu canal de WhatsApp API foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br /> Clique no botão abaixo para verificar seus canais.
                </span>
            ) : (
                <span>
                    Ocorreu um erro ao adicionar o canal WhatsApp API.<br /> Consulte nosso <b style={{ color: '#8033F4' }}>suporte</b> para ficar por dentro de todos os detalhes e orientações.
                </span>
            ),
        }
    ];

    const canMoveForward = () => {
        if (currentStep === 1 && name !== "" && token !== "" && url !== "") {
            return true;
        }

        notify("Error", "Preencha todos os campos", true);
        return false;
    }


    const handleAdvance = async () => {
        if (currentStep === 1) {
            setCurrentStep(2);

            await ChannelAddWppAPI({ url: url, token: token, name: name })
                .then((response) => {
                    if (response.message === 'success') {
                        setSuccess(true);
                    } else {
                        setSuccess(false);
                    }

                    setVerify(true);
                });
        }
    }

    useEffect(() => {

        if (firstTime > 0 && currentStep === 2) {

            const timer = setInterval(() => {
                setFirstTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }

        if (firstTime === 0 && currentStep === 2 && success === true && verify === true) {
            setCurrentStep(3);
            notify("Success", "Seu canal de WhatsApp API foi adicionado com sucesso", true);
        }
        if (firstTime === 0 && currentStep === 2 && success === false && verify === true) {
            setCurrentStep(3);
            notify("Error", "Ocorreu algum erro na criação do seu canal, tente novamente mais tarde", true);
        }

    }, [firstTime, currentStep]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <HeaderPage />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: 'rgb(128,51,244)',
                    background: 'radial-gradient(circle, rgba(124,41,249,1) 54%, rgba(128,51,244,1) 100%)',
                    opacity: 0.8,
                    flex: 1,
                    gap: "15px",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                    }}
                >
                    <Box
                        id="form"
                        sx={{
                            background: "#FFF",
                            height: {
                                xs: "100vh", // Ocupa a altura inteira em telas pequenas
                                sm: "100%", // Altura personalizada em telas maiores
                                md: "600px",
                                lg: "600px",
                                xl: "600px",
                            },
                            minWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            maxWidth: {
                                xs: "100%", // Ocupa a largura inteira em telas pequenas
                                sm: "100%", // Largura personalizada em telas maiores
                                md: "700px",
                                lg: "700px",
                                xl: "700px",
                            },
                            padding: "30px",
                            borderRadius: {
                                xs: "0", // Sem borda arredondada em telas pequenas
                                sm: "8px", // Bordas arredondadas em telas médias ou maiores
                            },
                            boxShadow: {
                                xs: "none", // Sem sombra em telas pequenas
                                sm: "0 4px 10px rgba(0, 0, 0, 0.2)", // Sombra em telas maiores
                            },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: { xs: "flex-start", sm: "flex-start", md: "space-between", lg: "space-between" }
                        }}
                    >
                        <FormContent
                            currentStep={currentStep}
                            steps={steps}
                            name={name}
                            setName={setName}
                            token={token}
                            setToken={setToken}
                            url={url}
                            setUrl={setUrl}
                            firstTime={firstTime}
                            success={success}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: { xs: "0px", sm: "10px" },
                                marginTop: { xs: "30px", sm: "20px" },
                                flexDirection: {
                                    xs: "column", // Empilha os botões em telas menores
                                    sm: "row", // Alinha os botões na horizontal em telas maiores
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    minWidth: {
                                        xs: '100%',
                                        sm: "154px",
                                    },
                                    display: "none",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: { xs: "16px", sm: "16px" },
                                    marginRight: "auto",
                                }}
                                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    padding: { xs: "16px 20px", sm: "10px 25px" },
                                    minWidth: {
                                        xs: '100%',
                                        sm: "154px", // Largura mínima em telas maiores
                                    },
                                    display: currentStep === 2 || currentStep === 3 ? "none" : "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    fontSize: { xs: "16px", sm: "16px" },
                                    marginLeft: "auto",
                                    marginTop: { xs: "20px", sm: "0" },
                                }}
                                onClick={() => canMoveForward() ? handleAdvance() : null}
                            >
                                Próximo
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ChannelPageAddEmail