import React, { useState, useEffect } from "react";
import Sidebar from "./components/layouts/Sidebar";
import MainContent from "./components/layouts/MainContent";
import RoutesApp from "./routes";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { BrowserRouter, useLocation } from "react-router-dom";

import Styles from "./components/Styles/Styles";
import { NavigationControlProvider } from "./NavigationControlContext";
import { ThemeProvider } from "@mui/material/styles";
import CreateTheme from "./components/ThemeColors/CreateTheme";
import { Toaster } from "react-hot-toast";
import { useAuth } from "./context/AuthContext";

import axios from 'axios';
import MobileNavbar from "./components/Mobile/MobileNavbar";


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {

      const location = window.location.pathname;

      if (location !== '/login' && location !== '/') {
        localStorage.removeItem('idToken');
        window.location.href = '/logout';
      }

    }
    return Promise.reject(error);
  }
);

const AppLayout = ({ children }) => {

  const { userAuthenticated } = useAuth();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const restrictedRoutes = ["/planos", "/planos/carrinho"];

    if (restrictedRoutes.includes(location.pathname) && userAuthenticated) {
      const mainContent = document.querySelector('.MainContent');
      if (mainContent) {
        setShowSidebar(false);
        mainContent.classList.add('full-width');
      }
    } else {
      const mainContent = document.querySelector('.MainContent');
      if (mainContent) {
        mainContent.classList.remove('full-width');
      }
    }
  }, [location.pathname, userAuthenticated]);


  return (
    <>
      {userAuthenticated &&
        (isSmallScreen ? (
          <MobileNavbar />
        ) : (
          showSidebar && <Sidebar />
        ))}
      {(userAuthenticated && <MainContent>{children}</MainContent>) || (
        <>{children}</>
      )}
    </>
  );
};

function App() {

  const { userAuthenticated } = useAuth();
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingAuthentication(false);
    }, 1000);
  }, []);

  if (loadingAuthentication) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress style={{ color: "rgb(52, 152, 219)" }} />
      </div>
    );
  }

  if (
    (!userAuthenticated && window.location.pathname === "/styles") ||
    (!userAuthenticated && window.location.pathname === "/buttons") ||
    (!userAuthenticated && window.location.pathname === "/icons") ||
    (!userAuthenticated && window.location.pathname === "/typography") ||
    (!userAuthenticated && window.location.pathname === "/iconography") ||
    (!userAuthenticated && window.location.pathname === "/emojis") ||
    (!userAuthenticated && window.location.pathname === "/labels") ||
    (!userAuthenticated && window.location.pathname === "/tables") ||
    (!userAuthenticated && window.location.pathname === "/ilustrations") ||
    (!userAuthenticated && window.location.pathname === "/alerts")
  ) {
    return (
      <ThemeProvider theme={CreateTheme}>
        <BrowserRouter>
          <NavigationControlProvider>
            <div className="flexContent" style={{ flex: 1 }}>
              <MainContent>
                <Styles />
              </MainContent>
            </div>
          </NavigationControlProvider>
        </BrowserRouter>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={CreateTheme}>
      <Toaster
        reverseOrder
        toastOptions={{
          position: "bottom-left",
          style: {
            maxWidth: "100vw",
          },
          duration: 5000,
        }}
      />
      <BrowserRouter>
        <AppLayout>
          <RoutesApp />
        </AppLayout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;