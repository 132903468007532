import React, { useState, useEffect } from 'react';
import { Avatar, Grid, Typography, IconButton, TextField, Select, MenuItem, FormControl, InputLabel, Button, CircularProgress, LinearProgress } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { isAuthenticated } from '../../authMiddleware';
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { ProfileGet } from '../../api/backend/Profile/ProfileGet';
import { ChangeProfileImage } from '../../api/backend/Profile/ChangeProfileImage';
import { notify } from '../../utils/utils';

const Profile = () => {
    const { userAuthenticated, userInfo, setUserInfo, userAvatar, setUserAvatar } = useAuth();
    const [loading, setLoading] = useState(true);
    const [isAvatarLoaded, setIsAvatarLoaded] = useState(userAvatar === null ? false : true);
    const [name, setName] = useState("John Doe");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [number, setNumber] = useState("");
    const [isLegalPerson, setIsLegalPerson] = useState("");
    const [cpfOrCnpj, setCpfOrCnpj] = useState("");
    const [newAvatar, setNewAvatar] = useState(null);

    async function getUserData() {
        try {
            const response = await ProfileGet();

            setName(response.name);
            setEmail(response.email);
            setCpfOrCnpj(response.cpf_cnpj);
            setZipCode(response.address.postal_code);
            setState(response.address.state);
            setNumber(response.address.number);
            setAddress(response.address.street);
            setPhone(response.address.cellphone);
            setCellPhone(response.address.cellphone);

        } catch (error) {
            console.error('Erro ao buscar os dados do usuário:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {
        if (userAuthenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, [userAuthenticated]);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files-public-139308022743-prod.s3.us-east-1.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
                setIsAvatarLoaded(true);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
                setIsAvatarLoaded(true);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
            setIsAvatarLoaded(true);
        }
    };

    useEffect(() => {
        if (userInfo['UUID'] && userAvatar === null) {
            const userHash = userInfo['UUID'] + '.png';

            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    const handleEditAvatar = (event) => {
        console.log('Evento:', event);
        const file = event.target.files[0];
        if (file && file.type === 'image/png') {
            setIsAvatarLoaded(false);
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const base64string = e.target.result;

                    await ChangeProfileImage(base64string).then((response) => {
                        if (response.message === "success") {
                            setNewAvatar(base64string);
                            setUserAvatar(base64string);
                            notify("Success", "Foto de perfil alterada com sucesso!", true);
                        }
                    });

                } catch (error) {
                    console.error("Erro ao alterar a imagem:", error);
                    notify("Error", "Ocorreu um erro ao alterar a foto de perfil.", false);
                } finally {
                    setIsAvatarLoaded(true);
                }
            };
            reader.readAsDataURL(file);
        } else {
            notify("Error", "Selecione apenas imagens no formato PNG", true);
        }
    };


    const handleSaveProfile = () => {
        console.log('Perfil salvo!');
    };

    return (
        <div>
            <Grid container spacing={2} justifyContent="center" direction="column" alignItems="center">
                <Grid item style={{ position: 'relative' }}>
                    {
                        isAvatarLoaded === false ? (
                            <CircularProgress style={{ color: "#e5e5e5", width: '200px', height: '200px' }} />
                        ) : (
                            <>
                                <Avatar
                                    src={userAvatar !== null ? userAvatar : AlternativeAvatar}
                                    sx={{ width: 200, height: 200 }}
                                />
                                <input
                                    type="file"
                                    accept="image/png"
                                    onChange={handleEditAvatar}
                                    style={{
                                        display: 'none',
                                    }}
                                    id="avatar-input"
                                />
                                <label htmlFor="avatar-input">
                                    <IconButton
                                        component="span"
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                            bgcolor: 'white',
                                            borderRadius: '50%',
                                            boxShadow: 2,
                                        }}
                                    >
                                        <CameraAltIcon sx={{ fontSize: 30 }} />
                                    </IconButton>
                                </label></>
                        )
                    }
                </Grid>

                <Grid item>
                    {loading === true ? (
                        <>
                            <LinearProgress sx={{
                                    height: '20px',
                                    borderRadius: '6px',
                                    minWidth: '100px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" align="center">{name}</Typography>
                            <Typography variant="body1" align="center">{email}</Typography>
                        </>
                    )}
                </Grid>

                <Grid item container spacing={2} xs={12} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label={"CPF ou CNPJ"}
                                    name={"CPF ou CNPJ"}
                                    variant="outlined"
                                    value={cpfOrCnpj}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="Telefone"
                                    name="Telefone"
                                    variant="outlined"
                                    value={phone}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="Celular"
                                    name="Celular"
                                    variant="outlined"
                                    value={cellPhone}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }

                    </Grid>

                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="CEP"
                                    name="CEP"
                                    variant="outlined"
                                    value={zipCode}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="Endereço"
                                    name="Endereço"
                                    variant="outlined"
                                    value={address}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="Estado"
                                    name="Estado"
                                    variant="outlined"
                                    value={state}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            loading === true ? (
                                <LinearProgress sx={{
                                    height: '56px',
                                    borderRadius: '6px',
                                    minWidth: '50px',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#E5E5E5',
                                    },
                                }} />
                            ) : (
                                <TextField
                                    fullWidth
                                    inputProps={{ "aria-readonly": true }}
                                    label="Número"
                                    name="Número"
                                    variant="outlined"
                                    value={number}
                                    sx={{ backgroundColor: '#F9F9F9', borderRadius: 1, pointerEvents: 'none' }}
                                />
                            )
                        }
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProfile}
                        fullWidth
                    >
                        Salvar Perfil
                    </Button>
                </Grid> */}
            </Grid >
        </div >
    );
};

export default Profile;