// Request to get campaigns by account uuid
// Update in 20/04/2024 ( OK )
import axios from 'axios';

export async function CampaignStatus(data) {
    try {
        const token = localStorage.getItem('jwt_token');
        const clientId = localStorage.getItem('idToken');

        if (!token) throw new Error('Token de autenticação não encontrado');

        if (!clientId) throw new Error('ID do cliente não encontrado');

        const response = await fetch(`${process.env.REACT_APP_BACKEND_AWS_URL}/campaigns/status`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                'Client_ID': `${clientId}`
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        // Melhor manejo do erro, exibindo mensagem de erro do servidor, se disponível
        const errorMsg = error.response?.data?.message || 'Erro desconhecido ao buscar dados da API';
        throw new Error(`Erro ao buscar dados da API: ${errorMsg}`);
    }
}
